<ng-container *ngIf="isLoggedIn === false">

    <app-header-no-session></app-header-no-session>
    
    <div class="wrapper home_fix">

        <div class="banner-img_home">
            <div class="banner_text">
                <p>Welcome to</p>
                <h1>Nigeria Passport</h1><hr>
            </div>
        </div>

        <section class="home">

            <div class="main">
                
                <div class="grid-container">

                    <div class="grid-box">
                        <div class="top_head">
                            <img src="assets/images/user-login.png" alt="icon">
                            <span>Step 1 <br>Login</span>
                        </div>

                        <div class="main-bottom">
                            <p>You are required to login or authenticate to continue to your application. </p>
                            <button class="loginBtn loginBtn--google" (click)="doLogin()">Sign In</button>
                            <span class="img_num"><img src="assets/images/01.png"></span>
                        </div>
                    </div>

                    <div class="grid-box">
                        <div class="top_head">
                            <img src="assets/images/form.png" alt="icon">
                            <span>Step 2 <br>Fill Application Form</span>
                        </div>

                        <div class="main-bottom">
                            <p>Let's know in brief about your personal, passport and travel information.</p>
                            <span class="img_num"><img src="assets/images/02.png"></span>
                        </div>
                    </div>

                    <div class="grid-box">
                        <div class="top_head">
                            <img src="assets/images/card.png" alt="icon">
                            <span>Step 3 <br>Make Payment</span>
                        </div>

                        <div class="main-bottom">
                            <p>For us to process your application, you must make payment first.</p>
                            <span class="img_num"><img src="assets/images/03.png"></span>
                        </div>
                    </div>

                </div>

            </div>

        </section>

    </div>

    <app-footer></app-footer>

</ng-container>
