<ng-container *ngIf="isPageLoaded === true">
    
    <app-header></app-header>
    <div class="wrapper">
        
        <div class="main" >
            <h3 style="padding-bottom:0;">Re-upload Supporting Documents</h3>
        </div>

        <section class="main">
                
            <fieldset class="support_document aaa">
    
                <form #supportingDocumentsForm="ngForm" (ngSubmit)="supportingDocumentsForm.form.valid && saveReuploadedDocs()" name="supportingDocumentsForm" id="msform" autocomplete="off">
                    
                    <div class="mb10">
                        <span style="color:red; font-weight: 600;">PLEASE ENSURE UPLOADED DOCUMENTS ARE CORRECT & PROPERLY READABLE TO AVOID REJECTION</span>
                    </div>

                    <div class="grid-one-third">
    
                        <div class="grid-onethird-box">
    
                            <details>
                                <summary>Instructions for Applicant </summary>
                                <div class="content">
                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                                </div>
                            </details>
    
                            <div class="re-upload-wrapper">

                                <div class="img_re-upload">

                                    <div class="img_upload">

                                        <div *ngIf="uploadPhotoFormObject !== null" class="img_box user_pic" style="padding: 2px;">
                                            <input type="hidden" name="validatedBase64Data" [(ngModel)]="uploadPhotoFormObject.validatedBase64Data" #validatedBase64Data="ngModel" class="effect" required="required">
                                            <img [src]="imageValidated === true ? uploadPhotoFormObject.validatedBase64Data : uploadPhotoFormObject.base64Data" class="cropped">
                                            <span *ngIf="photoUploaded === true" class="check"><i class="fa fa-check" aria-hidden="true"></i></span>
                                        </div>

                                        <div class="img_re-upload-info">

                                            <div class="re-upload-info_top">
                                                <div class="re_upload_box" >
                                                    <label>Full Name</label>
                                                    <p>{{applicantDetailsObject.fullName}}</p>
                                                </div>

                                                <div class="re_upload_box" >
                                                    <label>Reference Number</label>
                                                    <p>{{applicantDetailsObject.referenceNumber}}</p>
                                                </div>

                                                <div class="re_upload_box" >
                                                    <label>Application Number</label>
                                                    <p>{{applicantDetailsObject.applicationId}}</p>
                                                </div>

                                                <div class="re_upload_box" >
                                                    <label>Application Date</label>
                                                    <p>{{applicantDetailsObject.applicationDate}}</p>
                                                </div>
                                            </div>

                                            <div *ngIf="uploadPhotoFormObject !== null" class="re-upload-info_btm">
                                                <div class="img-required">
                                                    <span class="comments" role="comment">
                                                        <span>i</span>
                                                        <p>{{uploadPhotoFormObject.comments}}</p>
                                                    </span>
        
                                                    <span class="alert alert-danger" role="alert" *ngIf="supportingDocumentsForm.submitted && imageValidated === false">
                                                        <span>Image is required!</span>
                                                    </span>
                                                </div>
            
                                                <div class="photo_icons">
                                                    <div class="w_auto reUpload icons_uplaod">
                                                        <input type="button" (click)="openImageUploadModal(uploadPhotoFormObject, 'validatedBase64Data', 'passportImageFileType');" class="upload_btn" value="Upload Photo" style="margin-right: 5px;"/>
                                                        <input type="button" (click)="openDocHistoryModal(uploadPhotoFormObject)" class="history_btn"  value="History" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                            <ng-container *ngFor="let applicableDocSectionObject of applicableDocsList;">
                                
                                <table class="re-upload-table re-upload-doc">
                                    <thead>
                                        <tr class="line">
                                            <th colspan="2">{{applicableDocSectionObject.groupTypeLabel}}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let applicableDocObject of applicableDocSectionObject.groupDocs;">
                                            <td width="calc(100% - 160px)" class="applicant_details">
                                                <p>{{applicableDocObject.count}}. {{applicableDocObject.docTypeLabel}} <span class="medatory">*</span></p> 
                                                <span>
                                                    <input type="hidden" [name]="'base64Data'+applicableDocObject.applicantDocId" [(ngModel)]="applicableDocObject.base64Data" #base64Data="ngModel" required="required">
                                                    <span> (<a href="javascript:void(0);" (click)="openDocHistoryModal(applicableDocObject);" style="color: #0087ff;text-decoration: underline;" >Click here</a> to view previous uploaded documents)</span>
                                                </span>

                                                <span class="comments" role="comment">
                                                    <span>i</span>
                                                    <p>{{applicableDocObject.comments}}</p>
                                                </span>

                                                <span class="alert alert-danger" role="alert" *ngIf="supportingDocumentsForm.submitted && base64Data.invalid">
                                                    <span *ngIf="base64Data.errors.required">
                                                        {{applicableDocObject.docTypeLabel}} is required!
                                                    </span>
                                                </span>
                                            </td>
    
                                            <td width="160px" class="actionable">
                                                <ng-container *ngIf="applicableDocObject.isDocReuploaded === false">
                                                    <span (click)="openFileUploadModal(applicableDocObject);" class="upload">Re-Upload</span>
                                                </ng-container>
                                            
                                                <ng-container *ngIf="applicableDocObject.isDocReuploaded === true">
                                                    <span class="check"><i class="fa fa-check-circle" aria-hidden="true"></i></span>
    
                                                    <span (click)="openFilePreviewModal(applicableDocObject);" class="preview">Preview</span>
    
                                                    <span (click)="deleteUploadedFile(applicableDocObject);" class="delete">Delete</span>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>    

                            </ng-container>
                            
                            <div class="note-alert">
                                <span class="alert alert-danger" role="alert"><b> Note:</b> Please re-upload the document(s) flagged above according to the comments shown along with it. The initial {{maximumDocQueryAllowed}} re-uploads are non-chargeable. You are required to pay additional charges for any further re-upload.</span>
                            </div>

                            <label class="t_and_c reupload-confirm pointer">
                                <input type="checkbox" name="termCondition" [(ngModel)]="isTermConditionSelect" #termCondition="ngModel" required="required"> <span style="padding:0 3px;"> I hereby confirm that all the documents I have provided are genuine & properly readable.</span>
                                <span class="alert alert-danger" role="alert" *ngIf="supportingDocumentsForm.submitted && termCondition.invalid">
                                    <span *ngIf="termCondition.errors.required">
                                        Confirmation is required
                                    </span>
                                </span>
                            </label>
                        </div>
    
                        <div class="grid-onethird-box upload_inst">
                            <div class="element none_dis">
                                <h4>Instructions</h4>
                                <ng-template [ngTemplateOutlet]="instructions"></ng-template>  
                            </div>
                        </div>
                    </div>
    
                    <input type="submit" class="action-button custom_btn" value="Submit" />

                </form>
    
            </fieldset>

        </section>

    </div>

    <app-footer></app-footer>

    <ng-template #instructions>
        <ul>
            <li><span>Please upload all the required documents as displayed here for the processing of the Passport application</span></li>
        </ul>
    </ng-template>

</ng-container>
