import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Route } from '../common/route';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {

  private paymentSuccessForm : HTMLFormElement;

  private encryptedApplicantId : string = this.constants.BLANK;

  private encryptedTxnRefNumber : string = this.constants.BLANK;

  paymentDetailsObject : any = {};

  constructor(private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constants: Constants,
              private utils: Utils, private loaderService: LoaderService, private route: Route) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplicantId = params["applicantId"];
      this.encryptedTxnRefNumber = params["txnRefNumber"];
    });
    this.checkPaymentStatus();
  }

  ngOnInit(): void {
    this.paymentSuccessForm = document.forms['paymentSuccessForm'];
  }

  private checkPaymentStatus(){
    this.loaderService.startLoader();
    const decryptedTxnRefNumber = this.utils.decryptServerEncryptedString(this.encryptedTxnRefNumber);
    this.httpUtils.checkPaymentStatus(decryptedTxnRefNumber).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.toast.showSuccessToast("Your payment has been made successfully.");
        this.paymentDetailsObject = serverResponse;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
        this.viewApplication();
      }
    });
  }

  openHomePage(){
    this.ngZone.run(()=>this.router.navigate([this.route.HOME]));
  }

  openApplicationPage(){
    this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_ADD_EDIT, this.utils.encryptString(this.paymentDetailsObject.emailId)]));
  }

  viewApplication(){
    const serverDecryptedApplicantId = this.utils.decryptServerEncryptedString(this.encryptedApplicantId);
    this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_VIEW, this.utils.encryptString(serverDecryptedApplicantId)]));
  }

  downloadPDF(downloadType: string){
    this.loaderService.startLoader();
    this.paymentSuccessForm.action = environment.serverBaseUrl + "v1/application/downloadApplicationPDF";
    this.paymentSuccessForm.applicationId.value = this.paymentDetailsObject.applicationId;
    this.paymentSuccessForm.txnReference.value = this.paymentDetailsObject.txnRefNumber;
    this.paymentSuccessForm.downloadType.value = downloadType;
    this.paymentSuccessForm.submit();
    this.loaderService.stopLoader();
  }

}