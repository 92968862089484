<nav class="header-session">

    <div class="header_row">
    
        <div class="header_wrapper">
            <div class="left_sides right_top">

                <a href="https://immigration.gov.ng/" class="logo-header">
                <img src="assets/images/logo-nis.png">
            </a>
            </div>
            <div class="right_sides right_top_1">
                <div class="for_menu">
                    <input id="menu-toggle" type="checkbox" />
                    <label class='menu-button-container' for="menu-toggle">
                        <div class='menu-button'></div>
                    </label>
                    <ul class="menu no-menu" >
                        <li>
                            <a href="javascript:void(0)" (click)="openHomePage()">
                                <span class="icon"><i class="fa fa-home" aria-hidden="true"></i></span>Home
                            </a>
                        </li>
                        <li>
                            <a [href]="appointmentApplicationUrl" target="_blank">
                                <span class="icon"><i class="fa fa-calendar" aria-hidden="true"></i></span>Book an Appointment
                            </a>
                        </li>
                        <li>
                            <a [href]="trackApplicationURL" target="_blank">
                                <span class="icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>Track Application
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="openHelpPage()">
                                <span class="icon"><i class="fa fa-question-circle" aria-hidden="true"></i></span>Help
                            </a>
                        </li>
                        <!-- <li><a href="javascript:void(0);" (click)="openApplicationSearchPage()">Payment Status</a></li> -->
                        
                    </ul>
                </div> 
                <div class="user_log">
                    <ul>
                        <li><a href="javascript:void(0);" class="custom_btn" (click)="doLogin()">Sign In</a></li>
                    </ul>
                </div>
                
            </div>
        
        </div>

    </div>

    <div class="banner-img-name mb10"></div>

</nav>
