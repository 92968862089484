<app-header-no-session></app-header-no-session>

<div class="banner_wrapper">
    <div class="home_banner_img">
        <div class="banner_content">
            <h1>FAQ</h1>
        </div>
    </div>
</div>

<main class="help_page page">
    <div class="main text-center">
        <h1 class="heading_main green-text">Frequently Asked Questions</h1>
        <h4 class="faq_note">NOTE: The provided content is just to showcase the page design. Dev team is working on finalize content</h4>
        <mat-accordion class="faq-accordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>What payment methods do you accept?</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="centered-content">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae abillo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat </p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>What do I do if my credit card is not accepted?</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="centered-content">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Will I be charged a fee for refunds?</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="centered-content">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</main>

<app-footer-no-session></app-footer-no-session>