<div class="image_upload_container image-upload-large">

    <div class="modal-header">
        <h4>Upload: {{applicableDocObject.docTypeLabel}}</h4>
    </div>

    <form #fileUploadForm="ngForm" (ngSubmit)="fileUploadForm.form.valid && confirm()" class="form-msd" name="fileUploadForm" id="msform" autocomplete="off" style="padding: 10px;">

        <ng-template #instructions>
            <ul class="align-left-ins">
                <li>You can upload PDF, JPG, PNG, GIF files.</li>
                
                <li>The file size must not exceed {{constants.USER_IMAGE_REQUIRED_SIZE_IN_MB}}MB.</li>
            </ul>
        </ng-template> 
        
        <div class="modal-body"  style="overflow-y: auto;">

            <div class="centered">

                <details>
                            
                    <summary class="align-left">Instructions</summary>
                
                    <div class="content">
                        <ng-template [ngTemplateOutlet]="instructions"></ng-template> 
                    </div>

                </details>

                <div class="grid-one-third w100">

                    <div class="grid-boxes grid-boxes-upload">
                        
                        <ng-container *ngIf="applicableDocObject.hasOptions === true">

                            <div class="form_box form_height" style="width: 100%; max-width: 500px;">
                                <label class="fieldlabels">Document Type <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <select name="docSubType" [(ngModel)]="fileUploadFormObject.docSubType" #docSubType="ngModel" (change)="setDocSubTypeLabel($event);" class="effect" required="required">
                                        <option value="">Choose Option</option>
                                        <option *ngFor="let optionObject of applicableDocObject.options;" [value]="optionObject.key">{{optionObject.value}}</option>
                                    </select>
                                    <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Please choose atleast one document.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="fileUploadForm.submitted && docSubType.invalid">
                                    <span *ngIf="docSubType.errors.required">
                                        Document Type is required!
                                    </span>
                                </span>
                            </div>
        
                        </ng-container>
        
                        <div class="file-upload w100">
        
                            <div *ngIf="showDummyImage === true" class="photo_box  icon_dummy">
                                <i class="fa fa-file-image-o" aria-hidden="true"></i>
                            </div>
                
                            <div *ngIf="showPDFView === true" class="icon_dummy">
                                <pdf-viewer [src]="uploadedFileBase64Data" [render-text]="true"  [zoom] = "0.7" [original-size]="false" [ngClass]="applicableDocObject.hasOptions === true ? 'pdf-viewer-with-option' : 'pdf-viewer-without-option' " style="display: block;"></pdf-viewer>
                            </div>

                            <div *ngIf="enableImageCropper === true" class="image-cropper-box">
                                <perfect-scrollbar class="ps-show-always">
                                    <img [src]="uploadedFileBase64Data" />
                                </perfect-scrollbar>
                            </div>
        
                            <div class="upload_boxx mt10 files_btn">
                                <input type="hidden" name="base64Data" [(ngModel)]="uploadedFileBase64Data" #base64Data="ngModel" required="required">
                                <span class="alert alert-danger" role="alert" *ngIf="fileUploadForm.submitted && base64Data.invalid">
                                    <span *ngIf="base64Data.errors.required">
                                        File is required!
                                    </span>
                                </span>
                                <div class="file_upload1 w100 mt10 mb10">
                                    <label  class="fileUpload upload_btn">
                                        <input type="file" [accept]="allowFileTypeList" (change)="uploadFile($event)" id="myFile" class="upload up" style="width:99px;">
                                        <span id="upload" class="upl">Upload File</span>
                                    </label>
                                </div>
                            </div>
        
                        </div>

                    </div>

                    <div class="grid-boxes upload_inst">

                        <div class="instructionss w100">

                            <div class="element none_dis">

                                <h4>Instructions:</h4>
                                
                                <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        
        </div>

        <div class="modal-footer">
            <button type="submit" class="btn">Submit</button>

            <button type="button" class="back_btn" (click)="cancel()">Cancel</button>
        </div>
    
    </form>

</div>
