import { Constants } from "./constants";
import { Injectable } from '@angular/core';
import { SelectService } from "./select-service";
import * as CryptoJS from 'crypto-js';
import { CroppieOptions, ResultOptions } from "croppie";

declare function showJSToolTip(tagNameAttr: string): any;
declare function hideJSToolTip(tagNameAttr: string): any;
declare function setJSDropdownWidth(event: any): any;


@Injectable({ 
    providedIn: 'root'
})
export class Utils {

    resultOptions: ResultOptions = {type: 'base64', size: 'original', format: 'jpeg', quality: 1, circle: false};

    croppieOptions: CroppieOptions = {boundary: {width: 375, height: 375}, viewport: {width: 264, height: 340, type: 'square'}, enableExif: true, enableOrientation: true, enableZoom: true, enableResize: false, maxZoom: 0, minZoom: 0, mouseWheelZoom: true, showZoomer: true};

    orientation: number = 1;

    zoom: number = 0;

    defaultSelectOption = {value: this.constants.BLANK, text: this.constants.DEFAULT_SELECT_OPTION_LABEL};

    private initialFieldValue : string = this.constants.BLANK;

    constructor(private constants: Constants){
    }

    isEmpty(value: any){
        return (value === undefined || value === null || value === this.constants.BLANK);
    }

    showToolTip(tagNameAttr: string){
        showJSToolTip(tagNameAttr);
    }
    
    hideToolTip(tagNameAttr: string){
        hideJSToolTip(tagNameAttr);
    }
    
    storeFreshPassportTypes(passportSelectService: SelectService){
        const passportTypeList = [{value: this.constants.STANDARD_PASSPORT, text: "Standard Passport"}];
        passportSelectService.setSelectOptions(passportTypeList);
    }

    storeRenewPassportTypes(passportSelectService: SelectService){
        const passportTypeList = [{value: this.constants.STANDARD_PASSPORT_RENEWAL, text: "Standard Passport"}];
        passportSelectService.setSelectOptions(passportTypeList);
    }

    storeChangeTypes(changeTypeSelectService: SelectService){
        const changeTypeList = [this.defaultSelectOption];
        changeTypeList.push({value: this.constants.EXPIRED, text: "Expired"})
        changeTypeList.push({value: this.constants.PAGE_EXHAUSTED, text: "Page Exhausted"})
        changeTypeList.push({value: this.constants.LOST_REPLACEMENT_REQUEST, text: "Lost/Replacement Request"})
        changeTypeList.push({value: this.constants.DAMAGED_PASSPORT, text: "Damaged Passport"})
        changeTypeSelectService.setSelectOptions(changeTypeList);
    }
    
    // storeAgeGroups(ageGroupSelectService: SelectService){
    //     const ageGroupList = [this.defaultSelectOption];
    //     ageGroupList.push({value: this.constants.MINOR, text: "0-17"})
    //     ageGroupList.push({value: this.constants.ADULT, text: "18-59"})
    //     ageGroupList.push({value: this.constants.SENIOR_CITIZEN, text: "60 and above"})
    //     ageGroupSelectService.setSelectOptions(ageGroupList);
    // }

    encryptString(plainString: string){
        if(this.isEmpty(plainString) === true){
            return this.constants.BLANK;
        }
        const encryptedString = CryptoJS.AES.encrypt(plainString.trim(), this.constants.ENCRYPT_DECRYPT_KEY.trim()).toString();
        const encryptedBase64String = btoa(encryptedString);
        return encryptedBase64String;
    }

    decryptString(encryptedBase64String: string){
        if(this.isEmpty(encryptedBase64String) === true){
            return this.constants.BLANK;
        }
        const encryptedString = atob(encryptedBase64String);
        const plainString = CryptoJS.AES.decrypt(encryptedString.trim(), this.constants.ENCRYPT_DECRYPT_KEY.trim()).toString(CryptoJS.enc.Utf8);
        return plainString;
    }

    encryptServerString(plainString: string){
        const key = CryptoJS.PBKDF2(this.constants.ENCRYPT_DECRYPT_MASTER_PASSWORD, this.constants.ENCRYPT_DECRYPT_MASTER_SALT, {keySize: 4, iterations: 1});
        const encryptedString = CryptoJS.AES.encrypt(plainString, key, {iv: CryptoJS.enc.Utf8.parse(this.constants.ENCRYPT_DECRYPT_MASTER_KEY), padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC});
        return encryptedString.toString();
    }

    decryptServerEncryptedString(encryptedString: string){
        if(this.isEmpty(encryptedString) === true) {
            return this.constants.BLANK;
        }
        const key = CryptoJS.PBKDF2(this.constants.ENCRYPT_DECRYPT_MASTER_PASSWORD, this.constants.ENCRYPT_DECRYPT_MASTER_SALT, {keySize: 4, iterations: 1});
        const decryptedString = CryptoJS.AES.decrypt(encryptedString, key, {iv: CryptoJS.enc.Utf8.parse(this.constants.ENCRYPT_DECRYPT_MASTER_KEY), padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC});
        const plainString = decryptedString.toString(CryptoJS.enc.Utf8);
        return plainString;
    }

    getMonthsArray(){
        const monthsList = [{value: "", text: "MM"}];
        monthsList.push({value: "01", text: "Jan"});
        monthsList.push({value: "02", text: "Feb"});
        monthsList.push({value: "03", text: "Mar"});
        monthsList.push({value: "04", text: "Apr"});
        monthsList.push({value: "05", text: "May"});
        monthsList.push({value: "06", text: "Jun"});
        monthsList.push({value: "07", text: "Jul"});
        monthsList.push({value: "08", text: "Aug"});
        monthsList.push({value: "09", text: "Sep"});
        monthsList.push({value: "10", text: "Oct"});
        monthsList.push({value: "11", text: "Nov"});
        monthsList.push({value: "12", text: "Dec"});
        return monthsList;
    }

    fetchIntTelOptionsObject(allowDropdown: boolean){
        const mobileNumberOptionsObject = {initialCountry: 'ng', preferredCountries: [], separateDialCode: true, autoPlaceholder: this.constants.BLANK, allowDropdown: allowDropdown, formatOnDisplay: false};

        return mobileNumberOptionsObject;
    }

    setMobileNumberFields(countryInfoObject: {}, formObject: {}, fieldsArray: any[]){
        const countryISOCode = countryInfoObject['iso2'];
        
        formObject[fieldsArray[0]] = countryISOCode;
        formObject[fieldsArray[1]] = '+' + countryInfoObject['dialCode'];

        //THIS IS USED FOR THE RUN TIME EXPRESSION CHANGED ERROR
        setTimeout(() => {
            formObject[fieldsArray[2]] = 5;
            formObject[fieldsArray[3]] = 16;
        }, 0);
    }

    checkHasMatValueChanged(isOpened: boolean, formObject: {}, fieldName: string){
        if(isOpened === true){
            this.initialFieldValue = formObject[fieldName];
            return;
        } 
        
        const currentFieldValue = formObject[fieldName];
        
        if(this.initialFieldValue === currentFieldValue){
            formObject["hasFieldValueChanged"] = this.constants.FALSE;
        } else {
            formObject["hasFieldValueChanged"] = this.constants.TRUE;
        }
    }

    checkIsMobileDeviceUsed(): boolean{
        const deviceDetails = navigator.userAgent;
        const mobileDeviceExpression = /android|iphone|kindle|ipad/i;
        return mobileDeviceExpression.test(deviceDetails);
    }

    storeApplyingFor(applyingForSelectService: SelectService){
      const applyingForList = [this.defaultSelectOption];
      applyingForList.push({value: this.constants.FRESH, text: 'New'});
      applyingForList.push({value: this.constants.RENEW, text: 'Renew/Re-issue'});
      applyingForSelectService.setSelectOptions(applyingForList);
    }

    isRenewPassport(passportTypeKey: string){
        // return passportTypeKey === this.constants.STANDARD_PASSPORT_RENEWAL || passportTypeKey === this.constants.STANDARD_PASSPORT_REISSUE;
        return (passportTypeKey === this.constants.STANDARD_PASSPORT_RENEWAL);
    }

    fetchPassportTypeLabel(passportTypeKey: string){
        if(this.isEmpty(passportTypeKey) === true){
            return "Passport";
        }
        const passportTypeLabelObject = this.getPassportTypeLabel();
        return passportTypeLabelObject[passportTypeKey];
    }

    private getPassportTypeLabel(){
        const passportTypeLabelObject : any = {};
        passportTypeLabelObject[this.constants.STANDARD_PASSPORT] = "Fresh Passport";
        passportTypeLabelObject[this.constants.STANDARD_PASSPORT_RENEWAL] = "Renewal/Re-issue of Passport";
        passportTypeLabelObject[this.constants.STANDARD_PASSPORT_COD] = "Re-issue of Passport";
        return passportTypeLabelObject;
    }

    setDropdownWidth(event: any){
        setJSDropdownWidth(event);
    }

}
