import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { Tags } from '../common/tags';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { HttpUtils } from '../common/http-utils';
import { Toast } from '../common/toast';
import { GUI } from '../common/gui';
import { Utils } from '../common/utils';
import { UserType } from '../common/user-type';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  public isLoggedIn = false;

  public userProfile: KeycloakProfile | null = null;

  constructor(private readonly keycloakService: KeycloakService, private utils: Utils, public constants: Constants, private localStorage: LocalStorage, private tags: Tags, 
              private loaderService: LoaderService, private toast: Toast, private httpUtils: HttpUtils, private gui: GUI, private userType: UserType) { 
    this.localStorage.removeStorageKey(this.tags.EMAIL_VERIFICATION_STATUS);
   
    const currentBuildDate = this.localStorage.getStorageKey(this.tags.PROJECT_BUILD_DATE);
    const latestBuildDate = environment.buildDate;
    console.log("Current build date stored in storage is ", currentBuildDate, "Latest build date is ", latestBuildDate);
    
    if(this.utils.isEmpty(currentBuildDate) === true){
      this.localStorage.setStorageKey(this.tags.PROJECT_BUILD_DATE, latestBuildDate);
      this.reloadPage();
    } else if(currentBuildDate !== latestBuildDate){
      this.localStorage.setStorageKey(this.tags.PROJECT_BUILD_DATE, latestBuildDate);
      this.reloadPage();
    }
  }

  public async ngOnInit() {
    this.loaderService.startLoader();
    this.isLoggedIn = await this.keycloakService.isLoggedIn();
    if (this.isLoggedIn == true) {
      Cookie.set(this.tags.KEYCLOAK_TOKEN, this.keycloakService.getKeycloakInstance().token, 1, environment.path, environment.domain);
      Cookie.set(this.tags.KEYCLOAK_REFRESH_TOKEN, this.keycloakService.getKeycloakInstance().refreshToken, 1, environment.path, environment.domain);
      this.userProfile = await this.keycloakService.loadUserProfile();
      
      const userId = this.keycloakService.getKeycloakInstance().refreshTokenParsed?.sub;
      
      const userTypeArray = this.keycloakService.getKeycloakInstance().realmAccess?.roles;
    
      console.log("User types comes from keycloak is ", userTypeArray);

      if(this.userType.isValidUser(userTypeArray) === false) {
        this.loaderService.stopLoader();
        this.gui.openInvalidAccess();
        return;
      }

      this.fetchServerToken(userId, userTypeArray);
    } else {
      this.loaderService.stopLoader();
    }
  }
  
  private fetchServerToken(userId: string, userTypeArray: any[]): void {
    this.httpUtils.fetchServerToken().then(serverResponse => {
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
          this.localStorage.setStorageKey(this.tags.APPLICATION_TOKEN, serverResponse.bearerToken);
          this.saveLoginDetails(userId, userTypeArray);
      }
    });
  }

  private saveLoginDetails(userId: string, userTypeArray: any[]){
    this.httpUtils.saveLoginDetails(userId, this.userProfile.email, this.userProfile.username, this.userProfile.firstName, this.userProfile.lastName).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const loggedUserId = this.utils.encryptString(serverResponse.userId);
        const loggedUserFullName = this.utils.encryptString(serverResponse.userFullName);

        this.localStorage.setStorageKey(this.tags.LOGGED_USER_ID, loggedUserId);
        this.localStorage.setStorageKey(this.tags.LOGGED_USER_NAME, loggedUserFullName);

        const userTypeJSONObject = {[this.tags.USER_TYPE_JSON_KEY]: userTypeArray};
        this.localStorage.setStorageKey(this.tags.LOGGED_USER_TYPE, this.utils.encryptString(JSON.stringify(userTypeJSONObject)));
        
        this.gui.openDashboardPage();
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
        this.gui.openLogoutPage();
      }
    });
  }

  private reloadPage(){
    // window.location.reload();
    // window.location.replace('');
  }

  doLogin(){
    this.keycloakService.login();
  }

}
