import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { SearchModalComponent } from '../search-modal/search-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  private dashboardForm : HTMLFormElement;

  applicationsList: any[] = [];

  selectedAllApplications : boolean = false;
  
  selectedApplicationsCount : number = this.constants.FALSE;
  
  selectedApplicationsAmount : number = this.constants.FALSE;
  
  private applicationStatus: number = this.constants.FALSE;

  routeName : string = this.constants.BLANK;

  pageTitle: string = this.constants.BLANK;

  masterCurrencyCode : string = this.constants.BLANK;

  masterCurrencySymbol : string = this.constants.BLANK;

  private loggedUserId : string = this.constants.BLANK;

  private loaderTaskId: string = 'DASHBOARD_';
  
  constructor(public constants: Constants, private httpUtils: HttpUtils, private toast: Toast, private localStorage: LocalStorage, private loaderService: LoaderService, private simpleModalService: SimpleModalService,
              private gui: GUI, private utils: Utils) {
    this.routeName = this.localStorage.getLoggedUserCurrentRoute();
    if(this.routeName !== this.constants.RESUME_APPLICATION && this.routeName !== this.constants.PAYMENT_STATUS){
      this.routeName = this.constants.RESUME_APPLICATION;
    }
    this.applicationStatus = this.routeName === this.constants.PAYMENT_STATUS ? this.constants.STATUS_SUCCESS : this.constants.STATUS_INITIATE;
    this.pageTitle = this.routeName === this.constants.PAYMENT_STATUS ? 'Paid Applications' : 'Incomplete Applications';
    this.loggedUserId = this.localStorage.getLoggedUserId();
    this.storeDashboardData();
  }

  ngOnInit(): void {
    this.dashboardForm = document.forms['dashboardForm'];
  }

  private storeDashboardData(): void {
    this.loaderService.startLoaderV2(this.loaderTaskId + '1');
    this.httpUtils.fetchDashboardData(this.loggedUserId, this.applicationStatus).then(serverResponse => {
      this.loaderService.stopLoaderV2(this.loaderTaskId + '1');
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.applicationsList = serverResponse.applicationsList;
        if(this.applicationsList.length === this.constants.TRUE && this.applicationsList[0].applicationStatus === this.constants.STATUS_INITIATE) {
          this.editApplication(this.applicationsList[0].applicantId);
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  selectAllCompletedApplications(event : any){
    let applicationsTotalCount : number = this.constants.FALSE;
    let applicationsTotalAmount : number = this.constants.FALSE;
    let result = true;

    for(let i=0; i<this.applicationsList.length; i++) {
      const applicationStatus = this.applicationsList[i].applicationStatus;
      const applicationExpiryStatus = this.applicationsList[i].applicationExpiryStatus;

      if(applicationStatus !== this.constants.STATUS_SUCCESS || applicationExpiryStatus === this.constants.TRUE) {
        continue;
      }

      this.applicationsList[i].selected = this.selectedAllApplications;
     
      result = this.validateEmbassy(event, this.applicationsList[i]);
      if(result === false){
        break;
      }

      this.masterCurrencyCode = this.applicationsList[i].currencyCode;

      this.masterCurrencySymbol = this.applicationsList[i].currencySymbol;

      if(this.selectedAllApplications === true){
        const countResult = this.validateApplicationsCount(event, applicationsTotalCount, this.applicationsList[i]);
        if(countResult === false){
          this.toast.showErrorToast('Max ' + this.constants.MAX_APPLICATIONS_ALLOWED_FOR_PAYMENT + ' applications can be paid together.');
          break;
        }
        applicationsTotalAmount = applicationsTotalAmount + this.applicationsList[i].applicationFee;
        applicationsTotalCount = applicationsTotalCount + 1;
      }
    }
    
    this.selectedApplicationsCount = applicationsTotalCount;
    this.selectedApplicationsAmount = applicationsTotalAmount;

    if(result === false){
      this.toast.showErrorToast('Due to application processing restrictions, do not combine applications of different embassy locations.');
      return;
    }
  }

  checkIfAllCompletedApplicationsSelected(event : any, completedApplication: any){
    const result = this.validateEmbassy(event, completedApplication);
    if(result === false){
      this.toast.showErrorToast('Due to application processing restrictions, do not combine applications of different embassy locations.');
      return;
    }

    this.masterCurrencyCode = completedApplication.currencyCode;

    this.masterCurrencySymbol = completedApplication.currencySymbol;

    this.selectedAllApplications = this.applicationsList.every(function(item:any) {
      return item.selected == true;
    });

    if(this.selectedAllApplications){
      this.selectAllCompletedApplications(event);
      return;
    }

    let applicationsTotalCount : number = this.constants.FALSE;
    let applicationsTotalAmount : number = this.constants.FALSE;

    for(let i=0; i<this.applicationsList.length; i++) {
      if(this.applicationsList[i].selected === true){
        const countResult = this.validateApplicationsCount(event, applicationsTotalCount, completedApplication);
        if(countResult === false){
          this.toast.showErrorToast('Max ' + this.constants.MAX_APPLICATIONS_ALLOWED_FOR_PAYMENT + ' applications can be paid together.');
          break;
        }
        applicationsTotalCount++;
        applicationsTotalAmount = applicationsTotalAmount + this.applicationsList[i].applicationFee;
      }
    }

    this.selectedApplicationsCount = applicationsTotalCount;
    this.selectedApplicationsAmount = applicationsTotalAmount;
  }

  private validateEmbassy(event : any, completedApplication: any): boolean {
    for(let i=0; i<this.applicationsList.length; i++) {
      if(this.applicationsList[i].selected === true && this.applicationsList[i].processingEmbassy !== completedApplication.processingEmbassy){
        event.target.checked = false;
        completedApplication.selected = false;
        return false;
      }
    }
    return true;
  }

  private validateApplicationsCount(event : any, applicationsTotalCount: number, completedApplication: any): boolean {
    if(applicationsTotalCount >= this.constants.MAX_APPLICATIONS_ALLOWED_FOR_PAYMENT){
      event.target.checked = false;
      completedApplication.selected = false;
      return false;
    }

    return true;
  }

  openApplicationForm(applyingFor: string){
    this.gui.openNinVerificationPage(applyingFor);
  }

  viewApplication(applicantId: string){
    this.gui.openApplicationViewPage(applicantId);
  }

  openSearchModel(){
    this.simpleModalService.addModal(SearchModalComponent, {isUnpaidSearch: this.constants.TRUE, isGuarantorFormSearch: this.constants.FALSE}, {autoFocus: true}).subscribe((isApplicationAdded: boolean)=> {
      if(isApplicationAdded === true){
        this.storeDashboardData();
      }
    });
  }

  editApplication(applicationId: string){
    this.gui.openApplicationEditPage(applicationId);
  }

  showDeleteConfirmation(applicantId: string, applicationId: string, isDeleteIncomplete: number){
    const appendMessgae = isDeleteIncomplete === this.constants.TRUE ? 'this application?' : 'the application number ' + applicationId + '?'
    const message = 'Are you sure you want to delete ' + appendMessgae;
    const modalParamsObject = {title: 'Confirmation', message: message, confirmButtonLabel: 'YES', cancelButtonLabel: 'NO', isShowCancelButton: true};
    this.simpleModalService.addModal(ConfirmationModalComponent, modalParamsObject, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
      if(isConfirmed === true){
        this.deleteApplication(applicantId, isDeleteIncomplete);
      }
    });
  }

  private deleteApplication(applicantId: string, isDeleteIncomplete: number){
    this.loaderService.startLoader();
    this.httpUtils.deleteApplication(this.loggedUserId, applicantId, isDeleteIncomplete).then(serverResponse => {
      this.loaderService.stopLoader();
      this.selectedAllApplications = false;
      this.selectedApplicationsCount = this.constants.FALSE;
      this.selectedApplicationsAmount = this.constants.FALSE;
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.toast.showSuccessToast('Your application has been deleted successfully');
        this.storeDashboardData();
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private fetchSelectedApplications(){
    const applicantIds = [];
    for(let i=0; i<this.applicationsList.length; i++) {
      if(this.applicationsList[i].selected === true){
        applicantIds.push(this.applicationsList[i].applicantId);
      }
    }
    return applicantIds;
  }

  initiatePayment(){
    this.loaderService.startLoader();
    const applicationsList = this.fetchSelectedApplications();
    this.httpUtils.initiatePayment(this.loggedUserId, applicationsList).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.openIOSPaymentPage();
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
        this.selectedAllApplications = false;
        this.selectedApplicationsCount = this.constants.FALSE;
        this.selectedApplicationsAmount = this.constants.FALSE;
        this.storeDashboardData();
      }
    });
  }

  private openIOSPaymentPage(){
    this.loaderService.startLoader();
    this.dashboardForm.action = environment.serverBaseUrl + 'v1/payments/openIOSPaymentPage';
    this.dashboardForm.loggedUserId.value = this.loggedUserId;
    this.dashboardForm.submit();
  }

  checkPaymentStatus(applicantId: string): void {
    this.loaderService.startLoader();
    const loggedUserId = this.localStorage.getLoggedUserId();
    this.httpUtils.checkPaymentStatusOnIOS(loggedUserId, applicantId, false).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const checkPaymentResponseCode = serverResponse.checkPaymentResponseCode; 
        const checkPaymentResponseMsg = serverResponse.checkPaymentResponseMsg;
        if(checkPaymentResponseCode === this.constants.STATUS_SUCCESS) {
          this.toast.showSuccessToast(checkPaymentResponseMsg);
          this.storeDashboardData();
        } else {
          this.toast.showErrorToast(checkPaymentResponseMsg);
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  checkPaymentRefundStatus(applicantId: string, applicationIdV1: string, referenceIdV1: string){
    this.loaderService.startLoader();
    const loggedUserId = this.localStorage.getLoggedUserId();
    this.httpUtils.fetchRefundStatus(applicantId, loggedUserId).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS && serverResponse.paymentRefunded === true){
        this.toast.showErrorToast(serverResponse.responseMsg);
        this.storeDashboardData();
      } else if(serverResponse.responseCode === this.constants.STATUS_SUCCESS && serverResponse.paymentRefunded === false) {
        this.openBookAppointmentURL(applicationIdV1, referenceIdV1);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openBookAppointmentURL(applicationIdV1: string, referenceIdV1: string){
    let appointmentURL = environment.appointmentApplicationUrl;

    if(this.utils.isEmpty(applicationIdV1) === false && this.utils.isEmpty(referenceIdV1) === false) {
      const base64String = btoa(applicationIdV1 + "|" + referenceIdV1); 
      appointmentURL = appointmentURL + '?a=' + base64String;
    }

    window.open(appointmentURL, '_blank');
  }

}
