<div class="image_upload_container">

    <div class="modal-header">
        <h4>Document Upload History - {{requestObject.docTypeLabel}}</h4>
        
        <i class="fa fa-times" aria-hidden="true" (click)="cancel()"></i>
    </div>

    <div class="modal-body">

        <div class="history_modal">

            <div class="history_modal_info">
                <div class="history_info-inner">
                    <table class="table">
                        <tr>
                            <th width="12%">S.No</th>
                            <th width="47%">Action On</th>
                            <th width="28%">Status</th>
                            <th width="13%">Action</th>
                        </tr>
                        
                        <ng-container *ngFor="let docObject of documentHistoryList; let i = index" >
                            <tr [ngClass]="i === clickedRowNumber ? 'highlight_row' : ''" style="border-bottom: 0px;">
                                <td rowspan="2" width="12%">{{docObject.count}}</td>
                                <td width="47%">{{docObject.approvedOn}}</td>
                                <td width="28%" [innerHTML]="docObject.approvalStatusLabel"></td>
                                <td width="13%"><i (click)="viewDocument(docObject, i)" class="fa fa-eye" aria-hidden="true"></i></td>
                            </tr>
                            
                            <tr [ngClass]="i === clickedRowNumber ? 'highlight_row' : ''">
                                <td width="12%"></td>
                                <td colspan="4" width="88%">{{docObject.comments}}</td>
                            </tr>
                        </ng-container>

                    </table>
                </div>
            </div>

            <div *ngIf="utils.isEmpty(base64Data) === false" class="history_info_prv">
                <ng-container *ngIf="isPDFDoc === false">
                    <perfect-scrollbar class="ps-show-always">
                        <img [src]="base64Data" style="height: 100%; width: 100%;" >
                    </perfect-scrollbar>
                </ng-container>
                
                <pdf-viewer *ngIf="isPDFDoc === true" [src]="base64Data" [fit-to-page]="true"></pdf-viewer>
            </div>

        </div>

    </div>

</div>
