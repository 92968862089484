<app-header></app-header>

<div class="wrapper">
    
    <div class="center_body">

      <form action="" method="post" name="paymentSuccessForm" autocomplete="off">

          <input type="hidden" name="applicationId" value="" />

          <input type="hidden" name="txnReference" value="" />

          <input type="hidden" name="downloadType" value="" />

          <div class="card">
              <div style="border-radius:200px; height:100px; width:100px; background: #f2f9e8; margin:0 auto; border:1px solid #f0f0f0">
                  <!-- <i class="checkmark">✓</i> -->
              <!-- <i class="fa fa-check-circle" aria-hidden="true"></i> -->
              <img src="assets/images/tick.png" alt="sucess" style="width:57px;padding-top:20px;">
              </div>

              <h1>Payment Successfull!</h1>

              <div class="info_paid">
                  <span><b>Transaction Reference</b> {{paymentDetailsObject.txnRefNumber}}</span>
                  <span><b>Validation Number</b> {{paymentDetailsObject.validationNo}}</span>
                  <span><b>Total Amount</b> {{paymentDetailsObject.currencySymbol}}{{paymentDetailsObject.totalAmount}}</span>
              </div>

              <h6>Paid Application</h6>
              <table style="width: 100%;">
                  <thead>
                      <tr>
                          <th width="20%" data-label="application id" nowrap scope ="row">Application No.</th>
                          <th width="30%" data-label="Full Name" nowrap>Full Name</th>
                          <th width="23%" data-label="Passport Type" nowrap>Passport Type</th>
                          <th width="12%" data-label="Fee" nowrap style="text-align:right;">Fee</th>
                          <th width="15%" data-label="action">Action</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td width="20%" data-label="application id" scope ="row"><span>{{paymentDetailsObject.applicationId}}</span></td>
                          <td width="30%" data-label="Full Name">{{paymentDetailsObject.fullName}}</td>
                          <td width="23%" data-label="Passport Type">{{paymentDetailsObject.passportType}}</td>
                          <td width="12%" data-label="Fee" style="text-align:right;">{{paymentDetailsObject.currencySymbol}}{{paymentDetailsObject.applicationFee}}</td>
                          <td width="15%" data-label="Action" style="opacity:0.81;">
                              <span tooltip="View" placement="bottom" (click)="viewApplication();" style="margin-left: 10px; cursor: pointer;"><i class="fa fa-eye" aria-hidden="true"></i></span>
                              <span tooltip="Download Application" placement="bottom" (click)="downloadPDF(constants.APPLICATION_DOWNLOAD);" style="margin-left: 10px; cursor: pointer;"><i class="fa fa-download" aria-hidden="true"></i></span>
                              <span tooltip="Download Receipt" placement="bottom" (click)="downloadPDF(constants.RECEIPT_DOWNLOAD);" style="margin-left: 10px; cursor: pointer;"><img src="assets/images/receipt.png" style="width:20px;"></span>
                          </td>
                      </tr>
                  </tbody>
              </table>

              <input type="button" (click)="openApplicationPage()" value="Start New Application" class="save_btn">

              <input type="button" (click)="openHomePage()" value="Go to Home" class="custom_btn">
          </div>

      </form>

  </div>
</div>
<app-footer></app-footer>
