import { Injectable } from '@angular/core';

@Injectable({ 
    providedIn: 'root'
})
export class Tags {
    
    RESPONSE_CODE = 'responseCode';
    
    RESPONSE_MESSAGE = 'responseMsg';

    APPLICATION_TOKEN = 'USD_APPLICATION_TOKEN';

    AUTHORIZATION = 'server-authorization';

    SERVER_APPLICATION_TOKEN = 'x-access-token';

    KEYCLOAK_TOKEN = 'KEYCLOAK_TOKEN';

    KEYCLOAK_REFRESH_TOKEN = 'KEYCLOAK_REFRESH_TOKEN';
    
    EMAIL_VERIFICATION_STATUS = 'EMAIL_VERIFICATION_STATUS';

    PROJECT_BUILD_DATE = 'PROJECT_BUILD_DATE';

    LOGGED_USER_ID = 'USD_PASSPORT_LOGGED_USER_ID';

    LOGGED_USER_NAME = 'USD_PASSPORT_LOGGED_USER_NAME';

    LOGGED_USER_TYPE = 'USD_PASSPORT_LOGGED_USER_TYPE';

    USER_TYPE_JSON_KEY = 'userTypeJSONKey';

    LOGGED_USER_CURRENT_ROUTE = 'USD_PASSPORT_LOGGED_USER_CURRENT_ROUTE';

    IS_NOTICE_MODAL_OPEN = 'IS_NOTICE_MODAL_OPEN';
    
}
