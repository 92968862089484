<ng-container *ngIf="isLoggedIn === false">

    <app-header-no-session></app-header-no-session>
    
    <div class="banner_wrapper">
        <div class="home_banner_img">
            <div class="banner_content">
                 <p>Welcome to</p>
                <h1>Nigeria Passport Service</h1>
            </div>
        </div>
    </div>
    
    <main class="home_page page">
        <div class="main">
            <h1 class="main-heading green-text text_center">Apply for a Passport</h1>
            <div class="steps_wrapper" style="padding-bottom: 50px;">
                <div class="steps_items">

                    <div class="steps_item">
                        <div class="steps_item_inner">
                            <div class="steps_head">
                                <div class="icon">
                                    <img src="assets/images/login_icon.svg" alt="Login">
                                </div>
                                <h2>Individual Application</h2>
                            </div>
                            <p>You are required to login or authenticate to continue to your application. </p>
                            <button type="button" (click)="doLogin()">
                                Sign In <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>

                    <div class="steps_item">
                        <div class="steps_item_inner">
                            <div class="steps_head">
                                <div class="icon">
                                    <img src="assets/images/family_login_icon.png" alt="Login">
                                </div>
                                <h2>Family Application</h2>
                            </div>
                            <p>You are required to login or authenticate to continue to your application. </p>
                            <button type="button" (click)="doLogin()">
                                Sign In <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    
                    <div class="steps_item">
                        <div class="steps_item_inner">
                            <div class="steps_head">
                                <div class="icon">
                                    <img src="assets/images/file_form.svg" alt="File Application Form">
                                </div>
                                <h2>Fill Application Form</h2>
                            </div>
                            <p>Let’s know in brief about your personal, contact and kin's information.</p>
                            <p>Please visit our <a href="javascript:void(0)" (click)="openHelpPage()">Form Help</a> page to understand the data to be provided.</p> 
                        </div>
                    </div>
    
                    <div class="steps_item">
                        <div class="steps_item_inner">
                            <div class="steps_head">
                                <div class="icon">
                                    <img src="assets/images/make_payment.svg" alt="Make Payment">
                                </div>
                                <h2>Make Payment</h2>
                            </div>
                            <p>For us to process your application you must make payment first.</p>
                            <p>Please <a href="javascript:void(0)" (click)="openFAQ()" >Visit our FAQ</a> page for any payment related questions.</p>                            
                        </div>
                    </div>
    
                    <div class="steps_item">
                        <div class="steps_item_inner">
                            <div class="steps_head">
                                <div class="icon">
                                    <img src="assets/images/appointment.svg" alt="Book an Appointment">
                                </div>
                                <h2>Book an Appointment</h2>
                            </div>
                            <p>You are required to book an appointment at Embassy/Authorized Centre to get your biometrics and document verification.</p>
                        </div>
                    </div>
    
                    <!-- <div class="steps_item">
                        <div class="steps_item_inner">
                            <div class="steps_head">
                                <div class="icon">
                                    <img src="assets/images/embassy.svg" alt="Visit Embassy/Authorized center">
                                </div>
                                <h2>Visit Embassy/Authorized Center</h2>
                            </div>
                            <p>Visit the Embassy/Authorized Centre for the document verification. </p>
                            
                            <p><a href="#">Click here</a>  to know the locations of embassies/authorized center and working hours.</p>    
                        </div>
                    </div> -->
                    
                </div>
            </div>
        </div>
            
        <!-- <section class="track-application-wrapper">
            <div class="main">
                <div class="track-application-inner">
                    <div class="track-wrap">
                        <h3>Track your application</h3>
                        <p>If your application is paid and your biometrics are completed, you can track  your passport status here:</p>
                        <form>
                            <div class="input-block">
                                <div class="form-control">
                                    <label>Application No.</label>
                                    <input type="text">
                                </div>
                                <div class="form-control">
                                    <label>Reference No.</label>
                                    <input type="text">
                                </div>
                            </div>
                            <button (click)="openTrackApplication()" class="pointer">Track Application</button>
                        </form>
                    </div>
                </div>
            </div>
        </section> -->
    </main>
    
    <app-footer-no-session></app-footer-no-session>

</ng-container>


