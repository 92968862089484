import { FormGroup } from '@angular/forms';
import { Constants } from '../common/constants';

export function ValidateDateOfBirth(dateOfBirthDay: string, dateOfBirthMonth: string, dateOfBirthYear: string, ageGroup: string, isValidateAgeGroup: string) {
    return (formGroup: FormGroup) => {
        const formGroupDateOfBirthDayObject = formGroup.controls[dateOfBirthDay];
        const formGroupDateOfBirthMonthObject = formGroup.controls[dateOfBirthMonth];
        const formGroupDateOfBirthYearObject = formGroup.controls[dateOfBirthYear];

        if (!formGroupDateOfBirthDayObject || !formGroupDateOfBirthMonthObject || !formGroupDateOfBirthYearObject) {
          return null;
        }
        
        const constants = new Constants();

        const isValidateAgeGroupInt = Number(isValidateAgeGroup);
        
        if(isValidValue(formGroupDateOfBirthDayObject.value) === false || isValidValue(formGroupDateOfBirthMonthObject.value) === false || isValidValue(formGroupDateOfBirthYearObject.value) === false || (isValidateAgeGroupInt === constants.TRUE && isValidValue(ageGroup) === false)){
            return null;
        }

        const givenDateOfBirthDay = formGroupDateOfBirthDayObject.value;
        const givenDateOfBirthMonth = formGroupDateOfBirthMonthObject.value;
        const givenDateOfBirthYear = formGroupDateOfBirthYearObject.value;

        const requiredDateOfBirthValue = givenDateOfBirthMonth + "/" + givenDateOfBirthDay + "/" + givenDateOfBirthYear;
        const dateOfBirthValue = new Date(requiredDateOfBirthValue);
        dateOfBirthValue.setFullYear(givenDateOfBirthYear); //This is used to set the year bcoz 0000 change to 2000 if not set this

        if(isNaN(dateOfBirthValue.getTime()) === true) {
            formGroupDateOfBirthDayObject.setErrors({ hasError: true, errorMessage: "Date of Birth should be valid date!"});
            return;
        }

        const objectDateDay = dateOfBirthValue.getDate();
        const objectDateMonth = (dateOfBirthValue.getMonth() + 1);

        if(Number(givenDateOfBirthDay) !== objectDateDay || Number(givenDateOfBirthMonth) !== objectDateMonth){
            formGroupDateOfBirthDayObject.setErrors({ hasError: true, errorMessage: "Date of Birth should be valid date!"});
            return;
        }

        const todayDate = new Date();

        if (dateOfBirthValue.getTime() > todayDate.getTime()) {
            formGroupDateOfBirthDayObject.setErrors({ hasError: true, errorMessage: "Date of Birth can't be future date!"});
            return;
        }

        const minimumDateOfBirthValue = givenDateOfBirthMonth + "/" + givenDateOfBirthDay + "/" + constants.MIN_DATE_OF_BIRTH_YEAR;
        const minimumDateOfBirthObject = new Date(minimumDateOfBirthValue);

        if (dateOfBirthValue.getTime() < minimumDateOfBirthObject.getTime()) {
            formGroupDateOfBirthDayObject.setErrors({ hasError: true, errorMessage: "Year of Birth should be after "+constants.MIN_DATE_OF_BIRTH_YEAR+"!"});
            return;
        }

        formGroupDateOfBirthDayObject.setErrors(null);

        if(isValidateAgeGroupInt === constants.FALSE){
            return;
        }

        const age = fetchAge(dateOfBirthValue, todayDate);

        if(ageGroup === constants.MINOR && age > 17){
            formGroupDateOfBirthDayObject.setErrors({ hasError: true, errorMessage: "Age of the applicant must be less than 18 years!"});
            return;
        } else if(ageGroup === constants.ADULT && (age > 59 || age < 18)){
            formGroupDateOfBirthDayObject.setErrors({ hasError: true, errorMessage: "Applicant age must be between 18 and 60 years!"});
            return;
        } else if(ageGroup === constants.SENIOR_CITIZEN && (age > 100 || age < 60)){
            formGroupDateOfBirthDayObject.setErrors({ hasError: true, errorMessage: "Applicant age must be between 60 and 100 years!"});
            return;
        }

        formGroupDateOfBirthDayObject.setErrors(null);

        return null;
    }
}

function isValidValue(givenValue: any){
    if(givenValue === undefined || givenValue === null  || givenValue === "" ){
        return false;
    }
    return true;
}

function fetchAge(dateOfBirthValue: Date, todayDate: Date) {
    let age = todayDate.getFullYear() - dateOfBirthValue.getFullYear();
    let month = todayDate.getMonth() - dateOfBirthValue.getMonth();
    if (month < 0 || (month === 0 && todayDate.getDate() < dateOfBirthValue.getDate())) {
        age--;
    }    
    return age;
}