import { Component, NgZone, OnInit } from '@angular/core';
import { Utils } from '../common/utils';
import { Constants } from '../common/constants';
import { Tags } from '../common/tags';
import { LocalStorage } from '../common/local-storage';
import { GUI } from '../common/gui';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { Route } from '../common/route';
import { NoticeModalComponent } from '../notice-modal/notice-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';

@Component({
  selector: 'app-home-new-v2',
  templateUrl: './home-new-v2.component.html',
  styleUrls: ['./home-new-v2.component.css']
})
export class HomeNewV2Component implements OnInit {

  private isLoggedIn = false;

  constructor(private readonly keycloakService: KeycloakService, private utils: Utils, public constants: Constants, private tags: Tags, private localStorage: LocalStorage, public gui: GUI,
              private router: Router, private route: Route, private ngZone: NgZone, private simpleModalService: SimpleModalService) { }

  async ngOnInit() {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();
    this.openNoticeModal();
  }

  doLogin(redirectRouteName: string): void {
    this.localStorage.setStorageKey(this.tags.LOGGED_USER_CURRENT_ROUTE, this.utils.encryptString(redirectRouteName));

    if(this.isLoggedIn === false) {
      this.keycloakService.login();    
      return;
    }
    
    this.gui.redirectControl(redirectRouteName);
  }

  openTrackApplicationForm(): void {
    window.open(environment.trackApplicationUrl, "_blank");
  }

  openCentreAvailabilityForm(): void {
    window.open(environment.checkCentreAvailabilityUrl, "_blank");
  }

  openTestImageForm(): void {
    this.ngZone.run(()=>this.router.navigate([this.route.IMAGE_TEST]));
  }

  openPassportApplicationRequirements(): void {
    window.open(environment.passportApplicationRequirementsUrl, "_blank");
  }

  openNoticeModal(): void {
    const isNoticeModalOpenPrevious = this.localStorage.getStorageKey(this.tags.IS_NOTICE_MODAL_OPEN);
    if(this.utils.isEmpty(isNoticeModalOpenPrevious) === true){
      this.localStorage.setStorageKey(this.tags.IS_NOTICE_MODAL_OPEN, this.constants.TRUE.toString());
    } else if(Number(isNoticeModalOpenPrevious) === this.constants.TRUE){
      return;
    }
    const message = "This is the Passport Application Portal for applicants applying from outside Nigeria. If you are applying from outside Nigeria, click on the <b>Continue</b> button. <br /> <br />To apply from within Nigeria, click on <b>Apply from within Nigeria</b>."
    this.simpleModalService.addModal(NoticeModalComponent, {message: message}, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
      if(isConfirmed === false){
        window.location.href = environment.ngnPassportApplicationUrl;
      }
    });
  }

}
