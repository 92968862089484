import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-no-session',
  templateUrl: './footer-no-session.component.html',
  styleUrls: ['./footer-no-session.component.css']
})
export class FooterNoSessionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
