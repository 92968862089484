<div class="back-ground gurantor-form">
    <div class="pageWrapper">

        <kendo-pdf-export #pdf paperSize="A4" [margin]="margin" [scale]="scale">

            <div id="gurantor_header" class="center" >
                <img src="assets/images/logo-nis.png" style=" width: 160px; height: 42px;">
            </div>

            <h2 class="center" style="font-family:sfui-regular,sans-serif; margin:0;">Passport Guarantor's Form</h2>

            <table>
                <tr>
                    <td style="width:75%;">
                        <div>
                            <table width="100%">
                                <tr>
                                    <td>Full Name:</td>
                                    <td style="border-bottom:1px dotted #000;width:78%;">{{guarantorFormDetailsObject.fullName}}</td>
                                </tr>
                                <tr>
                                    <td>Gender:</td>
                                    <td style="border-bottom:1px dotted #000;width:78%;">{{guarantorFormDetailsObject.gender}}</td>
                                </tr>
                                <tr>
                                    <td>Date of Birth:</td>
                                    <td style="border-bottom:1px dotted #000;width:78%;">{{guarantorFormDetailsObject.dateOfBirth}}</td>
                                </tr>
                                <tr>
                                    <td>State of Origin:</td>
                                    <td style="border-bottom:1px dotted #000;width:78%;">{{guarantorFormDetailsObject.contactState}}</td>
                                </tr>
                                <tr>
                                    <td>Occupation:</td>
                                    <td style="border-bottom:1px dotted #000;width:78%">{{guarantorFormDetailsObject.occupation}}</td>
                                </tr>
                            </table>
                        </div>
                    </td>
                    <td style="width:2%;"></td>
                    <td style="width:20%;" >
                        <div style="margin-left: 4px;border:1px solid #333;width:130px;height:140px;vertical-align:middle;text-align:center;padding:2px;"> Guarantor's Passport Photograph Duly Signed at the Reverse Side </div>
                    </td>
                </tr>
            </table>

            <p STYLE="margin:2px;"><span style="font-weight:bold;">Notes:</span> Guarantor's are warned that any false declaration on this form will attract severe consequences which may include prosecution.</p>
            <p>To be furnished by Applicants for Nigerian Passports</p>
            <h5 style="text-decoration: underline;padding:0;margin:0;">PART I </h5>
            <span style="font-size:11px;"> (Particulars of Guarantor's (To be completed in block letters))</span>
            <table>
                <tbody>
                    <tr>
                        <td>Name(s)</td>
                        <td style="width:78%;">
                            <div>...................................................................................................................................</div>
                        </td>
                    </tr>
                    <tr>
                        <td>Profession</td>
                        <td style="width:78%;">
                            <div>...................................................................................................................................</div>
                        </td>
                    </tr>
                    <tr>
                        <td>Business Address</td>
                        <td style="width:78%;">
                        <div>...................................................................................................................................</div>
                        </td>
                    </tr>
                    <tr>
                        <td>Residential Address</td>
                        <td style="width:78%;">
                        <div>...................................................................................................................................</div>
                        </td>
                    </tr>

                </tbody>
            </table>

            <div class="center">(if not related state any other connection)</div>
            <h4>Particulars of Asset</h4>

            <table>
                <tbody>
                    <tr>
                        <td style="width:40%;">(a) Cash Available:</td>
                        <td style="width:60%;"><div>.......................................................................................................................</div></td>
                    </tr>
                    <tr>
                        <td style="width:40%;">(b)  Names and Address of Banker(s):</td>
                        <td style="width:60%;"><div>.........................................................................................................</div></td>
                    </tr>
                    <tr>
                    <td style="width:40%;"></td>
                        <td><div>....................................................................................................................................................................</div>
                        </td>
                    </tr>
                    <tr>
                        <td style="width:40%;">(c) Houses:</td>
                        <td style="width:60%;"><div>..............................................................................................................................</div></td>
                    </tr>
                    <tr>
                        <td style="width:40%;"></td>
                        <td><div>...................................................................................................................................................................</div>
                        </td>
                    </tr>
                    <tr>
                        <td style="width:40%;">(d) Other Assets:</td>
                        <td style="width:60%;"><div>.............................................................................................................................</div></td>
                    </tr>
                </tbody>
            </table>

            <h5 style="text-decoration: underline;padding: 0;margin:0;">PART II </h5>
            <span style="font-size:0.785em;"> (To be completed in case of all applicants proceeding overseas except those sponsored by the Federal or State Government or Corporations) </span>
            <div style="line-height:15px;">
                <table width="100%" cellspacing="0" cellpadding="1" border="0" id="">
                    <tbody>
                        <tr>
                            <td style="width: 1%;">I </td>
                            <td style="width: 22%;"><div>........................................................................................</div></td>
                            <td style="width: 12%;"><div>......................................................</div></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="center">(Full Name)</td>
                            <td class="center">(Nationality)</td>
                        </tr>
                        <tr>
                            <td style="width: 1%;">I </td>
                            <td style="width: 22%;"><div>........................................................................................</div> </td>
                            <td style="width: 12%;"><div>......................................................</div> </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="center"> (Profession)&nbsp;&nbsp;&nbsp;</td>
                            <td class="center">(Full Address)</td>
                        </tr>
                        <tr>
                            <td colspan="3" valign="bottom">Do hereby solemnly and sincerely declare and say as follows:</td>
                        </tr>
                        <tr>
                            <td colspan="3" valign="bottom">(i) That (Name of Applicant)........................................................................is
                            proposing with my full knowledge and consent to proceed to (Name of Country)............................... for the purpose of
                            ...................................................................................................................................................................</td>
                        </tr>
                        <tr>
                            <td colspan="3" valign="bottom">(ii) That the particulars of asset given in Part I hereof are correct.</td>
                        </tr>
                        <tr>
                            <td colspan="3" valign="bottom">(iii) That I will be responsible for the cost of living, accommodation and repatriation if necessary.</td>
                        </tr>
                        <tr>
                            <td colspan="3" valign="bottom">And I make this solemn declaration conscientiously believing the same to be true by virtue of the statutory Declaration Act., 1963.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p style="height: 80px;"></p>

            <div style="page-break-after: always; page-break-before: always;">

                <div id="gurantor_header" class="center" style="padding-top: 10px;">
                    <img src="assets/images/logo-nis.png" style=" width: 160px; height: 42px;">
                </div>

                <h2 class="center">Passport Guarantor's Form</h2>

                <table>
                    <tr>
                        <td>Full Name:</td>
                        <td style="border-bottom:1px dotted #000;width:80%;">{{guarantorFormDetailsObject.fullName}}</td>
                    </tr>
                    <tr>
                        <td>Gender:</td>
                        <td style="border-bottom:1px dotted #000;width:80%;">{{guarantorFormDetailsObject.gender}}</td>
                    </tr>
                    <tr>
                        <td>Date of Birth:</td>
                        <td style="border-bottom:1px dotted #000;width:80%;">{{guarantorFormDetailsObject.dateOfBirth}}</td>
                    </tr>
                    <tr>
                        <td>Country of Origin:</td>
                        <td style="border-bottom:1px dotted #000;width:80%;">Nigeria</td>
                    </tr>
                    <tr>
                        <td>State of Origin:</td>
                        <td style="border-bottom:1px dotted #000;width:80%;">{{guarantorFormDetailsObject.contactState}}</td>
                    </tr>
                    <tr>
                        <td>Occupation:</td>
                        <td style="border-bottom:1px dotted #000;width:80%">{{guarantorFormDetailsObject.occupation}}</td>
                    </tr>
                </table>

                <h5 style="vertical-align:middle;text-align:center;text-decoration: underline;padding: 2px 0;">PART III	</h5>
                <p class="center" style="font-size:11px;line-height:1px;"> (To be completed in all cases)</p>

                <table>
                    <tbody>
                        <tr>
                            <td style="width:80px;">Where as</td>
                            <td>
                                <div>...................................................................................................................................</div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width:80px;">of</td>
                            <td>
                                <div>...................................................................................................................................</div>
                            </td>
                        </tr>
                        </tbody>
                </table>

                <p>(hereinafter referred to as "the Applicant") wishes to travel outside Nigeria for the purpose of</p>
                <div>....................................................................................................................................................</div>
                <div>....................................................................................................................................................</div>
                <p>AND WHEREAS the said Applicant has applied to the Government of the Federal Republic of Nigeria (hereinafter referred to as "the Federal Government") for a PASSPORT to facilitate this journey.</p>
                <p>NOW THEREFORE IN CONSIDERATION of the said PASSPORT by the Federal Government to the said Applicant.</p>
                <p>I/WE .............................................................................................................................................</p>
                <p>	of ............................................................................................................................. (hereinafter referred to as "the Guarantor(s)")</p>
                <p>HEREBY AGREE as follows:</p>
                <ul>
                    <li>  I/WE hold myself/ourselves responsible for the cost of repatriating the said Applicant to Nigeria; and</li>
                    <li> I/WE will indemnify the Federal Government against all or any expenses incurred by the Federal Government for the eventual repatriation of the said Applicant to Nigeria.</li>
                </ul>

                <br/>

                <div style="line-height:15px;">
                    <table width="100%" cellspacing="0" cellpadding="1" border="0" id="">
                        <tbody>
                            <tr>
                                <td width="50%"><br/><div class="center">RIGHT THUMB PRINT</div></td>
                                <td width="50%"><div>......................................................</div>  <span class="center">Signature of Guarantor's/Declarant</span></td>
                            </tr>
                        </tbody>
                    </table>

                    <br/>

                    <p class="center"><b>NOTICE OF REQUIREMENTS FOR GUARANTOR</b></p>
                    <ul>
                        <li> One Passport Photograph of the Guarantor's.</li>
                        <li> Photocopy of the data page of the Guarantor's Current International Passport</li>
                        <li> Photocopies of the following Guarantor's documents:
                            <ul style="padding-left:20px;list-style:none !important;">
                                <li>a. National I.D. Card.</li>
                                <li>b. National Driver's Licence</li>
                            </ul>
                        </li>
                    </ul>

                    <div class="center">
                        <p><b>Commissioner's oath:</b></p>
                        <p>Sworn to at .....................................................................................................</p>
                        <p>Registry this ....................................................day of ........................20....................before me.</p>
                        <br/><p>...................................................................</p>
                        <p><b>Commissioner for oaths</b></p>
                    </div>

                    <span>Note:</span>
                    <ul>
                        <li>This section must be sworn before either a magistrate, Justice of the Peace, a Commissioner for oaths or a Notary Public and guarantor's should note that falsity of this declaration may amount to a criminal offence.</li>
                        <li> This Form of understanding and indemnity must be presented to the commissioner for stamp Duties for stamping within forty days from the Date it is sworn to.</li>
                    </ul>
                </div>

            </div>

        </kendo-pdf-export>

        <div class="center hide">
            <input type="button" (click)="backToDashboard()" value="Back" style="background:#636363;">
            <input type="button" kendo-button (click)="pdf.saveAs('Gurantor-Form.pdf')" value="Download PDF" style="background:#f96922;">
            <input type="button" (click)="printGurantorForm()" value="Print" style="background:#0d7836;">
        </div>

    </div>

</div>
