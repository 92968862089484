<app-header *ngIf="isSearchFromHome === constants.FALSE" class="view-header"></app-header>

<app-header-no-session *ngIf="isSearchFromHome === constants.TRUE" class="view-header"></app-header-no-session>

<div class="wrapper">
    
    <div class="banner-img-name view-top">

        <div class="main" style="padding-bottom: 0;">
            <h2>Preview Application</h2>
        </div>

    </div>

    <section class="main" style="width: 90%">

        <div class="profile-view">

            <table class="h_logo">
                <tr>
                    <td></td>
                    <td><img src="assets/images/logo-nis.png" style="width:140px;"></td>
                    <td></td>
                </tr>
            </table>

            <div class="s-head">

                <div class="result-header">

                    <div class="user-info-detail">

                        <div class="upper_bar" style="border-bottom:1px solid #979797;">

                            <div class="bar_code">
                                <div class="barcode">
                                    <ngx-barcode [bc-value]="constants.PASSPORT_TYPE + '-' + applicationDetailsObject.applicationId" [bc-display-value]="true" [bc-width]="1" [bc-height]="40" [bc-font-size]="14"></ngx-barcode>
                                </div>
                            </div>

                            <div class="visa_type" style="text-align: right;">
                                <p>{{applicationDetailsObject.passportType}} Passport {{applicationDetailsObject.applicationType}} Application</p>
                            </div>

                        </div>

                        <div class="common_view">

                            <div>
                                <label>Application No.</label>
                                <p class="p-body">{{applicationDetailsObject.applicationId}}</p>
                            </div>

                            <div>
                                <label>Reference No.</label>
                                <p class="p-body">{{applicationDetailsObject.referenceId}}</p>
                            </div>

                            <div>
                                <label>Application Date (DD/MM/YYYY)</label>
                                <p class="p-body">{{applicationDetailsObject.applicationDate}}</p>
                            </div>

                            <div>
                                <label>Payment Status</label>
                                <p class="p-body"><img [src]="applicationDetailsObject.paymentStatusImageURL" class="status_img" style="width: 100%; max-width: 51px;"></p>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <section id="applicationdetail">

                <div class="s-content">

                    <div class="border_line">

                        <p class="p-body line">Passport Details</p>

                        <div class="sr-p-info" >

                            <div>
                                <label>Applying For</label>
                                <p>{{applicationDetailsObject.applicationType}}</p>
                            </div>

                            <div *ngIf="utils.isEmpty(applicationDetailsObject.passportNo) === false">
                                <label>Passport Number</label>
                                <p>{{applicationDetailsObject.passportNo}}</p>
                            </div>

                            <div>
                                <label>Age Group</label>
                                <p>{{applicationDetailsObject.ageGroup}}</p>
                            </div>

                            <div>
                                <label>Processing Country</label>
                                <p>{{applicationDetailsObject.processingCountry}}</p>
                            </div>

                            <div [style.borderBottom]="applicationDetailsObject.applicationType === 'Fresh' ? '1px solid #aeaeae !important' : '0px !important'">
                                <label>Processing Embassy</label>
                                <p>{{applicationDetailsObject.processingEmbassy}}</p>
                            </div>

                            <div>
                                <label>Booklet Type / Validity</label>
                                <p>{{applicationDetailsObject.bookletType}} / {{applicationDetailsObject.validity}}</p>
                            </div>

                        </div>

                    </div>

                    <div class="border_line">

                        <p class="p-body line">Personal Details</p>

                        <div class="sr-p-info">

                            <div>
                                <label>Full Name</label>
                                <p>{{applicationDetailsObject.fullName}}</p>
                            </div>

                            <div>
                                <label>Date of Birth (DD/MM/YYYY)</label>
                                <p>{{applicationDetailsObject.dateOfBirth}}</p>
                            </div>

                            <div>
                                <label>Place of Birth</label>
                                <p>{{applicationDetailsObject.placeOfBirth}}</p>
                            </div>

                            <div>
                                <label>Gender</label>
                                <p>{{applicationDetailsObject.gender}}</p>
                            </div>

                            <div>
                                <label>Marital Status</label>
                                <p>{{applicationDetailsObject.maritalStatus}}</p>
                            </div>

                            <div>
                                <label>Occupation</label>
                                <p>{{applicationDetailsObject.occupation}}</p>
                            </div>

                            <div>
                                <label>Maiden Name</label>
                                <p>{{applicationDetailsObject.mothersMaidenName}}</p>
                            </div>

                            <div>
                                <label>NIN No.</label>
                                <p>{{applicationDetailsObject.nin}}</p>
                            </div>

                        </div>

                    </div>

                    <div class="border_line">

                        <p class="p-body line">Contact Information</p>

                        <div class="sr-p-info" >

                            <div>
                                <label>E-mail Address</label>
                                <p>{{applicationDetailsObject.email}}</p>
                            </div>

                            <div>
                                <label>Contact Number</label>
                                <p>{{applicationDetailsObject.contactNumber}}</p>
                            </div>

                            <div>
                                <label>Current Address</label>
                                <p>{{applicationDetailsObject.currentAddress}}</p>
                            </div>

                            <div>
                                <label>Permanent Address</label>
                                <p>{{applicationDetailsObject.permanentAddress}}</p>
                            </div>

                            <div>
                                <label>State of Origin</label>
                                <p>{{applicationDetailsObject.stateOfOrigin}}</p>
                            </div>

                            <div style="border-bottom:1px solid #aeaeae !important;">
                                <label>Home Town</label>
                                <p>{{applicationDetailsObject.homeTown}}</p>
                            </div>

                            <div>
                                <label>Additional Number</label>
                                <p>{{applicationDetailsObject.additionalContactNumber}}</p>
                            </div>

                        </div>

                    </div>

                    <div class="border_line">

                        <p class="p-body line pb10">Kin Information</p>

                        <div class="sr-p-info" >

                            <div>
                                <label>Name of Kin</label>
                                <p>{{applicationDetailsObject.nextOfKin}}</p>
                            </div>

                            <div>
                                <label>Relationship</label>
                                <p>{{applicationDetailsObject.relation}}</p>
                            </div>

                            <div>
                                <label>Address</label>
                                <p>{{applicationDetailsObject.nextOfKinAddress}}</p>
                            </div>

                            <div>
                                <label>Contact No.</label>
                                <p>{{applicationDetailsObject.nextOfKinContactNumber}}</p>
                            </div>

                        </div>

                    </div>

                    <ng-container *ngIf="isShowPaymentDetails === true">

                        <div class="half_col pay_details">

                            <div class="half_col_box" style="margin-right:10px;">

                                <p class="p-body line">Fee Details</p>

                                <div class="tab50">

                                    <div>
                                        <label>Passport Booklet Fee</label>
                                        <span class="fr">{{applicationDetailsObject.currencySymbol}}{{applicationDetailsObject.bookletFee | number : '1.2' : 'en-US'}}</span>
                                    </div>

                                    <div>
                                        <label>Service Charge</label>
                                        <span class="fr">{{applicationDetailsObject.currencySymbol}}{{applicationDetailsObject.transactionFee | number : '1.2' : 'en-US'}}</span>
                                    </div>

                                    <div class="total_box">
                                        <label>Total Fee</label>
                                        <span class="fr">{{applicationDetailsObject.currencySymbol}}{{applicationDetailsObject.totalFee | number : '1.2' : 'en-US'}}</span>
                                    </div>

                                </div>

                            </div>

                            <div class="half_col_box">

                                <p class="p-body line">Payment Details</p>

                                <div class="tab50">

                                    <div>
                                        <label>Payment Date (DD/MM/YYYY HH:MM)</label>
                                        <span class="fr">{{applicationDetailsObject.paymentDate}}</span>
                                    </div>

                                    <div>
                                        <label>Payment Gateway</label>
                                        <span class="fr">{{applicationDetailsObject.paymentGateway}}</span>
                                    </div>

                                    <div *ngIf="utils.isEmpty(applicationDetailsObject.paymentMethod) === false">
                                        <label>Payment Mode</label>
                                        <span class="fr">{{applicationDetailsObject.paymentMethod}}</span>
                                    </div>

                                    <div *ngIf="utils.isEmpty(applicationDetailsObject.gatewayReference) === false">
                                        <label>Gateway Reference</label>
                                        <span class="fr">{{applicationDetailsObject.gatewayReference}}</span>
                                    </div>

                                    <div *ngIf="utils.isEmpty(applicationDetailsObject.validationNo) === false">
                                        <label>Validation Number</label>
                                        <span class="fr">{{applicationDetailsObject.validationNo}}</span>
                                    </div>

                                    <div *ngIf="utils.isEmpty(applicationDetailsObject.pan) === false">
                                        <label>PAN</label>
                                        <span class="fr">{{applicationDetailsObject.pan}}</span>
                                    </div>

                                    <div *ngIf="utils.isEmpty(applicationDetailsObject.authCode) === false">
                                        <label>Auth Code</label>
                                        <span class="fr">{{applicationDetailsObject.authCode}}</span>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </ng-container>

                </div>

            </section>

            <div class="view-buttons">
                <input type="button" (click)="backToPage()" value="Close" class="back_btn">

                <input type="button" (click)="printApplication()" value="Print" class="save_btn">

                <input type="button" (click)="printReceipt()" value="Print Receipt" class="custom_btn pay_btn">

                <input type="button" (click)="isShowPaymentDetails = !isShowPaymentDetails" [value]="isShowPaymentDetails === true ? 'Hide Payment Details' : 'Show Payment Details'" class="custom_btn">
            </div>

        </div>


    </section>

</div>

<app-footer class="view-footer"></app-footer>
