import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Route } from './route';
import { Utils } from './utils';
import { Constants } from './constants';

@Injectable({ 
    providedIn: 'root'
})
export class GUI {

    constructor(private ngZone: NgZone, private router: Router, private route: Route, private utils: Utils, private constants: Constants) {

    }

    openHomePage(): void {
        this.ngZone.run(()=>this.router.navigate([this.route.HOME]));
    }

    openNewHomePage(): void {
        this.ngZone.run(()=>this.router.navigate([this.route.HOME_NEW]));
    }

    openDashboardPage(): void {
        this.ngZone.run(()=>this.router.navigate([this.route.DASHBOARD]));
    }

    openApplicationAddPage(applicantId: string): void {
        this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_ADD_EDIT, this.utils.encryptString(applicantId)]));
    }

    openApplicationEditPage(applicantId: string): void {
        this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_ADD_EDIT, this.utils.encryptString(applicantId)]));
    }

    openApplicationViewPage(applicantId: string): void {
        this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_VIEW, this.utils.encryptString(applicantId)]));
    }

    openApplicationViewPageV1(applicationId: string, referenceId: string): void {
        this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_VIEW_V1, this.utils.encryptString(applicationId), this.utils.encryptString(referenceId)]));
    }

    openGuarantorFormPage(applicantId: string): void {
        this.ngZone.run(()=>this.router.navigate([this.route.GUARANTOR_FORM, this.utils.encryptString(applicantId)]));
    }

    openLogoutPage() : void {
        this.ngZone.run(()=>this.router.navigate([this.route.LOGOUT]));
    }

    openInvalidAccess(): void {
        this.ngZone.run(()=>this.router.navigate([this.route.FORBIDDEN]));
    }

    openApplicationSearchPage() : void {
        this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_SEARCH]));
    }

    openApplicationHelpPage() : void {
        this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_HELP]));
    }

    openApplicationPreviewPageV1FromHome(applicationId: string, referenceId: string) : void {
        this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_VIEW_V1, this.utils.encryptString(applicationId), this.utils.encryptString(referenceId), this.utils.encryptString(this.constants.TRUE.toString())]));
    }

    openNinVerificationPage(applyingFor: string){
        this.ngZone.run(()=>this.router.navigate([this.route.NIN_VERIFICATION, this.utils.encryptString(applyingFor)]));
    }

    openApplicationViewV2(applicantId: string){
        this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_VIEW, this.utils.encryptString(applicantId)]));  
    }

    openPaymentHelp(){
        this.ngZone.run(()=>this.router.navigate([this.route.PAYMENT_HELP]));
    }

    openPassportVerificationPage(masterId: string, applyingFor: string){
        this.ngZone.run(()=>this.router.navigate([this.route.COD_REQUEST, this.utils.encryptString(masterId), this.utils.encryptString(applyingFor)]));
    }

    openCODDataComparisonPage(dataCorrectionId: string): void{
        this.ngZone.run(()=>this.router.navigate([this.route.COD_DATA_COMPARISON, this.utils.encryptString(dataCorrectionId)]));
    }

    redirectControl(redirectRouteName: string): void {
        if(this.utils.isEmpty(redirectRouteName) === true){
          this.openNewHomePage();
          return;
        }
    
        if(redirectRouteName === this.constants.FRESH || redirectRouteName === this.constants.RENEW || redirectRouteName === this.constants.REISSUE) {
          this.openNinVerificationPage(redirectRouteName);
          return;
        }
        
        this.openDashboardPage();
    }

}
