import { Component, OnInit } from '@angular/core';
import { Utils } from '../common/utils';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { Constants } from '../common/constants';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface FilePreviewInterface {
  applicableDocObject: {};
}
@Component({
  selector: 'app-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.css']
})
export class FilePreviewModalComponent extends SimpleModalComponent<FilePreviewInterface, null> implements FilePreviewInterface, OnInit {

  applicableDocObject: any = {};

  isPDFDoc: boolean = false;

  base64Data: string = this.constants.BLANK;

  constructor(private utils: Utils, private httpUtils: HttpUtils, private loaderService: LoaderService, private toast: Toast, public constants: Constants) { 
    super();
  }

  ngOnInit(): void {
    const uploadedDocBase64Data = this.applicableDocObject.base64Data;
    if(this.utils.isEmpty(uploadedDocBase64Data) === true){
      this.storeDocBase64Data();
    } else {
      this.isPDFDoc = this.applicableDocObject.isPDFDoc;
      this.base64Data = uploadedDocBase64Data;
    }

    if(this.utils.isEmpty(this.applicableDocObject.docSubTypeLabel) === false) {
      this.applicableDocObject.showDocSubTypeLabel = true;
    }
  }

  private storeDocBase64Data(): void {
    setTimeout(() => {this.loaderService.startLoader();}, 0);
    const applicantDocId = this.applicableDocObject.applicantDocId;
    this.httpUtils.fetchDocBase64Data(applicantDocId).then(serverResponse => {
      setTimeout(() => {this.loaderService.stopLoader(); }, 0);
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.isPDFDoc = serverResponse.details.isPDFDoc;
        this.base64Data = serverResponse.details.base64Data;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  cancel(): void {
    this.close();
  }

}
