import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { LocalStorage } from '../common/local-storage';
import { SearchModalComponent } from '../search-modal/search-modal.component';
import { environment } from 'src/environments/environment';
import { Utils } from '../common/utils';
import { Route } from '../common/route';
import { Router } from '@angular/router';

declare function clickDropdownClass(): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  loggedUserFullName : string = this.constants.BLANK;
  
  trackApplicationURL: string = environment.trackApplicationUrl;

  appointmentApplicationUrl: string = environment.appointmentApplicationUrl;

  isUserLoggedIn: boolean = false;

  isShowHomeLink: boolean = false;

  constructor(private router: Router, private location: LocationStrategy, public constants: Constants, private localStorage: LocalStorage, private gui: GUI, private simpleModalService: SimpleModalService, public utils: Utils, public route: Route) {
    history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    this.loggedUserFullName = this.localStorage.getLoggedUserFullName();
    this.isUserLoggedIn = this.utils.isEmpty(this.loggedUserFullName) === false;
    this.isShowHomeLink = this.router.url !== '/';
  }

  ngOnInit() : void {
    clickDropdownClass();
  }

  doLogout() {
    this.gui.openLogoutPage();
  }

  // openSearchModel(isGuarantorFormSearch: number){
  //   this.simpleModalService.addModal(SearchModalComponent, {isUnpaidSearch: this.constants.FALSE, isGuarantorFormSearch: isGuarantorFormSearch}, {autoFocus: true}).subscribe((searchResult: boolean)=> {
     
  //   });
  // }

}
