import { LocationStrategy } from '@angular/common';
import { Component } from '@angular/core';
import { GUI } from '../common/gui';

@Component({
  selector: 'app-invalid-access',
  templateUrl: './invalid-access.component.html',
  styleUrls: ['./invalid-access.component.css']
})
export class InvalidAccessComponent {

  constructor(private location: LocationStrategy, private gui: GUI) {
    history.pushState(null, null, window.location.href);  
    
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    
    setTimeout(()=>{
      this.gui.openLogoutPage();
    }, 5000);
  }

}
