import { Component, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { SelectService } from '../common/select-service';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { LoaderService } from '../common/loader-service';
import { AbstractControl, NgForm } from '@angular/forms';
import { Route } from '../common/route';
import { Pattern } from '../common/pattern';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { LocalStorage } from '../common/local-storage';
import { PhotoGuidelinesModalComponent } from '../photo-guidelines-modal/photo-guidelines-modal.component';
import { ImageUploadModalComponent } from '../image-upload-modal/image-upload-modal.component';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { GUI } from '../common/gui';
import { FilePreviewModalComponent } from '../file-preview-modal/file-preview-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-application-add-edit',
  templateUrl: './application-add-edit.component.html',
  styleUrls: ['./application-add-edit.component.css']
})
export class ApplicationAddEditComponent {

  @ViewChild('uploadPhotoForm') uploadPhotoForm : NgForm;

  @ViewChild('personalDetailsForm') personalDetailsForm : NgForm;

  @ViewChild('contactInformationForm') contactInformationForm : NgForm;

  @ViewChild('kinInformationForm') kinInformationForm : NgForm;

  @ViewChild('passportTypeForm') passportTypeForm : NgForm;

  @ViewChild('supportingDocumentsForm') supportingDocumentsForm : NgForm;

  @ViewChild('passportDeliveryForm') passportDeliveryForm : NgForm;


  private masterEncrypedApplicantId : string = this.constants.BLANK;

  private applicantId : string = this.constants.BLANK;

  private loggedUserId : string = this.constants.BLANK;

  applicationStage : number = this.constants.PASSPORT_STEP;

  applicationHighestStage : number = this.constants.PASSPORT_STEP;

  menuClickedStage : number = this.constants.ANY;

  passportStageSerialNumber : number = this.constants.FALSE;

  isDeliveryAvailable : number = this.constants.TRUE;


  passportTypeFormObject : any = {};

  feeDetailsObject : any = {};

  uploadPhotoFormObject : any = {};

  personalDetailsFormObject : any = {};

  contactInformationFormObject : any = {};

  kinInformationFormObject : any = {};

  supportingDocumentsFormObject : any = {};

  passportDeliveryFormObject : any = {};


  // applyingForSelectService = new SelectService();

  // passportSelectService = new SelectService();

  changeTypeSelectService = new SelectService();

  processingCountrySelectService = new SelectService();

  embassySelectService = new SelectService();

  validitySelectService = new SelectService();

  bookletSelectService = new SelectService();

  countrySelectService = new SelectService();

  stateSelectService = new SelectService();

  currentAddressStateSelectService = new SelectService();

  permanentLGASelectService = new SelectService();

  kinLGASelectService = new SelectService();


  private shallExitApplication : boolean;
  
  isEnhancedProcessingCenter : boolean = false;

  isShowFeesSection : boolean = false;

  isEnhancedEmbassy : boolean = false;

  imageValidated: boolean = false;


  personalMobileNumberOptions = this.utils.fetchIntTelOptionsObject(true);
  
  personalPhoneNumberOptions = this.utils.fetchIntTelOptionsObject(true);

  passportDeliveryMobileNumberOptions = this.utils.fetchIntTelOptionsObject(true);
  
  passportDeliveryPhoneNumberOptions = this.utils.fetchIntTelOptionsObject(true);
  
  kinMobileNumberOptions = this.utils.fetchIntTelOptionsObject(true);
  
  kinMobileNumberTelObj: any = {};



  private defaultSelectOption = {value: this.constants.BLANK, text: this.constants.DEFAULT_SELECT_OPTION_LABEL};

  applicationStepsList : any = [{count: this.constants.PASSPORT_STEP, id: 'passport-type', label: 'Passport Details'}, {count: this.constants.UPLOAD_PHOTO_STAGE, id: 'photo-upload', label: 'Passport Photograph'} , {count: this.constants.PERSONAL_INFO_STEP, id: 'personal', label: 'Personal Details'}, {count: this.constants.CONTACT_INFO_STEP, id: 'contact', label: 'Contact Information'}, {count: this.constants.NEXT_OF_KIN_INFO_STEP, id: 'kin', label: 'Next of Kin Information'}, {count: this.constants.DOCUMENTS_UPLOADED_STAGE, id: 'document-upload', label: 'Upload Supporting Documents'}];
  
  monthsList : any = [];

  titleList: any[] = [this.defaultSelectOption];

  applicableDocsList: any[] = [];
  

  currentAddressStateFieldType : string = this.constants.TEXT_FIELD;

  passportTypeFormCounter : number = -2;

  passportTypeLabel: string = this.constants.BLANK;

  ageGroup: string = this.constants.BLANK;

  allowImagesList: string = 'image/jpg,image/png,image/jpeg';

  changeTypeLabel: string = 'Change Type';

  centreAvailabilityUrl: string = environment.checkCentreAvailabilityUrl;

  enablePassportDeliveryStage: boolean = environment.enablePassportDeliveryStage;

  passportDeliveryTermsLink: string = environment.passportDeliveryTermsLink;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private ngZone: NgZone, private route: Route, private httpUtils: HttpUtils, private toast: Toast, 
              public utils: Utils, public constants: Constants, private loaderService: LoaderService, public pattern: Pattern, private simpleModalService: SimpleModalService, 
              private localStorage: LocalStorage, private gui: GUI){ 
    this.activatedRoute.params.subscribe((params: Params) => {
      this.masterEncrypedApplicantId = params['applicantId'];
    });
    if(this.enablePassportDeliveryStage === true) {
      this.applicationStepsList.push({count: this.constants.PASSPORT_DELIVERY_STAGE, id: 'passport-delivery', label: 'Passport Delivery Information'});
    }
    this.applicantId = this.utils.decryptString(this.masterEncrypedApplicantId);
    this.loggedUserId = this.localStorage.getLoggedUserId();
    this.storeProcessingCountriesList();
    this.storeAllCountriesList();
    this.storeStateList();
    this.utils.storeChangeTypes(this.changeTypeSelectService);
    this.monthsList = this.utils.getMonthsArray();
    this.storeApplicationData();
  }

  private setContactInformationFormDefaultValues(){
    this.setFieldsToBlank(this.contactInformationFormObject, ['currentCountry', 'currentState', 'permanentOriginState', 'permanentState', 'permanentLga'], [this.permanentLGASelectService], false);
  }

  private setKinInformationFormDefaultValues(){
    this.setFieldsToBlank(this.kinInformationFormObject, ['relationship', 'permanentCountry', 'permanentState', 'permanentLga'], [this.kinLGASelectService], false);
  }

  // private setPassportDeliveryFormDefaultValues(): void {
  //   this.passportDeliveryFormObject.deliveryOpted = this.constants.FALSE;
  // }



  private storeContactlessStatus(value): void {
    localStorage.setItem("contactlessStatus", value);
    this.passportTypeFormObject.contactlessStatus = value;

  }


  get isBiometricEnrollmentRequired(): boolean {
    return ['CA'].includes(this.passportTypeFormObject?.processingState);
  }


  private fetchCurrentForm(){
    if(this.passportTypeForm !== undefined){
      return this.passportTypeForm;
    }

    if(this.uploadPhotoForm !== undefined){
      return this.uploadPhotoForm;
    }

    if(this.personalDetailsForm !== undefined){
      return this.personalDetailsForm;
    }

    if(this.contactInformationForm !== undefined){
      return this.contactInformationForm;
    }

    if(this.kinInformationForm !== undefined){
      return this.kinInformationForm;
    }

    if(this.supportingDocumentsForm !== undefined){
      return this.supportingDocumentsForm;
    }

    if(this.passportDeliveryForm !== undefined){
      return this.passportDeliveryForm;
    }

    return null;
  }

  openFieldSet(stage: number, event: any){
    window.scrollTo(0,0);
    const currentForm = this.fetchCurrentForm();
    if(currentForm === null){
      return;
    }

    currentForm.onSubmit(event);
    if(currentForm.valid === true){
      const hasFieldValueChangedControl = currentForm.form.controls['hasFieldValueChanged'];
      if(hasFieldValueChangedControl !== undefined && hasFieldValueChangedControl.value === this.constants.TRUE){
        const callableFunctionName =  currentForm.form.value.callableFunctionName;
        this.showDataChangeInformationModal(event, hasFieldValueChangedControl, callableFunctionName, stage);
      } else {
        this.applicationStage = stage;
      }
    }
  }

  private showDataChangeInformationModal(event: any, hasFieldValueChangedControl: AbstractControl, callableFunctionName: string, stage: number){
    const message = 'It seems you have changed some content on this page. <br> Click <b>OK</b> to save the information first.';
    const modalParamsObject = {title: 'Confirmation', message: message, confirmButtonLabel: 'OK', cancelButtonLabel: 'Cancel', isShowCancelButton: true};
    this.simpleModalService.addModal(ConfirmationModalComponent, modalParamsObject, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
      if(isConfirmed === true){
        hasFieldValueChangedControl.setValue(this.constants.FALSE);
        event.target.name = 'confirmation';
        this.menuClickedStage = stage;
        this[callableFunctionName](event);
      }
    });
  }

  openPreviousFieldSet(){
    this.applicationStage--;
  }

  setShallExitApplication(shallExitApplication: boolean){
    this.shallExitApplication = shallExitApplication;
  }

  openDashboardPage(){
    this.ngZone.run(()=>this.router.navigate([this.route.DASHBOARD]));
  }

  openValidateEmailPage(){
    this.ngZone.run(()=>this.router.navigate([this.route.VALIDATE_EMAIL]));
  }

  setGender(){
    if(this.personalDetailsFormObject.title === this.constants.BLANK){
      this.personalDetailsFormObject.gender = this.constants.BLANK;
      return;
    }

    if(this.personalDetailsFormObject.title === this.constants.MR){
      this.personalDetailsFormObject.gender = this.constants.MALE;
      return;
    }

    this.personalDetailsFormObject.gender = this.constants.FEMALE;
  }

  setCountryCode(countryCode: string) {
    this.kinMobileNumberTelObj.setCountry(countryCode);
  }

  private isValidDetails(formObject: any = {}, checkedFieldsArray: any = []): boolean {
    let fieldBlankCount = 0;

    for(let i=0; i<checkedFieldsArray.length; i++){
      if(this.utils.isEmpty(formObject[checkedFieldsArray[i]]) === true){
        fieldBlankCount++;
      }
    }

    return fieldBlankCount > 0 ? false : true;
  }

  setFieldsToBlank(formObject: {}, fieldsArray : any[], selectServiceArray : SelectService[], isFeeObjectEffected: boolean){
    for(let i=0; i<fieldsArray.length; i++){
      formObject[fieldsArray[i]] = this.constants.BLANK;
    }

    if(selectServiceArray !== null){
      for(let selectService of selectServiceArray){
        selectService.setSelectOptions([this.defaultSelectOption]);
      }
    }

    if(isFeeObjectEffected === true){
      this.isShowFeesSection = false;
      this.feeDetailsObject = {};
    }
  }

  private setDateOfBirth(){
    const dateOfBirthArray = this.personalDetailsFormObject.dateOfBirth.split('/');
    this.personalDetailsFormObject.dateOfBirthDay = dateOfBirthArray[0];
    this.personalDetailsFormObject.dateOfBirthMonth = dateOfBirthArray[1];
    this.personalDetailsFormObject.dateOfBirthYear = dateOfBirthArray[2];
  }

  setCurrentAddressStateFieldType(isChangedFromGUI: boolean){
    if(isChangedFromGUI === true){
      this.setFieldsToBlank(this.contactInformationFormObject, ['currentState'], null, false);
    }

    if(this.contactInformationFormObject.currentCountry === this.constants.USA_COUNTRY_CODE){
      this.currentAddressStateFieldType = this.constants.SELECT_FIELD;
      return;
    }

    this.currentAddressStateFieldType = this.constants.TEXT_FIELD;
  }

  private storeProcessingCountriesList(){
    this.httpUtils.fetchProcessingCountriesList().then(serverResponse => {
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const serverCountryList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.processingCountries){
          serverCountryList.push({value: valueJSONObject.isoCode, text: valueJSONObject.fullName});
        }
        this.processingCountrySelectService.setSelectOptions(serverCountryList);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }
 
  private storeAllCountriesList(): void {
    this.httpUtils.fetchCountriesList().then(serverResponse => {
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const serverCountryList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.allCountries){
          serverCountryList.push({value: valueJSONObject.isoCode, text: valueJSONObject.fullName});
        }
        this.countrySelectService.setSelectOptions(serverCountryList);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private storeStateList(){
    this.httpUtils.fetchCountryStateList().then(serverResponse => {
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const serverNigeriaStateList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.statesNigeria){
          serverNigeriaStateList.push({value: valueJSONObject.id, text: valueJSONObject.stateName});
        }
        this.stateSelectService.setSelectOptions(serverNigeriaStateList);

        const serverUSAStateList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.statesUSA){
          serverUSAStateList.push({value: valueJSONObject.id, text: valueJSONObject.stateName});
        }
        this.currentAddressStateSelectService.setSelectOptions(serverUSAStateList);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  storeCountryEmbassyList(isChangedFromGUI: boolean){
    if(isChangedFromGUI === true){
      this.loaderService.startLoader();
      this.setFieldsToBlank(this.passportTypeFormObject, ['processingEmbassy', 'bookletType', 'validity'], [this.embassySelectService, this.bookletSelectService, this.validitySelectService], true);
    }
    
    if(this.isValidDetails(this.passportTypeFormObject, ['processingCountry']) === false){
      this.embassySelectService.setSelectOptions([this.defaultSelectOption]);
      if(isChangedFromGUI === true){
        this.loaderService.stopLoader();
      }
      return;
    }

    this.httpUtils.fetchCountryEmbassyList(this.passportTypeFormObject.processingCountry).then(serverResponse => {
      if(isChangedFromGUI === true){
        this.loaderService.stopLoader();
      }
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const serverCountryEmbassyList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.countryEmbassyList){
          serverCountryEmbassyList.push({value: valueJSONObject.id, text: valueJSONObject.name});
        }
        this.embassySelectService.setSelectOptions(serverCountryEmbassyList);

        if(isChangedFromGUI === true) {
          this.isDeliveryAvailable = serverResponse.isDeliveryAvailable;
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  storeEmbassyBookletList(isChangedFromGUI: boolean){
    if(isChangedFromGUI === true){
      this.loaderService.startLoader();
      this.setFieldsToBlank(this.passportTypeFormObject, ['bookletType', 'validity'], [this.bookletSelectService, this.validitySelectService], true);
    }

    if(this.isValidDetails(this.passportTypeFormObject, ['processingCountry', 'processingEmbassy']) === false){
      this.bookletSelectService.setSelectOptions([this.defaultSelectOption]);
      if(isChangedFromGUI === true){
        this.loaderService.stopLoader();
      }
      return;
    }

    this.passportTypeFormObject.applicantId = this.applicantId;
    this.passportTypeFormObject.loggedUserId = this.loggedUserId;
    this.httpUtils.fetchEmbassyBookletList(this.passportTypeFormObject).then(serverResponse => {
      if(isChangedFromGUI === true){
        this.loaderService.stopLoader();
      }
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.isEnhancedEmbassy = serverResponse.isEnhancedCenter === this.constants.TRUE;
        const serverBookletTypeList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.bookletList){
          serverBookletTypeList.push({value: valueJSONObject.key, text: valueJSONObject.value});
        }
        this.bookletSelectService.setSelectOptions(serverBookletTypeList);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  storeEmbassyBookletValidityList(isChangedFromGUI: boolean){
    if(isChangedFromGUI === true){
      this.loaderService.startLoader();
      this.setFieldsToBlank(this.passportTypeFormObject, ['validity'], [this.validitySelectService], true);
    }

    if(this.isValidDetails(this.passportTypeFormObject, ['processingCountry', 'processingEmbassy', 'bookletType']) === false){
      this.validitySelectService.setSelectOptions([this.defaultSelectOption]);
      if(isChangedFromGUI === true){
        this.loaderService.stopLoader();
      }
      return;
    }

    const processingEmbassy = this.passportTypeFormObject.processingEmbassy;
    const passportType = this.passportTypeFormObject.passportType;
    const subType = this.passportTypeFormObject.changeType;
    const bookletType = this.passportTypeFormObject.bookletType;
    this.httpUtils.fetchEmbassyBookletValidityList(processingEmbassy, passportType, subType, bookletType, this.applicantId).then(serverResponse => {
      if(isChangedFromGUI === true){
        this.loaderService.stopLoader();
      }
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const serverValidityList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.validityList){
          serverValidityList.push({value: valueJSONObject.key, text: valueJSONObject.value});
        }

        if(serverResponse.validityList.length === this.constants.TRUE) {
          this.passportTypeFormObject.validity = serverValidityList[1].value;
          this.fetchPassportFeeDetails();
        }


        this.validitySelectService.setSelectOptions(serverValidityList);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  fetchPassportFeeDetails(): void{
    if(this.isValidDetails(this.passportTypeFormObject, ['passportType', 'processingEmbassy', 'bookletType', 'validity']) === false){
      this.isShowFeesSection = false;
      this.feeDetailsObject = {};
      return;
    }

    this.loaderService.startLoader();
    this.passportTypeFormObject.applicantId = this.applicantId;
    this.passportTypeFormObject.loggedUserId = this.loggedUserId;
    this.passportTypeFormObject.deliveryOpted = this.passportDeliveryFormObject.deliveryOpted;
    this.httpUtils.fetchPassportFeeDetails(this.passportTypeFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.feeDetailsObject = serverResponse;
        this.isEnhancedProcessingCenter = this.feeDetailsObject.isEnhancedCenter === this.constants.TRUE;
        this.isShowFeesSection = true;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
        this.isShowFeesSection = false;
        this.feeDetailsObject = {};
      }
    });
  }

  fetchStateLGA(formObject: {}, fieldName: string, selectService: SelectService, childFieldName: string, isChangedFromGUI: boolean){
    if(isChangedFromGUI === true){
      this.loaderService.startLoader();
      formObject[childFieldName] = this.constants.BLANK;
    }
    
    const stateId = formObject[fieldName];
    
    if(this.utils.isEmpty(stateId) === true){
      formObject[childFieldName] = this.constants.BLANK;
      if(isChangedFromGUI === true){
        this.loaderService.stopLoader();
      }
      return;
    }

    this.httpUtils.fetchStateLGAList(stateId).then(serverResponse => {
      if(isChangedFromGUI === true){
        this.loaderService.stopLoader();
      }
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const serverStateLGAList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.lga){
          serverStateLGAList.push({value: valueJSONObject.id, text: valueJSONObject.description});
        }
        selectService.setSelectOptions(serverStateLGAList);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private setApplicationLatestStage(applicationStage: number){
    this.applicationStage = applicationStage;
    this.applicationHighestStage = applicationStage;
  }

  private markFieldValueUnchanged(event: any, formObject: {}){
    event.target.name = this.constants.BLANK;
    formObject['hasFieldValueChanged'] = this.constants.FALSE;
  }

  openImageUploadForm(event: any): void {
    if(event.target.name === undefined || event.target.name === this.constants.BLANK){
      return;
    }

    window.scrollTo(0,0);

    this.loaderService.startLoader();
    this.passportTypeFormObject.applicantId = this.applicantId;
    this.passportTypeFormObject.loggedUserId = this.loggedUserId;
    this.httpUtils.saveV2ProcessingInfoData(this.passportTypeFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.passportTypeFormObject.processingInfoId = serverResponse.processingInfoId;
        this.markFieldValueUnchanged(event, this.passportTypeFormObject);
       
        if(this.shallExitApplication === true){
          this.openDashboardPage();
          return;
        }

        this.setDeliveryPhoneInitialCountry();

        if(this.isDeliveryAvailable === this.constants.FALSE) {
          this.passportDeliveryFormObject.deliveryOpted = this.constants.FALSE;
        }else if(this.passportDeliveryFormObject.deliveryOpted === this.constants.FALSE) {
          this.passportDeliveryFormObject.deliveryOpted = this.constants.BLANK;
        }
        
        if(this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }

        this.setApplicationLatestStage(this.constants.UPLOAD_PHOTO_STAGE);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  setDeliveryPhoneInitialCountry(): void {
    this.passportDeliveryMobileNumberOptions.initialCountry = this.passportTypeFormObject.processingCountry;
    this.passportDeliveryPhoneNumberOptions.initialCountry = this.passportTypeFormObject.processingCountry;
  }

  openPersonalDetailsForm(event: any): void{
    if(this.imageValidated === false){
      return;
    }

    if(event.target.name === undefined || event.target.name === this.constants.BLANK){
      return;
    }

    window.scrollTo(0,0);

    if(this.shallExitApplication === true){
      this.openDashboardPage();
      return;
    }

    if(this.menuClickedStage > this.constants.ANY) {
      this.applicationStage = this.menuClickedStage;
      return;
    }

    this.loaderService.startLoader();
    this.setApplicationLatestStage(this.constants.PERSONAL_INFO_STEP);
    this.loaderService.stopLoader();
  }

  openContactInformationForm(event: any){
    if(this.utils.isEmpty(event.target.name) === true){
      return;
    }

    window.scrollTo(0,0);

    this.loaderService.startLoader();
    this.personalDetailsFormObject.dateOfBirth = this.personalDetailsFormObject.dateOfBirthDay + '/' + this.personalDetailsFormObject.dateOfBirthMonth + '/' + this.personalDetailsFormObject.dateOfBirthYear;
    this.personalDetailsFormObject.loggedUserId = this.loggedUserId;
    this.httpUtils.savePersonalDetailsDataV2(this.personalDetailsFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.personalDetailsFormObject.id = serverResponse.applicantId;
        this.personalDetailsFormObject.applicationId = serverResponse.applicationId;
        this.markFieldValueUnchanged(event, this.personalDetailsFormObject);

        if(this.shallExitApplication === true){
          this.loaderService.stopLoader();
          this.openDashboardPage();
          return;
        }

        if(this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }
        
        this.personalMobileNumberOptions.initialCountry = this.passportTypeFormObject.processingCountry;
        this.personalPhoneNumberOptions.initialCountry = this.passportTypeFormObject.processingCountry;
        this.setApplicationLatestStage(this.constants.CONTACT_INFO_STEP);
      } else {
        this.loaderService.stopLoader();
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }
  
  openKinInformationForm(event: any){
    if(this.utils.isEmpty(event.target.name) === true){
      return;
    }

    window.scrollTo(0,0);

    this.loaderService.startLoader();
    this.contactInformationFormObject.applicantId = this.applicantId;
    this.contactInformationFormObject.loggedUserId = this.loggedUserId;
    this.httpUtils.saveContactInformationData(this.contactInformationFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.contactInformationFormObject.contactDetailsId = serverResponse.contactDetailsId;
        this.contactInformationFormObject.applicantId = serverResponse.applicantId;
        this.personalMobileNumberOptions.initialCountry = this.contactInformationFormObject.mobileNumberCountryISOCode;
        this.personalPhoneNumberOptions.initialCountry = this.contactInformationFormObject.phoneNumberCountryISOCode;
        this.markFieldValueUnchanged(event, this.contactInformationFormObject);
        
        if(this.shallExitApplication === true){
          this.openDashboardPage();
          return;
        }

        if(this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }

        this.setApplicationLatestStage(this.constants.NEXT_OF_KIN_INFO_STEP);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openSupportingDocumentsForm(event: any) {
    if(event.target.name === undefined || event.target.name === this.constants.BLANK){
      return;
    }

    window.scrollTo(0,0);

    this.loaderService.startLoader();
    this.kinInformationFormObject.applicantId = this.applicantId;
    this.kinInformationFormObject.loggedUserId = this.loggedUserId;
    this.httpUtils.saveKinInformationData(this.kinInformationFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.kinInformationFormObject.contactDetailsId = serverResponse.contactDetailsId;
        this.kinMobileNumberOptions.initialCountry = this.kinInformationFormObject.mobileNumberCountryISOCode;
        this.markFieldValueUnchanged(event, this.kinInformationFormObject);

        if(this.shallExitApplication === true){
          this.openDashboardPage();
          return;
        }

        if(this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }
        
        this.storeApplicableDocs().then(result => {
          if(result === true) {
            this.setApplicationLatestStage(this.constants.DOCUMENTS_UPLOADED_STAGE);
          }
        });
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private async storeApplicableDocs(): Promise<boolean> {
    let result : boolean = false;
    await this.httpUtils.fetchApplicableDocs(this.applicantId, this.loggedUserId).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.applicableDocsList = serverResponse.applicableDocsList;
        result = true;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
    return result;
  }

  openPassportDeliveryForm(event: any): void {
    if(event.target.name === undefined || event.target.name === this.constants.BLANK){
      return;
    }

    window.scrollTo(0,0);

    this.loaderService.startLoader();
    this.supportingDocumentsFormObject.applicantId = this.applicantId;
    this.supportingDocumentsFormObject.loggedUserId = this.loggedUserId;
    this.supportingDocumentsFormObject.documents = this.fetchUploadedDocs();
    this.httpUtils.saveApplicableDocs(this.supportingDocumentsFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.markFieldValueUnchanged(event, this.supportingDocumentsFormObject);

        if(this.shallExitApplication === true){
          this.openDashboardPage();
          return;
        }

        if(this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }
        
        if(this.enablePassportDeliveryStage === true){
          this.setApplicationLatestStage(this.constants.PASSPORT_DELIVERY_STAGE);
          return;
        }

        this.gui.openApplicationViewV2(this.applicantId);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openApplicationPreview(event: any): void{
    if(event.target.name === undefined || event.target.name === this.constants.BLANK){
      return;
    }

    window.scrollTo(0,0);
    this.loaderService.startLoader();
    this.passportDeliveryFormObject.applicantId = this.applicantId;
    this.passportDeliveryFormObject.loggedUserId = this.loggedUserId;
    this.httpUtils.savePassportDeliveryDetails(this.passportDeliveryFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.passportDeliveryFormObject.deliveryInfoId = serverResponse.deliveryInfoId;
        this.markFieldValueUnchanged(event, this.passportDeliveryFormObject);

        if(this.shallExitApplication === true){
          this.openDashboardPage();
          return;
        }

        this.fetchPassportFeeDetails();

        if(this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }
        
        this.gui.openApplicationViewV2(this.applicantId);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private fetchUploadedDocs(): any[] {
    const documents = [];
    for(let sectionObject of this.applicableDocsList) {
      for(let applicableDocObject of sectionObject.groupDocs) {
        documents.push({applicantDocId: applicableDocObject.applicantDocId, docSubType: applicableDocObject.docSubType, base64Data: applicableDocObject.base64Data});
      } 
    }
    return documents;
  }

  private storeApplicationData(){
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationData(this.applicantId, this.loggedUserId).then(serverResponse => {
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.setupPersonalDetailsFormObject(serverResponse);
        
        this.setupPassportTypeFormObject(serverResponse);
        
        this.setupFeeDetailsObject(serverResponse);

        this.setupUploadPhotoFormObject(serverResponse);

        this.setupContactInformationFormObject(serverResponse);
        
        this.setupKinInformationFormObject(serverResponse);

        this.setupPassportDeliveryFormObject(serverResponse);

        this.setCallableFunction();

        this.setApplicationLatestStage(serverResponse.applicationStage);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private setupPersonalDetailsFormObject(serverResponse: any = {}): void {
    this.personalDetailsFormObject = serverResponse.personalInfo;
    this.ageGroup = this.personalDetailsFormObject.ageGroup;
    for(let titleObject of this.personalDetailsFormObject.titleList) {
      this.titleList.push({value: titleObject.key, text: titleObject.value});
    }
    this.setDateOfBirth();
    this.storeApplicableDocs();
  }

  private setupPassportTypeFormObject(serverResponse: any = {}) : void {
    this.passportTypeFormObject = serverResponse.passportDetails;
    this.isDeliveryAvailable = this.passportTypeFormObject.isDeliveryAvailable;
    this.setAppointmentAvailabilityURL(this.passportTypeFormObject.processingEmbassyLabel);
    this.changeTypeLabel = this.passportTypeFormObject.passportType === this.constants.STANDARD_PASSPORT_COD ? 'Current Passport Status' : 'Reason for Renewal';
    this.passportStageSerialNumber = this.passportTypeFormObject.applyingFor === this.constants.FRESH ? 0 : 2;
    this.passportTypeLabel = this.utils.fetchPassportTypeLabel(this.passportTypeFormObject.passportType);
    this.storeCountryEmbassyList(false);
    this.storeEmbassyBookletList(false);
    this.storeEmbassyBookletValidityList(false);
  }
  
  private setupFeeDetailsObject(serverResponse: any = {}) : void {
    this.feeDetailsObject = serverResponse.feeDetails;
    if(this.isValidDetails(this.passportTypeFormObject, ['passportType', 'processingEmbassy', 'bookletType', 'validity']) === true){
      this.isShowFeesSection = true;
    }
  }

  private setupUploadPhotoFormObject(serverResponse: any) : void {
    if(serverResponse.uploadPhotoDetails !== null) {
      this.uploadPhotoFormObject = serverResponse.uploadPhotoDetails;
      this.imageValidated = this.utils.isEmpty(this.uploadPhotoFormObject.validatedBase64Data) === false;
    }
  }

  private setupContactInformationFormObject(serverResponse: any = {}) : void {
    if(serverResponse.contactInfo !== null){
      this.contactInformationFormObject = serverResponse.contactInfo;
      
      if(this.utils.isEmpty(this.contactInformationFormObject.mobileNumberCountryISOCode) === false){
        this.personalMobileNumberOptions.initialCountry = this.contactInformationFormObject.mobileNumberCountryISOCode;
      }

      if(this.utils.isEmpty(this.contactInformationFormObject.phoneNumberCountryISOCode) === false){
        this.personalPhoneNumberOptions.initialCountry = this.contactInformationFormObject.phoneNumberCountryISOCode;
      }

      this.fetchStateLGA(this.contactInformationFormObject, 'permanentState', this.permanentLGASelectService, 'permanentLga', false);
      this.setCurrentAddressStateFieldType(false);
    } else {
      this.setContactInformationFormDefaultValues();
    }
  }

  private setupKinInformationFormObject(serverResponse: any = {}) : void {
    if(serverResponse.kinInformation !== null){
      this.kinInformationFormObject = serverResponse.kinInformation;
      if(this.utils.isEmpty(this.kinInformationFormObject.mobileNumberCountryISOCode) === false){
        this.kinMobileNumberOptions.initialCountry = this.kinInformationFormObject.mobileNumberCountryISOCode;
      }
    } else {
      this.setKinInformationFormDefaultValues();
    }
  }

  private setupPassportDeliveryFormObject(serverResponse: any) : void {
    this.passportDeliveryFormObject = serverResponse.deliveryInfo;
    this.passportDeliveryFormObject.receiverAddressCountry = this.passportTypeFormObject.processingCountryLabel;

    if(this.passportDeliveryFormObject.deliveryOpted === this.constants.ANY){
      // this.passportDeliveryFormObject.deliveryOpted = this.constants.BLANK;
      this.passportDeliveryFormObject.deliveryOpted = this.isDeliveryAvailable === this.constants.TRUE ? this.constants.BLANK : this.constants.FALSE;
    }

  }

  private setCallableFunction() : void{
    this.passportTypeFormObject.callableFunctionName = 'openImageUploadForm';

    this.uploadPhotoFormObject.callableFunctionName = 'openPersonalDetailsForm';

    this.personalDetailsFormObject.callableFunctionName = 'openContactInformationForm';

    this.contactInformationFormObject.callableFunctionName = 'openKinInformationForm';

    this.kinInformationFormObject.callableFunctionName = 'openSupportingDocumentsForm';
    
    this.supportingDocumentsFormObject.callableFunctionName = 'openPassportDeliveryForm';

    this.passportDeliveryFormObject.callableFunctionName = 'openApplicationPreview';
  }

  openImageUploadModal(formObject: {}, fileDataField: string, fileTypeField: string) : void {
    const requestObject = {};
    requestObject['isFixedCropper'] = this.constants.TRUE;
    requestObject['allowFileTypeList'] = this.allowImagesList;
    requestObject['applicantId'] = this.applicantId;
    requestObject['loggedUserId'] = this.loggedUserId;
    this.simpleModalService.addModal(ImageUploadModalComponent, {requestObject: requestObject}, {autoFocus: true}).subscribe((responseObject: any = {})=> {
      if(Object.keys(responseObject).length > 0){
        this.toast.showSuccessToastOld('Picture Uploaded Successfully');
        formObject[fileDataField] = responseObject.fileBase64Data;
        formObject[fileTypeField] = responseObject.fileType;
        this.imageValidated = this.utils.isEmpty(responseObject.fileBase64Data) === false;
      }
    });
  }

  openPhotoGuidelineModal(): void {
    this.simpleModalService.addModal(PhotoGuidelinesModalComponent, null, {autoFocus: true});
  }

  openFileUploadModal(applicableDocObject: any = {}) : void {
    this.simpleModalService.addModal(FileUploadModalComponent, {applicableDocObject}, {autoFocus: true}).subscribe((responseObject: any = {})=> {
      if(Object.keys(responseObject).length > 0 && this.utils.isEmpty(responseObject.base64Data) === false){
        applicableDocObject.docSubType = responseObject.docSubType;
        applicableDocObject.docSubTypeLabel = responseObject.docSubTypeLabel;
        applicableDocObject.base64Data = responseObject.base64Data;
        applicableDocObject.isPDFDoc = responseObject.isPDFDoc;
        applicableDocObject.isDocUploaded = true;
      }
    });
  }

  openFilePreviewModal(applicableDocObject: any = {}) : void {
    this.simpleModalService.addModal(FilePreviewModalComponent, {applicableDocObject}, {autoFocus: true, closeOnEscape: true}).subscribe();
  }

  deleteUploadedFile(applicableDocObject: any = {}): void {
    this.loaderService.startLoader();
    applicableDocObject.base64Data = this.constants.BLANK;
    applicableDocObject.isPDFDoc = false;
    applicableDocObject.isDocUploaded = false;
    this.loaderService.stopLoader();
  }

  setAppointmentAvailabilityURL(processingEmbassy: string) : void {
    this.centreAvailabilityUrl = environment.checkCentreAvailabilityUrl + 'centers?center=' + processingEmbassy;
  }

}
