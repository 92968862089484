import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Route } from '../common/route';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.css']
})
export class PaymentFailureComponent {

  private encryptedApplicantId : string = this.constants.BLANK;
  
  private encryptedTxnRefNumber : string = this.constants.BLANK;

  serverDecryptedApplicantId : string = this.constants.BLANK;

  failedReason : string = this.constants.BLANK;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private ngZone: NgZone, private httpUtils: HttpUtils, private toast: Toast, public constants: Constants,
              private utils: Utils, private loaderService: LoaderService, private route: Route) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplicantId = params["applicantId"];
      this.encryptedTxnRefNumber = params["txnRefNumber"];
    });
    this.checkPaymentStatus();
  }

  private checkPaymentStatus(){
    this.loaderService.startLoader();
    this.serverDecryptedApplicantId = this.utils.decryptServerEncryptedString(this.encryptedApplicantId);
    const decryptedTxnRefNumber = this.utils.decryptServerEncryptedString(this.encryptedTxnRefNumber);
    this.httpUtils.checkPaymentStatus(decryptedTxnRefNumber).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS || serverResponse.responseCode === this.constants.PAYMENT_ALREADY_COMPLETE){
        this.openPaymentSuccessPage();
      } else {
        this.failedReason = serverResponse.responseMsg;
      }
    });
  }

  openHomePage(){
    this.ngZone.run(()=>this.router.navigate([this.route.HOME]));
  }

  retryPayment(){
    this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_VIEW, this.utils.encryptString(this.serverDecryptedApplicantId)]));
  }

  private openPaymentSuccessPage() {
    this.ngZone.run(()=>this.router.navigate([this.route.PAYMENT_SUCCESS, this.encryptedApplicantId, this.encryptedTxnRefNumber]));
  }

}