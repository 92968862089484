import { Directive, HostListener, Output, EventEmitter } from '@angular/core';
import { Constants } from '../common/constants';
import { Utils } from '../common/utils';

@Directive({
    selector: '[number-only]'
})
export class NumberOnlyDirective {

    @Output() ngModelChange = new EventEmitter();

    constructor(private constants: Constants, private utils: Utils) { }

    @HostListener('keydown', ['$event'])
    public onKeydownHandler(keyboardEvent: KeyboardEvent): boolean {
        const keyValue = keyboardEvent.charCode? keyboardEvent.charCode : keyboardEvent.keyCode;
        const specialKeyList = [8, 37, 39, 9, 118, 224, 86, 35, 46];

        if (specialKeyList.includes(keyValue) === true){
			return true;
		}
        if (keyValue >= 48 && keyValue <= 57) {
            return true;
        }
        if (keyValue >= 96 && keyValue <= 105) {
            return true;
        }
        return false;
    }

    @HostListener('blur', ['$event'])
    public onBlurHandler(event: any): void {
        let fieldValue = event.target.value;
        fieldValue = this.utils.isEmpty(fieldValue) === false ? fieldValue.trim() : fieldValue;
        if(new RegExp(/^([0-9]+s?)*$/).test(fieldValue) === false){
            event.target.value = this.constants.BLANK;
            this.ngModelChange.emit(this.constants.BLANK);
        }
    }

}