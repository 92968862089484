import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '../common/constants';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input('fixedFooterValue') private fixedFooterValue: string;

  isFixedFooter : boolean = false;

  latestYear : number;

  constructor(private utils: Utils, private constants: Constants) {
    this.latestYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    if(this.utils.isEmpty(this.fixedFooterValue) === false){
      this.isFixedFooter = Number(this.fixedFooterValue) === this.constants.TRUE;
    }
  }

}