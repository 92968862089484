import { Component } from '@angular/core';
import { GUI } from '../common/gui';

@Component({
  selector: 'app-payment-failure-v1',
  templateUrl: './payment-failure-v1.component.html',
  styleUrls: ['./payment-failure-v1.component.css']
})
export class PaymentFailureV1Component {

  constructor(private gui: GUI) { 

  }

  openDashboard(){
    this.gui.openDashboardPage();
  }

}
