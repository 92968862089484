import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { LocalStorage } from '../common/local-storage';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent {

  constructor(private localStorage: LocalStorage, private keycloakService: KeycloakService) {
    Cookie.deleteAll(environment.path, environment.domain);
    this.localStorage.removeAllStorageData();
    this.keycloakService.logout(environment.keycloakLogoutRedirectUrl);
  }

}
