import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[disallowSpaces]'
})
export class DisallowSpacesDirective {
  
    @Output() ngModelChange = new EventEmitter();

    constructor() { }

    @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
        e.preventDefault();
    }

    @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
        e.preventDefault();
    }

    @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
        e.preventDefault();
    }

    @HostListener('keydown', ['$event'])
    public onKeydownHandler(key: KeyboardEvent): void {
        const keyValue = key.charCode? key.charCode : key.keyCode;
        if(keyValue === 32) {
            key.preventDefault();
        }
    }

    @HostListener('input', ['$event'])
    public onInputHandler(event: any): void {
        let fieldValue = event.target.value;
        fieldValue = fieldValue.replace(/ /g, '');
        this.ngModelChange.emit(fieldValue);
    }

}