import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Utils } from '../common/utils';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { SimpleModalService } from 'ngx-simple-modal';
import { GUI } from '../common/gui';
import { InformationModalComponent } from '../information-modal/information-modal.component';
import { LocalStorage } from '../common/local-storage';

@Component({
  selector: 'app-coddata-comparison',
  templateUrl: './coddata-comparison.component.html',
  styleUrls: ['./coddata-comparison.component.css']
})
export class CODDataComparisonComponent {

  private encryptedDataCorrectionId: string = this.constants.BLANK;

  dataCorrectionId: string = this.constants.BLANK;

  loggedUserId: string = this.constants.BLANK;

  private defaultSelectOption = {key: this.constants.BLANK, value: this.constants.DEFAULT_SELECT_OPTION_LABEL};

  codReasonList: any = [];

  codComparisonItemsList: any = [];

  codComparisonFormObject: any = {};

  isTermConditionSelect: boolean = false;

  disableReason: boolean = false;

  showFeesNote: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private utils: Utils, public constants: Constants, private httpUtils: HttpUtils, private loaderService: LoaderService, private toast: Toast, private gui: GUI, 
              private simpleModalService: SimpleModalService, private localStorage: LocalStorage) { 
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encryptedDataCorrectionId = params['dataCorrectionId'];
    });
    this.dataCorrectionId = this.utils.decryptString(this.encryptedDataCorrectionId);
    this.loggedUserId = this.localStorage.getLoggedUserId();
    this.codComparisonDefaultValues();
    this.storeCODComparisonData();
  }

  private storeCODComparisonData(): void {
    this.loaderService.startLoader();
    this.httpUtils.fetchCODComparisonData(this.dataCorrectionId).then(serverResponse =>{
      this.loaderService.stopLoader();
      if(serverResponse.responseCode == this.constants.STATUS_SUCCESS) {
        this.allocateSelectionOptions(serverResponse.details.reasons, this.codReasonList);
        this.codComparisonItemsList = serverResponse.details.comparisonItems;
        this.codComparisonFormObject.currencyCode = serverResponse.details.currencyCode; 
        this.codComparisonFormObject.currencySymbol = serverResponse.details.currencySymbol; 
        this.codComparisonFormObject.paymentAmount = serverResponse.details.paymentAmount; 
        this.codComparisonFormObject.reason = serverResponse.details.defaultReason; 
        this.disableReason = serverResponse.details.disableReason; 
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private codComparisonDefaultValues(): void {
    this.codReasonList.push(this.defaultSelectOption);
    this.codComparisonFormObject.reason = this.constants.BLANK;
    this.codComparisonFormObject.dataCorrectionId = this.dataCorrectionId;
  }

  private allocateSelectionOptions(objectArray: any, targetArray: any): void{
    for(let object of objectArray ){
      targetArray.push(object);
    }
  }
  
  private startCODApplication(): void {
    this.loaderService.startLoader();
    this.codComparisonFormObject.loggedUserId = this.loggedUserId;
    this.httpUtils.saveCODComparisonData(this.codComparisonFormObject).then(serverResponse =>{
      this.loaderService.stopLoader();
      if(serverResponse.responseCode == this.constants.STATUS_SUCCESS) {
        this.gui.openApplicationAddPage(serverResponse.applicantId);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openInformationModal(): void {
    const message = 'Your NIN data will be used to print your passport. Please confirm you have verified and agreed to proceed.';
    const checkBoxLabel = 'I hereby confirm to use this information for processing the passport application.'
    this.simpleModalService.addModal(InformationModalComponent, {message: message, isShowCheckBox: true, checkBoxLabel: checkBoxLabel}, {autoFocus: true, closeOnEscape: true}).subscribe((isConfirmed: boolean)=> {
      if(isConfirmed === true){
        this.startCODApplication();
      }
    });
  }

}
