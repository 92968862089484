import { Injectable } from '@angular/core';
import { Constants } from './constants';
import { LocalStorage } from './local-storage';
import { Tags } from './tags';
import { Utils } from './utils';

@Injectable({ 
    providedIn: 'root'
})
export class UserType {

    private APPLICANT = 'applicant';
    
    // private DIPLOMAT_1 = 'diplomat_1';

    private DIPLOMAT_1 = 'foreign_mission';

    private VALID_USER_TYPES = [this.APPLICANT, this.DIPLOMAT_1];
    
    constructor(private tags: Tags, private localStorage: LocalStorage, private utils: Utils, private constants: Constants){
    
    }

    isValidUser(userTypeArray: any = []){
        for(let requestUserType of userTypeArray){
            if(this.VALID_USER_TYPES.includes(requestUserType) === true){
                return true;
            }
        }
        return false;
    }
    
    private fetchUserTypeArray(): any {
        const loggedUserTypeJSONObject = JSON.parse(this.localStorage.getLoggedUserType());

        return loggedUserTypeJSONObject[this.tags.USER_TYPE_JSON_KEY];
    }

    isApplicant(): boolean {
        const loggedUserTypeArray = this.fetchUserTypeArray();
        
        return loggedUserTypeArray.includes(this.APPLICANT) === true;
    }

    isDiplomat(): boolean {
        const loggedUserTypeArray = this.fetchUserTypeArray();
        
        return loggedUserTypeArray.includes(this.DIPLOMAT_1) === true;
    }

}
