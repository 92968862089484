<div class="wrapper">

    <section class="main">

        <div class="profile-view">

            <div class="border_bottom">
            
                <table style="border:0; margin-bottom:0;">
                    <tr>
                        <td style="border:0;"><img src="assets/images/logo-nis.png" style="width:140px;"></td>
        
                        <td style="border:0;" class="fr"><h3>Passport Payment Receipt</h3></td>
                    </tr>
                </table>
            
            </div>

            <section id="applicationdetail" class="w100 print_reciept print-rec mt15">

                <h4>Application Details</h4>
                <table>
                    <tr>
                        <td>
                            <span class="w100 pdf-label">Applicant Name</span>{{paymentReceiptDetails.applicantName}}
                        </td>

                        <td>
                            <span class="w100 pdf-label">Passport Type</span>{{paymentReceiptDetails.passportType}}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <span class="w100 pdf-label">Application No.</span>{{paymentReceiptDetails.applicationIdV1}}
                        </td>

                        <td>
                            <span class="w100 pdf-label">Reference No.</span>{{paymentReceiptDetails.referenceIdV1}}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <span class="w100 pdf-label">Processing Country / Embassy</span>{{paymentReceiptDetails.processingCountry}} / {{paymentReceiptDetails.processingEmbassy}}
                        </td>

                        <td>
                            <span class="w100 pdf-label">Booklet Type / Validity</span>{{paymentReceiptDetails.bookletType}} / {{paymentReceiptDetails.validity}}
                        </td>
                    </tr>
                </table>

                <h4 class="pt-10">Payment Details</h4>
                <table>
                    <tr>    
                        <td>
                            <span class="w100 pdf-label">Order No.</span>{{paymentReceiptDetails.orderReference}}
                        </td>

                        <td>
                            <span class="w100 pdf-label">Order Date (DD/MM/YYYY)</span>{{paymentReceiptDetails.orderDate}}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <span class="w100 pdf-label">Payment Gateway</span>{{paymentReceiptDetails.paymentGateway}}
                        </td>

                        <td>
                            <span class="w100 pdf-label">Payment Mode</span>{{paymentReceiptDetails.paymentMode}}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <span class="w100 pdf-label">Payment Date (DD/MM/YYYY)</span>{{paymentReceiptDetails.paymentDate}}
                        </td>

                        <td>

                        </td>
                    </tr>
                </table>

                <h4 class="pt-10">Application Fee Details</h4>
                <div class="s-content">
        
                    <div style="margin-top:0;border:1px solid #cacbca;">
        
                        <div class="sr-p-info">

                            <ng-container *ngIf="paymentReceiptDetails.paymentDetailsExist === true">

                                <div *ngIf="paymentReceiptDetails.codFeeByGatway > 0">
                                    <label>COD Fee</label>
                                    <p><b>{{paymentReceiptDetails.currencySymbolByGateway}}{{paymentReceiptDetails.codFeeByGatway | number : '1.2' : 'en-US'}}</b></p>
                                </div>

                                <div>
                                    <label>Passport Booklet Fee</label>
                                    <p><b>{{paymentReceiptDetails.currencySymbolByGateway}}{{paymentReceiptDetails.bookletFeeByGatway | number : '1.2' : 'en-US'}} <span *ngIf="paymentReceiptDetails.currencyCode !== paymentReceiptDetails.currencyCodeByGateway">({{paymentReceiptDetails.currencySymbol}}{{paymentReceiptDetails.bookletFee | number : '1.2' : 'en-US'}})</span></b></p>
                                </div>

                                <div *ngIf="paymentReceiptDetails.deliveryFeeByGatway > 0">
                                    <label>Passport Delivery Fee</label>
                                    <p><b>{{paymentReceiptDetails.currencySymbol}}{{paymentReceiptDetails.deliveryFeeByGatway | number : '1.2' : 'en-US'}}</b></p>
                                </div>

                                <div *ngIf="paymentReceiptDetails.serviceCharge > 0">
                                    <label>Transaction Fee</label>
                                    <p><b>{{paymentReceiptDetails.currencySymbolByGateway}}{{paymentReceiptDetails.serviceCharge | number : '1.2' : 'en-US'}}</b></p>
                                </div>

                                <div>
                                    <label>Total Fee</label>
                                    <p><b>{{paymentReceiptDetails.currencySymbolByGateway}}{{paymentReceiptDetails.totalAmountCollectedByGatway | number : '1.2' : 'en-US'}}</b></p>
                                </div>

                            </ng-container>

                            <ng-container *ngIf="paymentReceiptDetails.paymentDetailsExist === false">

                                <div *ngIf="paymentReceiptDetails.codFee > 0">
                                    <label>COD Fee</label>
                                    <p><b>{{paymentReceiptDetails.currencySymbol}} {{paymentReceiptDetails.codFee | number : '1.2' : 'en-US'}}</b></p>
                                </div>

                                <div>
                                    <label>Booklet Fee</label>
                                    <p><b>{{paymentReceiptDetails.currencySymbol}} {{paymentReceiptDetails.bookletFee | number : '1.2' : 'en-US'}}</b></p>
                                </div>

                                <div *ngIf="paymentReceiptDetails.deliveryFee > 0">
                                    <label>Passport Delivery Fee</label>
                                    <p><b>{{paymentReceiptDetails.currencySymbol}}{{paymentReceiptDetails.deliveryFee | number : '1.2' : 'en-US'}}</b></p>
                                </div>

                                <div class="total_box">
                                    <label>Total Fee</label>
                                    <p><b>{{paymentReceiptDetails.currencySymbol}}{{paymentReceiptDetails.totalFee | number : '1.2' : 'en-US'}}</b></p>
                                </div>
                                
                            </ng-container>
                        
                        </div>

                    </div>
                
                </div>

            </section>

            <div style="width: 100%; float: left; text-align: center; padding-top: 10px;">
            
                <p style="font-size:8pt">This is a computer generated bill. No signature/stamp is required.</p>
            
            </div>

        </div>

        <div class="bottom-deatils-content">
            
            <p>{{paymentReceiptDetails.footerContent}}</p>

        </div>

    </section>

</div>
