<div class="modal-content">

    <form #searchApplicationForm="ngForm" (ngSubmit)="searchApplicationForm.form.valid && searchApplication()" autocomplete="off">

        <div class="modal-header">
            <h4>Search Application</h4>
            <i class="fa fa-times" (click)="close()"></i>
        </div>

        <div class="modal-body">
            <div class="grid-modal-container">

                <div class="grid-modal-box">
                    <div class="form_box">
                        <label class="fieldlabels">Application No. <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="applicationId" [(ngModel)]="searchApplicationFormObject.applicationId" #applicationId="ngModel" class="effect" required="required" maxlength="15">
                            <span class="focus-border"><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && applicationId.invalid">
                            <span *ngIf="applicationId.errors.required">
                                Application No. is required!
                            </span>
                        </span>
                    </div>
                </div>
                
                <div class="grid-modal-box">
                    <div class="form_box">
                        <label class="fieldlabels">Reference No. <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="referenceId" [(ngModel)]="searchApplicationFormObject.referenceId" #referenceId="ngModel" class="effect" required="required" maxlength="16">
                            <span class="focus-border"><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && referenceId.invalid">
                            <span *ngIf="referenceId.errors.required">
                                Reference No. is required!
                            </span>
                        </span>
                    </div>
                </div>

                <div class="grid-modal-box search-app">
                    <input type="submit" value="Search" class="btn"/>
                </div>

            </div>    
        </div>

    </form>
    
</div>