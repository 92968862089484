import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { Tags } from '../common/tags';
import { Toast } from '../common/toast';

export interface ConfirmModel {
  isUnpaidSearch: number;

  isGuarantorFormSearch: number;
}
@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.css']
})
export class SearchModalComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {
  
  isUnpaidSearch: number;

  isGuarantorFormSearch: number;

  searchApplicationFormObject: any = {};
  
  constructor(private router: Router, private ngZone: NgZone, private httpUtils: HttpUtils, private tags: Tags, private localStorage: LocalStorage,
              private constants: Constants, private toast: Toast, private loaderService: LoaderService, private gui: GUI) { 
    super();
  }

  searchApplication(){
    this.loaderService.startLoader();
    this.searchApplicationFormObject.isUnpaidSearch = this.isUnpaidSearch;
    this.searchApplicationFormObject.isGuarantorFormSearch = this.isGuarantorFormSearch;
    this.searchApplicationFormObject.loggedUserId = this.localStorage.getLoggedUserId();
    this.httpUtils.searchApplication(this.searchApplicationFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        if(this.isUnpaidSearch === this.constants.TRUE){
          this.addApplicationToCart(serverResponse.applicantId);
        } else if(this.isGuarantorFormSearch === this.constants.TRUE){
          this.openGuarantorForm(serverResponse.applicantId);
        } else {
          this.redirectControl(serverResponse);
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private addApplicationToCart(applicantId: string) {
    this.loaderService.startLoader();
    const loggedUserId = this.localStorage.getLoggedUserId();
    this.httpUtils.saveApplicationToCart(loggedUserId, applicantId).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.result = true;
        this.close();
        this.toast.showSuccessToast("Your application has been added to cart successfully");
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private redirectControl(applicationData: any) {
    const applicationIdV1 = applicationData.applicationIdV1;
    const referenceIdV1 = applicationData.referenceIdV1;
    const isV1Application = applicationData.isV1Application;
    this.result = true;
    this.close();

    if(isV1Application === this.constants.TRUE){
      this.gui.openApplicationViewPageV1(applicationIdV1, referenceIdV1);
      return;
    }

    const applicantId = applicationData.applicantId;
    const applicationStatus = applicationData.applicationStatus;
    
    if(applicationStatus !== this.constants.STATUS_SUCCESS){
      this.gui.openApplicationEditPage(applicantId);
      return;
    }

    this.gui.openApplicationViewPage(applicantId);
  }

  private openGuarantorForm(applicantId: string): void {
    this.result = true;
    this.close();
    this.gui.openGuarantorFormPage(applicantId);
  }

}
