import { Component, OnInit } from '@angular/core';
import { GUI } from '../common/gui';

@Component({
  selector: 'app-contactless-response',
  templateUrl: './contactless-response.component.html',
  styleUrls: ['./contactless-response.component.css']
})
export class ContactlessResponseComponent implements OnInit {
  applicationId: string = "";
  referenceId: string = "";

  constructor(public gui: GUI) {

  }
  private getApplicationInfo(): void {
    this.applicationId = JSON.parse(localStorage.getItem("applicationId")) || null;
    this.referenceId = JSON.parse(localStorage.getItem("referenceId")) || null;

    if (this.applicationId === null && this.referenceId === null) {
      this.gui.openHomePage();

    }
  }
  ngOnInit(): void {
    this.getApplicationInfo();
  }

  ngOnDestroy(): void {
    this.clearApplicationInfo();
  }


  private clearApplicationInfo(): void {
    localStorage.removeItem("applicationId");
    localStorage.removeItem("referenceId");
    localStorage.removeItem("contactlessStatus");
  }


}




