<div class="cod-popup">

    <div class="modal-header">

        <h4 class="list-doc">List of COD Documents</h4>

        <i class="fa fa-times" aria-hidden="true" (click)="cancel()"></i>

    </div>
    <main class="body_section">

        <div class="main">
    
            <div class="cod-Data">
    
                <div class="title_btn">
                    <h2>Correction of data in Passport</h2>
                </div>
          
                <p>To change any data in the Passport, you have to apply for a <b>Correction of data</b> and get the specified change done in the personal particulars. The following is the List of Documents to be submitted along with the application form.</p>
                
                <br>
    
                <h3 class="heading-green mb10">Correction Due to Marriage:</h3>
    
                <div class="tabordion">
                    <div class="tabordion-list">
                        <ul>
                            <li (click)="currentTab = constants.MARRIAGE" [ngClass]="currentTab === constants.MARRIAGE ? 'active' : null " >Married</li>
                            <li (click)="currentTab = constants.DIVORCE" [ngClass]="currentTab === constants.DIVORCE ? 'active' : null " >Divorced</li>
                            <li (click)="currentTab = constants.DECEASED_SPOUSE" [ngClass]="currentTab === constants.DECEASED_SPOUSE ? 'active' : null " >Widowed</li>
                        </ul>
                    </div>
                    <div class="tabordion-output">
                        <div *ngIf="currentTab === constants.MARRIAGE" class="tabordion-panel">
                            <ol>
                                <li> Application Letter.</li>
                                <li> Marriage Certificate or Declaration of Marriage.</li>
                                <li> Sworn Affidavit from the High Court for Change of Name deposed to by Applicant.</li>
                                <li> Copy of Newspaper Publication of Change of Name (21 days after publication).</li>
                                <li> Statutory evidence of change of name (if resident outside Nigeria in lieu of 3 and 4).</li>
                                <li> Passport Data Page.</li>
                            </ol>
                        </div>
                        <div *ngIf="currentTab === constants.DIVORCE" class="tabordion-panel">
                            <ol>
                                <li> Application Letter.</li>
                                <li> Decree Absolute or Declaration of Divorce.</li>
                                <li> In case of Re-marriage, Certificate of Marriage with Current Spouse.</li>
                                <li> Sworn Affidavit from the High Court for Change of Name deposed to by Applicant.</li>
                                <li> Copy of Newspaper Publication of Change of Name (21 days after publication).</li>
                                <li> Statutory evidence of change of name (if resident outside Nigeria in lieu of 4 and 5).</li>
                                <li> Passport Data Page.</li>
                            </ol>
                        </div>
                        <div *ngIf="currentTab === constants.DECEASED_SPOUSE" class="tabordion-panel">
                            <ol>
                                <li> Application Letter.</li>
                                <li> Certificate of Death of spouse from National Population Commission (NPC).</li>
                                <li> In case of Re-marriage, Certificate of Marriage with Current Spouse.</li>
                                <li> Sworn Affidavit from the High Court for Change of Name deposed to by Applicant.</li>
                                <li> Copy of Newspaper Publication of Change of Name (21 days after publication).</li>
                                <li> Statutory evidence of change of name (if resident outside Nigeria in lieu of 4 and 5).</li>
                                <li> Passport Data Page.</li>
                            </ol>
                        </div>
                    </div>
        
                </div>
                <hr style="border-style: dashed;border-color: #aeaeae;border-width: 0.5px;border-bottom: none;">
                
                <br>
                
                <h3 class="heading-green">Correction Due to Error:</h3>
                
                <ol style="padding-left:20px;">
                    <li> Application Letter.</li>
                    <li>Passport Data Page.</li>
                    <li>Sworn Affidavit from the High Court for correction of error, deposed to by Applicant.</li>
                </ol>
                
                <br>
            
                <h4 style="display:block;width:auto;float:left;">Others:</h4>
    
                <ul style="font-family:'sfui-semibold';display:inline-block;padding: 3px 10px;">
                    <li>- Replacement of names</li>
                    <li>- Addition of names</li>
                    <li>- Rearrangement of names</li>
                    <li>- Deletion of names</li>
                </ul>
    
                <ol style="padding-left:20px;">
                    <li> Application Letter.</li>
                    <li>Sworn Affidavit from the High Court for Change of Name deposed to by Applicant with passport sized photograph attached.</li>
                    <li>Copy of Newspaper Publication of Change of name 21 days after the publication.</li>
                    <li>Passport Data Page.</li>
                </ol>
                
            </div>
    
        </div>
        
    </main>
</div>


