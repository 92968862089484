<app-header></app-header>

<div class="wrapper">

    <section class="main">

        <div class="info">

            <h3>Please fill the application form to apply for {{passportTypeLabel}}</h3>

        </div>

        <div class="form_main">

            <ul id="progressbar">

                <ng-container *ngFor="let applicationStepObject of applicationStepsList;">

                    <li [id]="applicationHighestStage >= applicationStepObject.count && applicationStage !== applicationStepObject.count ? applicationStepObject.id + '-tick' : applicationStepObject.id"
                        [ngClass]="applicationHighestStage >= applicationStepObject.count ? 'active allow-cursor' : 'not-allow-cursor'"
                        [class]="enablePassportDeliveryStage === true ? 'sevenstages' : ''"
                        (click)="applicationHighestStage >= applicationStepObject.count ? openFieldSet(applicationStepObject.count, $event) : ''">
                        <p>{{applicationStepObject.label}}</p>
                    </li>

                </ng-container>

            </ul>

            <fieldset *ngIf="applicationStage === constants.PASSPORT_STEP" class="passport_type">

                <div class="step_info">
                    <span>Step {{constants.PASSPORT_STEP}}: {{applicationStepsList[constants.PASSPORT_STEP -
                        1].label}}</span>
                </div>

                <form #passportTypeForm="ngForm"
                    (ngSubmit)="passportTypeForm.form.valid && menuClickedStage = this.constants.ANY && openImageUploadForm($event)"
                    autocomplete="off" name="passportTypeForm" id="msform" appFocus>

                    <input type="hidden" name="hasFieldValueChanged"
                        [(ngModel)]="passportTypeFormObject.hasFieldValueChanged" #hasFieldValueChanged="ngModel">

                    <input type="hidden" name="callableFunctionName"
                        [(ngModel)]="passportTypeFormObject.callableFunctionName" #callableFunctionName="ngModel">

                    <ng-template #instructions>
                        <ul>
                            <li><span>Check Centre Appointment Availability before proceeding to the next step</span>
                            </li>
                            <li><span>All fields that contain the Asterisk (*) sign are mandatory and must be
                                    completed.</span></li>
                        </ul>
                    </ng-template>

                    <div class="grid-one-third">

                        <div class="grid-onethird-box">

                            <details>

                                <summary>Instructions for Applicant </summary>

                                <div class="content">

                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                                </div>

                            </details>

                            <div class="grid-two">

                                <ng-container *ngIf="personalDetailsFormObject.isFreshApplication === false">
                                    <div class="form_box">
                                        <label class="fieldlabels">1. {{changeTypeLabel}} <span
                                                class="medatory">*</span></label>
                                        <div class="input_box">
                                            <mat-select name="changeType"
                                                [(ngModel)]="passportTypeFormObject.changeType" #changeType="ngModel"
                                                [identify-value-change]="passportTypeFormObject"
                                                (ngModelChange)="fetchPassportFeeDetails();" required="required"
                                                class="effect">
                                                <mat-option>
                                                    <ngx-mat-select-search
                                                        [formControl]="changeTypeSelectService.selectFilterControl"
                                                        noEntriesFoundLabel="No data found" placeholderLabel="Search"
                                                        autocomplete="off"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option
                                                    *ngFor="let changeType of changeTypeSelectService.filteredOptions | async"
                                                    [value]="changeType.value">{{changeType.text}}</mat-option>
                                            </mat-select>
                                            <span class="tooltip"><span class='tooltipArrow'></span>Select the
                                                appropriate reason that best describes the purpose of
                                                renewing/re-issuing the passport.</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportTypeForm.submitted && changeType.invalid">
                                            <span *ngIf="changeType.errors.required">
                                                {{changeTypeLabel}} is required!
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box">
                                        <label class="fieldlabels">2. Passport Number <span
                                                *ngIf="passportTypeFormObject.changeType !== constants.LOST_REPLACEMENT_REQUEST"
                                                class="medatory">*</span></label>
                                        <div class="input_box">
                                            <input type="text" name="passportNumber" hint
                                                [identify-value-change]="passportTypeFormObject" alphaNumericOnly
                                                [(ngModel)]="passportTypeFormObject.passportNumber"
                                                #passportNumber="ngModel"
                                                [required]="passportTypeFormObject.changeType !== constants.LOST_REPLACEMENT_REQUEST"
                                                autocomplete="off" [pattern]="pattern.PASSPORT_NUMBER_PATTERN"
                                                (input)="passportTypeFormObject.passportNumber = passportTypeFormObject.passportNumber.toUpperCase()"
                                                class="effect" minlength="7" maxlength="9" disabled="disabled">
                                            <span class="tooltip"><span class='tooltipArrow'></span>Enter applicant's
                                                current passport number as it appears on the existing passport.</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportTypeForm.submitted && passportNumber.invalid">
                                            <span *ngIf="passportNumber.errors?.required">
                                                Passport Number is required!
                                            </span>
                                            <span *ngIf="passportNumber.errors?.minlength">
                                                Passport Number should be 7 digits!
                                            </span>
                                            <span
                                                *ngIf="!passportNumber.errors?.minlength && passportNumber.errors?.pattern">
                                                Passport Number should start with alphabet!
                                            </span>
                                        </span>
                                    </div>
                                </ng-container>

                                <div class="form_box">
                                    <label class="fieldlabels">{{passportStageSerialNumber + 1}}. Processing Country
                                        <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <mat-select name="processingCountry"
                                            [(ngModel)]="passportTypeFormObject.processingCountry"
                                            #processingCountry="ngModel"
                                            [identify-value-change]="passportTypeFormObject"
                                            (ngModelChange)="storeCountryEmbassyList(true); fetchPassportFeeDetails();"
                                            (selectionChange)="passportDeliveryFormObject.receiverAddressCountry = $event.source.triggerValue;"
                                            (openedChange)="utils.checkHasMatValueChanged($event, passportTypeFormObject, 'processingCountry');"
                                            required="required" class="effect">
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="processingCountrySelectService.selectFilterControl"
                                                    noEntriesFoundLabel="No data found" placeholderLabel="Search"
                                                    autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option
                                                *ngFor="let country of processingCountrySelectService.filteredOptions | async"
                                                [value]="country.value">{{country.text}}</mat-option>
                                        </mat-select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Choose the country of
                                            citizenship, as it appears on applicant's passport. The country of
                                            citizenship is required to complete the application.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert"
                                        *ngIf="passportTypeForm.submitted && processingCountry.invalid">
                                        <span *ngIf="processingCountry.errors.required">
                                            Processing Country is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">{{passportStageSerialNumber + 2}}. Processing Embassy
                                        <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <mat-select name="processingEmbassy"
                                            [(ngModel)]="passportTypeFormObject.processingEmbassy"
                                            #processingEmbassy="ngModel"
                                            [identify-value-change]="passportTypeFormObject"
                                            (ngModelChange)="storeEmbassyBookletList(true); fetchPassportFeeDetails();"
                                            (selectionChange)="setAppointmentAvailabilityURL($event.source.triggerValue);"
                                            (openedChange)="utils.checkHasMatValueChanged($event, passportTypeFormObject, 'processingEmbassy');"
                                            required="required" class="effect">
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="embassySelectService.selectFilterControl"
                                                    noEntriesFoundLabel="No data found" placeholderLabel="Search"
                                                    autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option
                                                *ngFor="let processingEmbassy of embassySelectService.filteredOptions | async"
                                                [value]="processingEmbassy.value">{{processingEmbassy.text}}</mat-option>
                                        </mat-select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Choose the nearby
                                            processing embassy according to the country you have chosen before and their
                                            availability to streamline your application process.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span *ngIf="utils.isEmpty(passportTypeFormObject.processingEmbassy) === false"
                                        class="book_center ensure-txt">
                                        Ensure to check appointment availability in the center
                                        <a [href]="centreAvailabilityUrl" target="_blank" rel="noopener noreferrer"
                                            style="color: #0087ff;text-decoration: underline;">here.</a>
                                    </span>
                                    <span class="alert alert-danger" role="alert"
                                        *ngIf="passportTypeForm.submitted && processingEmbassy.invalid">
                                        <span *ngIf="processingEmbassy.errors.required">Processing Embassy is
                                            required!</span>
                                    </span>
                                </div>

                                <div class="form_box">

                                    <label class="fieldlabels">{{passportStageSerialNumber + 3}}. Booklet Type <span
                                            class="medatory">*</span></label>

                                    <div class="input_box">

                                        <mat-select name="bookletType" [(ngModel)]="passportTypeFormObject.bookletType"
                                            #bookletType="ngModel" [identify-value-change]="passportTypeFormObject"
                                            (ngModelChange)="storeEmbassyBookletValidityList(true); fetchPassportFeeDetails()"
                                            (openedChange)="utils.checkHasMatValueChanged($event, passportTypeFormObject, 'bookletType');"
                                            required="required" class="effect">
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="bookletSelectService.selectFilterControl"
                                                    noEntriesFoundLabel="No data found" placeholderLabel="Search"
                                                    autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option
                                                *ngFor="let booklet of bookletSelectService.filteredOptions | async"
                                                [value]="booklet.value">{{booklet.text}}</mat-option>
                                        </mat-select>

                                        <span class="tooltip"><span class='tooltipArrow'></span>Select the number of
                                            pages you require in the passport.</span>
                                        <span class="focus-border "><i></i></span>

                                    </div>

                                    <span class="alert alert-danger" role="alert"
                                        *ngIf="passportTypeForm.submitted && bookletType.invalid">
                                        <span *ngIf="bookletType.errors.required">Booklet Type is required!</span>
                                    </span>

                                </div>

                                <div class="form_box">

                                    <label class="fieldlabels">{{passportStageSerialNumber + 4}}. Validity <span
                                            class="medatory">*</span></label>

                                    <div class="input_box">

                                        <mat-select name="validity" [(ngModel)]="passportTypeFormObject.validity"
                                            [identify-value-change]="passportTypeFormObject"
                                            (ngModelChange)="fetchPassportFeeDetails();" #validity="ngModel"
                                            required="required" class="effect">
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="validitySelectService.selectFilterControl"
                                                    noEntriesFoundLabel="No data found" placeholderLabel="Search"
                                                    autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option
                                                *ngFor="let validity of validitySelectService.filteredOptions | async"
                                                [value]="validity.value">{{validity.text}}</mat-option>
                                        </mat-select>

                                        <span class="tooltip"><span class='tooltipArrow'></span>Please choose the
                                            validity period for the passport.</span>
                                        <span class="focus-border "><i></i></span>

                                    </div>

                                    <span class="alert alert-danger" role="alert"
                                        *ngIf="passportTypeForm.submitted && validity.invalid">
                                        <span *ngIf="validity.errors.required">Validity is required!</span>
                                    </span>

                                </div>

                                <div class="form_box mb30"
                                    *ngIf="isBiometricEnrollmentRequired && passportTypeFormObject.applyingFor === constants.RENEW && (passportTypeFormObject.changeType=== constants.EXPIRED ||passportTypeFormObject.changeType=== constants.PAGE_EXHAUSTED )">
                                    <div class="radio">
                                        <h4>How would you like your biometric enrolled?</h4>
                                        <div class="radio-group">
                                            <input type="radio" name="contactlessStatus" [value]="constants.FALSE"
                                                #isContactless="ngModel"
                                                [(ngModel)]="passportTypeFormObject.contactlessStatus"
                                                [required]="isBiometricEnrollmentRequired" class="infoyn"
                                                id="visitOffice" (ngModelChange)="storeContactlessStatus(false)" />
                                            <label for="visitOffice" class="infoyn">
                                                Visit Immigration Processing Office
                                            </label>
                                        </div>

                                        <div class="radio-group">
                                            <input type="radio" name="contactlessStatus" [value]="constants.TRUE"
                                                #isContactless="ngModel"
                                                [(ngModel)]="passportTypeFormObject.contactlessStatus"
                                                [required]="isBiometricEnrollmentRequired" class="infoyn"
                                                id="selfEnrollment" (ngModelChange)="storeContactlessStatus(true)" />
                                            <label for="selfEnrollment" class="infoyn">
                                                Self Enrollment (Additional fees will be charged)
                                            </label>
                                        </div>

                                        <div *ngIf="passportTypeFormObject.submitted && isContactless.invalid"
                                            class="alert alert-danger" role="alert">
                                            <span *ngIf="isContactless.errors?.required">
                                                Confirmation is required
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="grid-onethird-box upload_inst">

                            <div *ngIf="isShowFeesSection === false" class="element mt20 none_dis">

                                <h4>Instructions</h4>

                                <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                            </div>

                            <div *ngIf="isShowFeesSection === true" class="grid-box">

                                <div class="fee">

                                    <div *ngIf="feeDetailsObject.codFee > 0">COD Fee:
                                        <span>{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.codFeeView | number
                                            : '1.2' : 'en-US'}}</span>
                                    </div>

                                    <div>Passport Booklet Fee : <span>{{feeDetailsObject.currencySymbol}}
                                            {{feeDetailsObject.bookletFee | number : '1.2' : 'en-US'}}</span></div>

                                    <div *ngIf="feeDetailsObject.deliveryFee > 0">Passport Delivery Fee:
                                        <span>{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.deliveryFee |
                                            number : '1.2' : 'en-US'}}</span>
                                    </div>


                                    <div *ngIf="passportTypeFormObject.contactlessStatus ">
                                        Contactless Fee:
                                        <span>{{ feeDetailsObject.currencySymbol }}
                                            {{
                                            100
                                            | number : "1.2" : "en-US"
                                            }}</span>
                                    </div>


                                    <div><b>Total Fee :</b> <span><b>{{feeDetailsObject.currencySymbol}}
                                                {{feeDetailsObject.totalFee | number : '1.2' : 'en-US'}}</b></span>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <label *ngIf="isShowFeesSection === true" class="t_and_c pass-det pointer">
                        <input type="checkbox" name="termCondition"
                            [(ngModel)]="passportTypeFormObject.isTermConditionSelect" #termCondition="ngModel"
                            required="required"> <span class="confirm-text">I hereby confirm that I have checked the
                            appointment availability at the chosen processing office.</span>
                        <span class="alert alert-danger" role="alert"
                            *ngIf="passportTypeForm.submitted && termCondition.invalid">
                            <span class="font-regular" *ngIf="termCondition.errors.required">
                                Confirmation is required
                            </span>
                        </span>
                    </label>

                    <div style="width: 100%;">
                        <input type="submit" (click)="setShallExitApplication(true)" class="save_btn"
                            value="Save & Exit" />

                        <input type="submit" (click)="setShallExitApplication(false)" class="action-button custom_btn"
                            value="Continue" />
                    </div>
                </form>

            </fieldset>

            <fieldset *ngIf="applicationStage === constants.UPLOAD_PHOTO_STAGE">

                <div class="step_info">
                    <span>Step {{constants.UPLOAD_PHOTO_STAGE}}: Upload a Colored Passport Sized Photograph</span>
                </div>

                <form #uploadPhotoForm="ngForm"
                    (ngSubmit)="uploadPhotoForm.form.valid && menuClickedStage = this.constants.ANY && openPersonalDetailsForm($event)"
                    autocomplete="off" name="uploadPhotoForm" id="msform">

                    <input type="hidden" name="hasFieldValueChanged"
                        [(ngModel)]="uploadPhotoFormObject.hasFieldValueChanged" #hasFieldValueChanged="ngModel">

                    <input type="hidden" name="callableFunctionName"
                        [(ngModel)]="uploadPhotoFormObject.callableFunctionName" #callableFunctionName="ngModel">

                    <ng-template #instructions>
                        <h4>Fundamental rules for uploading picture</h4>

                        <p>Please keep the following points in mind while uploading the picture</p>

                        <ul>
                            <li>Image resolution should be atleast 600x800 px.</li>
                            <li>The person must have a neutral expression with both eyes open and mouth closed. Smiling
                                or frowning is not allowed, as it can alter facial features.</li>
                            <li>The head and shoulders of the subject must be centered in the photo, with the face fully
                                visible and not tilted. The person must be looking directly at the camera with a relaxed
                                and natural head position.</li>
                            <li>The photo must be taken in good lighting conditions, with no harsh shadows or
                                reflections on the face.</li>
                            <li>The photo must be in color, with accurate color representation and good contrast between
                                the subject's face and the background.</li>
                            <li>The photo should not have any red-eye effect or other image defects that could affect
                                facial recognition accuracy.</li>
                            <li>ICAO guidelines have relaxation for kids under the age of 5. Kids must look toward the
                                camera and the face should be centered</li>
                        </ul>

                        <!-- <p class="guidlines mt10"> You can go through some sample pictures for a better understanding by clicking <a href="javascript:void(0)" (click)="openPhotoGuidelineModal()">Read photo guidelines in detail.</a> </p> -->
                    </ng-template>

                    <details>

                        <summary>Instructions for Applicant </summary>

                        <div class="content">
                            <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                        </div>

                    </details>

                    <div class="search_grid-upload-img">

                        <h3 style="color: red;">Note: The photo you are uploading here will be printed on the
                            applicant's passport.</h3>

                        <div class="grid-box">

                            <div class="img_upload">
                                <div class="img_box" style="display: flex;">
                                    <input type="hidden" name="validatedBase64Data"
                                        [(ngModel)]="uploadPhotoFormObject.validatedBase64Data"
                                        #validatedBase64Data="ngModel" class="effect" required="required">
                                    <img [src]="imageValidated === true ? uploadPhotoFormObject.validatedBase64Data : 'assets/images/defaultImage.png'"
                                        class="cropped">
                                </div>
                                <p style="position: relative; padding-top: 13px;">
                                    <span class="alert alert-danger" role="alert"
                                        *ngIf="uploadPhotoForm.submitted && validatedBase64Data.invalid"
                                        style="position: absolute; top: 0; left: 0; padding-top: 5px;">
                                        <span *ngIf="validatedBase64Data.errors.required">
                                            Image is required!
                                        </span>
                                    </span>
                                </p>

                                <div class="photo_icons" style="text-align: left;">
                                    <!-- <label class="w_auto icons_uplaod pt-10">
                                        <input type="button" class="upload_btn" value="Upload Photo" (click)="openImageUploadModal(uploadPhotoFormObject, 'validatedBase64Data', 'passportImageFileType');"/>
                                    </label> -->
                                    <button class="filt--upload" type="button" class="upload_btn" value="Upload Photo"
                                        (click)="openImageUploadModal(uploadPhotoFormObject, 'validatedBase64Data', 'passportImageFileType');">
                                        <span id="upload" class="upl">Upload Photo</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="grid-box">

                            <div class="verify-picture-grid">

                                <div class="verify-picture">

                                    <li>
                                        <div class="pic-upload">
                                            <img src="assets/images/verify-img1.jpg" alt="image">
                                            <span class="icon-upload verify"><i aria-hidden="true"
                                                    class="fa fa-check"></i></span>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="pic-upload">
                                            <img src="assets/images/verify-img2.jpg" alt="image">
                                            <span class="icon-upload not-verify"><i aria-hidden="true"
                                                    class="fa fa-times"></i></span>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="pic-upload">
                                            <img src="assets/images/verify-img3.jpg" alt="image">
                                            <span class="icon-upload not-verify"><i aria-hidden="true"
                                                    class="fa fa-times"></i></span>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="pic-upload">
                                            <img src="assets/images/verify-img4.png" alt="image">
                                            <span class="icon-upload verify"><i aria-hidden="true"
                                                    class="fa fa-check"></i></span>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="pic-upload">
                                            <img src="assets/images/verify-img5.png" alt="image">
                                            <span class="icon-upload not-verify"><i aria-hidden="true"
                                                    class="fa fa-times"></i></span>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="pic-upload">
                                            <img src="assets/images/verify-img6.png" alt="image">
                                            <span class="icon-upload not-verify"><i aria-hidden="true"
                                                    class="fa fa-times"></i></span>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="pic-upload">
                                            <img src="assets/images/verify-img7.png" alt="image">
                                            <span class="icon-upload verify"><i aria-hidden="true"
                                                    class="fa fa-check"></i></span>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="pic-upload">
                                            <img src="assets/images/verify-img8.png" alt="image">
                                            <span class="icon-upload not-verify"><i aria-hidden="true"
                                                    class="fa fa-times"></i></span>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="pic-upload">
                                            <img src="assets/images/verify-img9.png" alt="image">
                                            <span class="icon-upload not-verify"><i aria-hidden="true"
                                                    class="fa fa-times"></i></span>
                                        </div>
                                    </li>

                                </div>

                                <div class="guidlines mt10">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                    <p>You can go through some sample pictures for a better understanding by clicking <a
                                            href="javascript:void(0)" (click)="openPhotoGuidelineModal()">Read photo
                                            guidelines in detail.</a> </p>
                                </div>

                            </div>

                        </div>

                        <div class="grid-box upload_inst">

                            <div class="element none_dis" style="margin-top: 0;">

                                <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                            </div>

                        </div>

                        <div class="w100">

                            <input type="button" (click)="openPreviousFieldSet()"
                                class="action-button-previous back_btn" value="Previous Page" />

                            <ng-container *ngIf="imageValidated === true">

                                <input type="submit" (click)="setShallExitApplication(true)" value="Save & Exit"
                                    class="action-button-previous save_btn" />

                                <input type="submit" (click)="setShallExitApplication(false)" value="Continue"
                                    class="action-button custom_btn" />

                            </ng-container>
                        </div>

                    </div>

                </form>

            </fieldset>

            <fieldset *ngIf="applicationStage === constants.PERSONAL_INFO_STEP">

                <div class="step_info">
                    <span>Step {{constants.PERSONAL_INFO_STEP}}: {{applicationStepsList[constants.PERSONAL_INFO_STEP -
                        1].label}}</span>
                </div>

                <form #personalDetailsForm="ngForm"
                    (ngSubmit)="personalDetailsForm.form.valid && menuClickedStage = this.constants.ANY && openContactInformationForm($event)"
                    name="personalDetailsForm" autocomplete="off" id="msform"
                    [validateDateOfBirth]="['dateOfBirthDay', 'dateOfBirthMonth', 'dateOfBirthYear', constants.TRUE]"
                    error-focus>

                    <input type="hidden" name="hasFieldValueChanged"
                        [(ngModel)]="personalDetailsFormObject.hasFieldValueChanged" #hasFieldValueChanged="ngModel">

                    <input type="hidden" name="callableFunctionName"
                        [(ngModel)]="personalDetailsFormObject.callableFunctionName" #callableFunctionName="ngModel">

                    <ng-template #instructions>
                        <ul>
                            <li>The personal details populated here are as per your NIN records and the same will be
                                printed on your passport</li>

                            <li>Kindly update Place of Birth, Country of Birth, Marital Status, Occupation, Maiden name
                                and Email, if required.</li>

                            <li>All fields that contain the Asterisk (*) sign are mandatory and must be completed.</li>
                        </ul>
                    </ng-template>

                    <div class="grid-one-third">

                        <div class="grid-onethird-box">

                            <details>

                                <summary>Instructions for Applicant </summary>

                                <div class="content">
                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                                </div>

                            </details>

                            <div class="grid-two">

                                <div class="form_box">
                                    <label class="fieldlabels">1. Title <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <select name="title" [(ngModel)]="personalDetailsFormObject.title"
                                            #title="ngModel" [identify-value-change]="personalDetailsFormObject"
                                            class="effect" required="required">
                                            <option *ngFor="let object of titleList;" [value]="object.value">
                                                {{object.text}}</option>
                                        </select>
                                        <span class="icon_caret"><i class="fa fa-caret-down"
                                                aria-hidden="true"></i></span>
                                        <span class="tooltip"><span class='tooltipArrow'></span></span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="personalDetailsForm.submitted && title.invalid">
                                        <span *ngIf="title.errors?.required">
                                            Title is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box ">
                                    <label class="fieldlabels">2. Surname <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="lastName"
                                            [(ngModel)]="personalDetailsFormObject.lastName" #lastName="ngModel"
                                            [identify-value-change]="personalDetailsFormObject" class="effect"
                                            required="required" disabled="disabled" minlength="2" maxlength="50">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter the surname
                                            or last name here. This should be same as applicant's official documents
                                            such as identification cards, passports, or legal papers.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="personalDetailsForm.submitted && lastName.invalid">
                                        <span *ngIf="lastName.errors?.required">
                                            Surname is required!
                                        </span>
                                        <span *ngIf="lastName.errors?.minlength">
                                            Surname should be of minimum 2 characters!
                                        </span>
                                        <span *ngIf="!lastName.errors?.minlength && lastName.errors?.pattern">
                                            Surname should start and end with an alphabet!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box ">
                                    <label class="fieldlabels">3. First Name <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="firstName"
                                            [(ngModel)]="personalDetailsFormObject.firstName" #firstName="ngModel"
                                            [identify-value-change]="personalDetailsFormObject" disabled="disabled"
                                            class="effect" required="required" minlength="2" maxlength="50">
                                        <span class="tooltip"><span class='tooltipArrow'></span>First names (given
                                            names) as stated in passport. </span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="personalDetailsForm.submitted && firstName.invalid">
                                        <span *ngIf="firstName.errors?.required">
                                            First Name is required!
                                        </span>
                                        <span *ngIf="firstName.errors?.minlength">
                                            First Name should be of minimum 2 characters!
                                        </span>
                                        <span *ngIf="!firstName.errors?.minlength && firstName.errors?.pattern">
                                            First Name should start with an alphabet and end with an alphabet or number!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box ">
                                    <label class="fieldlabels">4. Middle Name (Optional)</label>
                                    <div class="input_box">
                                        <input type="text" name="middleName"
                                            [(ngModel)]="personalDetailsFormObject.middleName" #middleName="ngModel"
                                            [identify-value-change]="personalDetailsFormObject" disabled="disabled"
                                            class="effect" minlength="2" maxlength="50">
                                        <span class="tooltip"><span class='tooltipArrow'></span>In the middle name
                                            field, enter the middle name as appears on the passport. The Middle name is
                                            optional .</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="personalDetailsForm.submitted && middleName.invalid">
                                        <span *ngIf="middleName.errors?.minlength">
                                            Middle Name should be of minimum 2 characters!
                                        </span>
                                        <span *ngIf="!middleName.errors?.minlength && middleName.errors?.pattern">
                                            Middle Name should start and end with an alphabet!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box ">
                                    <label class="fieldlabels">5. Date of Birth (dd/mm/yyyy) <span
                                            class="medatory">*</span></label>
                                    <div class="grid-date">
                                        <div class="input_box">
                                            <input type="text" name="dateOfBirthDay" hint number-only
                                                [(ngModel)]="personalDetailsFormObject.dateOfBirthDay"
                                                #dateOfBirthDay="ngModel"
                                                [identify-value-change]="personalDetailsFormObject" disabled="disabled"
                                                class="effect" required="required" maxlength="2" placeholder="DD">
                                            <span class="tooltip"><span class='tooltipArrow'></span></span>
                                            <span class="focus-border "><i></i></span>
                                        </div>

                                        <div class="input_box">
                                            <select name="dateOfBirthMonth"
                                                [(ngModel)]="personalDetailsFormObject.dateOfBirthMonth"
                                                #dateOfBirthMonth="ngModel"
                                                [identify-value-change]="personalDetailsFormObject" disabled="disabled"
                                                class="effect" required="required">
                                                <option *ngFor="let object of monthsList;" [value]="object.value">
                                                    {{object.text}}</option>
                                            </select>
                                            <span class="icon_caret"><i class="fa fa-caret-down"
                                                    aria-hidden="true"></i></span>
                                            <span class="tooltip"><span class='tooltipArrow'></span></span>
                                            <span class="focus-border "><i></i></span>
                                        </div>

                                        <div class="input_box">
                                            <input type="text" name="dateOfBirthYear" hint number-only
                                                [(ngModel)]="personalDetailsFormObject.dateOfBirthYear"
                                                #dateOfBirthYear="ngModel"
                                                [identify-value-change]="personalDetailsFormObject" disabled="disabled"
                                                class="effect" required="required" minlength="4" maxlength="4"
                                                placeholder="YYYY">
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="personalDetailsForm.submitted && (dateOfBirthDay.invalid || dateOfBirthMonth.invalid || dateOfBirthYear.invalid)">
                                        <span
                                            *ngIf="(dateOfBirthDay.errors?.required || dateOfBirthMonth.errors?.required || dateOfBirthYear.errors?.required)">
                                            Date of Birth is required!
                                        </span>
                                        <span *ngIf="dateOfBirthYear.errors?.minlength">
                                            Date of Birth's year should 4 digits!
                                        </span>
                                        <span
                                            *ngIf="!dateOfBirthYear.errors?.minlength && dateOfBirthDay.errors?.hasError">
                                            {{dateOfBirthDay.errors.errorMessage}}
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">6. Gender <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <select name="gender" [(ngModel)]="personalDetailsFormObject.gender"
                                            #gender="ngModel" [identify-value-change]="personalDetailsFormObject"
                                            class="effect" required="required" disabled="disabled">
                                            <option [value]="constants.BLANK">{{constants.DEFAULT_SELECT_OPTION_LABEL}}
                                            </option>
                                            <option [value]="constants.MALE">Male</option>
                                            <option [value]="constants.FEMALE">Female</option>
                                        </select>
                                        <span class="icon_caret"><i class="fa fa-caret-down"
                                                aria-hidden="true"></i></span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="personalDetailsForm.submitted && gender.invalid">
                                        <span *ngIf="gender.errors?.required">
                                            Gender is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">7. Place of Birth <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="placeOfBirth" hint
                                            [(ngModel)]="personalDetailsFormObject.placeOfBirth" #placeOfBirth="ngModel"
                                            [identify-value-change]="personalDetailsFormObject" class="effect"
                                            required="required" minlength="2" maxlength="65"
                                            [pattern]="pattern.COMMON_FIELD_PATTERN">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter the
                                            city/town/village in which you were born.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="personalDetailsForm.submitted && placeOfBirth.invalid">
                                        <span *ngIf="placeOfBirth.errors?.required">
                                            Place of Birth is required!
                                        </span>
                                        <span *ngIf="placeOfBirth.errors?.minlength">
                                            Place of Birth should be of minimum 2 characters!
                                        </span>
                                        <span *ngIf="!placeOfBirth.errors?.minlength && placeOfBirth.errors?.pattern">
                                            Place of Birth should start and end with an alphabet!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">8. Country of Birth <span
                                            class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="countryOfBirth" hint
                                            [(ngModel)]="personalDetailsFormObject.countryOfBirth"
                                            #countryOfBirth="ngModel"
                                            [identify-value-change]="personalDetailsFormObject" class="effect"
                                            required="required" autocomplete="off" minlength="2" maxlength="40">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter the country in
                                            which you were born.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert"
                                        *ngIf="personalDetailsForm.submitted && countryOfBirth.invalid">
                                        <span *ngIf="countryOfBirth.errors.required">
                                            Country of Birth is required!
                                        </span>
                                        <span *ngIf="countryOfBirth.errors?.minlength">
                                            Country of Birth should be of minimum 2 characters!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">9. Marital Status <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <select name="maritalStatus"
                                            [(ngModel)]="personalDetailsFormObject.maritalStatus"
                                            #maritalStatus="ngModel" [identify-value-change]="personalDetailsFormObject"
                                            class="effect" required="required">
                                            <option [value]="constants.BLANK">{{constants.DEFAULT_SELECT_OPTION_LABEL}}
                                            </option>
                                            <option [value]="constants.SINGLE">Single</option>
                                            <option [value]="constants.MARRIED">Married</option>
                                            <option [value]="constants.WIDOWED">Widowed</option>
                                            <option [value]="constants.DIVORCED">Divorced</option>
                                        </select>
                                        <span class="icon_caret"><i class="fa fa-caret-down"
                                                aria-hidden="true"></i></span>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please select
                                            applicant's current marital status. This will be required to ensure list of
                                            documents to be uploaded on later stage.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="personalDetailsForm.submitted && maritalStatus.invalid">
                                        <span *ngIf="maritalStatus.errors?.required">
                                            Marital status is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">9. Occupation <span *ngIf="ageGroup !== constants.MINOR"
                                            class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="occupation" hint name-directive
                                            [(ngModel)]="personalDetailsFormObject.occupation" #occupation="ngModel"
                                            [identify-value-change]="personalDetailsFormObject" class="effect"
                                            [required]="ageGroup !== constants.MINOR" maxlength="50">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Provide details about
                                            applicant's employment or field of work. Example: Teacher, Software
                                            Engineer, Nurse, etc.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="personalDetailsForm.submitted && occupation.invalid">
                                        <span *ngIf="occupation.errors?.required">
                                            Occupation is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">10. Maiden Name</label>
                                    <div class="input_box">
                                        <input type="text" name="maidenName" hint name-directive disallowSpaces
                                            [(ngModel)]="personalDetailsFormObject.maidenName" #maidenName="ngModel"
                                            [identify-value-change]="personalDetailsFormObject" class="effect"
                                            minlength="2" maxlength="50" [pattern]="pattern.COMMON_FIELD_PATTERN">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Maiden name refers to
                                            the surname you used before marriage. Please enter applicant's previous last
                                            name here, if applicable.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="personalDetailsForm.submitted && maidenName.invalid">
                                        <span *ngIf="maidenName.errors?.required">
                                            Maiden Name is required!
                                        </span>
                                        <span *ngIf="maidenName.errors?.minlength">
                                            Maiden Name should be of minimum 2 characters!
                                        </span>
                                        <span *ngIf="!maidenName.errors?.minlength && maidenName.errors?.pattern">
                                            Maiden Name should start and end with an alphabet!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">11. Communication E-mail <span
                                            class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="email" hint name="emailId"
                                            [(ngModel)]="personalDetailsFormObject.emailId" #emailId="ngModel"
                                            class="effect" maxlength="100" required="required"
                                            [pattern]="pattern.EMAIL_FIELD_PATTERN">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please provide a valid
                                            email address in the format yourname@example.com. This email will serve as
                                            the primary point of contact regarding application and payment</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert"
                                        *ngIf="personalDetailsForm.submitted && emailId.invalid">
                                        <span *ngIf="emailId.errors.required">
                                            E-mail is required!
                                        </span>
                                        <span *ngIf="emailId.errors.pattern">
                                            E-mail should be valid!
                                        </span>
                                    </span>
                                </div>

                            </div>

                        </div>

                        <div class="grid-onethird-box upload_inst">

                            <div class="element mt20 none_dis">

                                <h4>Instructions</h4>

                                <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                            </div>

                        </div>

                    </div>

                    <input type="button" (click)="openPreviousFieldSet();" class="action-button-previous back_btn mt20"
                        value="Previous Page" />

                    <input type="submit" (click)="setShallExitApplication(true);" class="save_btn"
                        value="Save & Exit" />

                    <input type="submit" (click)="setShallExitApplication(false);" class="action-button custom_btn"
                        value="Continue" />

                </form>

            </fieldset>

            <fieldset *ngIf="applicationStage === constants.CONTACT_INFO_STEP">

                <div class="step_info">
                    <span>Step {{constants.CONTACT_INFO_STEP}}: {{applicationStepsList[constants.CONTACT_INFO_STEP -
                        1].label}}</span>
                </div>

                <form #contactInformationForm="ngForm"
                    (ngSubmit)="contactInformationForm.form.valid && menuClickedStage = this.constants.ANY && openKinInformationForm($event)"
                    name="contactInformationForm" autocomplete="off" id="msform" error-focus>

                    <input type="hidden" name="hasFieldValueChanged"
                        [(ngModel)]="contactInformationFormObject.hasFieldValueChanged" #hasFieldValueChanged="ngModel">

                    <input type="hidden" name="callableFunctionName"
                        [(ngModel)]="contactInformationFormObject.callableFunctionName" #callableFunctionName="ngModel">

                    <ng-template #instructions>
                        <ul>
                            <li><span>Kindly fill in your address details correctly for further communication in the
                                    Passport application process.</span></li>

                            <li><span>Enter a valid phone number in the format +234-1234567891.</span></li>

                            <li><span>All fields that contain the Asterisk (*) sign are mandatory and must be
                                    completed.</span></li>
                        </ul>
                    </ng-template>

                    <div class="grid-one-third">

                        <div class="grid-onethird-box">

                            <details>

                                <summary>Instructions for Applicant </summary>

                                <div class="content">

                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                                </div>

                            </details>

                            <div class="grid-two">

                                <div class="form_box">
                                    <label class="fieldlabels">1. Contact Number <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="mobileNumber" hint number-only
                                            [(ngModel)]="contactInformationFormObject.mobileNumber"
                                            #mobileNumber="ngModel"
                                            [identify-value-change]="contactInformationFormObject" class="focus_border"
                                            required="required" ng2TelInput
                                            [ng2TelInputOptions]="personalMobileNumberOptions"
                                            (countryChange)="utils.setMobileNumberFields($event, contactInformationFormObject, ['mobileNumberCountryISOCode', 'mobileNumberDialCode', 'mobileNumberMinLength', 'mobileNumberMaxLength'])"
                                            (intlTelInputObject)="utils.setMobileNumberFields($event.s, contactInformationFormObject, ['mobileNumberCountryISOCode', 'mobileNumberDialCode', 'mobileNumberMinLength', 'mobileNumberMaxLength'])"
                                            [minlength]="contactInformationFormObject?.mobileNumberMinLength"
                                            [maxlength]="contactInformationFormObject?.mobileNumberMaxLength">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter a valid
                                            contact number where you can be reached easily.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && mobileNumber.invalid">
                                        <span *ngIf="mobileNumber.errors?.required">
                                            Contact Number is required!
                                        </span>
                                        <span *ngIf="mobileNumber.errors?.minlength">
                                            Contact Number should be of minimum
                                            {{contactInformationFormObject?.mobileNumberMinLength}} digits!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">2. Additional Number</label>
                                    <div class="input_box">
                                        <input type="text" name="phoneNumber" hint number-only
                                            [(ngModel)]="contactInformationFormObject.phoneNumber"
                                            #phoneNumber="ngModel"
                                            [identify-value-change]="contactInformationFormObject" class="focus_border"
                                            ng2TelInput [ng2TelInputOptions]="personalPhoneNumberOptions"
                                            (countryChange)="utils.setMobileNumberFields($event, contactInformationFormObject, ['phoneNumberCountryISOCode', 'phoneNumberDialCode', 'phoneNumberMinLength', 'phoneNumberMaxLength'])"
                                            (intlTelInputObject)="utils.setMobileNumberFields($event.s, contactInformationFormObject, ['phoneNumberCountryISOCode', 'phoneNumberDialCode', 'phoneNumberMinLength', 'phoneNumberMaxLength'])"
                                            [minlength]="contactInformationFormObject?.phoneNumberMinLength"
                                            [maxlength]="contactInformationFormObject?.phoneNumberMaxLength">
                                        <span class="tooltip"><span class='tooltipArrow'></span>If applicable, enter any
                                            secondary contact number where you can be contacted. If you don't have an
                                            additional number, leave this field blank.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && phoneNumber.invalid">
                                        <span *ngIf="phoneNumber.errors?.required">
                                            Additional Number is required!
                                        </span>
                                        <span *ngIf="phoneNumber.errors?.minlength">
                                            Additional Number should be of minimum
                                            {{contactInformationFormObject?.phoneNumberMinLength}} digits!
                                        </span>
                                    </span>
                                </div>

                            </div>

                            <h4 class="seprate">Current Address</h4>

                            <div class="grid-two">

                                <div class="form_box">
                                    <label class="fieldlabels">3. Address Line 1<span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="currentAddress1" hint address-directive
                                            [(ngModel)]="contactInformationFormObject.currentAddress1"
                                            #currentAddress1="ngModel"
                                            [identify-value-change]="contactInformationFormObject" class="effect"
                                            required="required" minlength="10" maxlength="200">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter the address
                                            where you currently reside, where you receive official documents.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && currentAddress1.invalid">
                                        <span *ngIf="currentAddress1.errors?.required">
                                            Address Line 1 is required!
                                        </span>
                                        <span *ngIf="currentAddress1.errors?.minlength">
                                            Address Line 1 should be of minimum 10 characters!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">4. Address Line 2</label>
                                    <div class="input_box">
                                        <input type="text" name="currentAddress2" hint address-directive
                                            [(ngModel)]="contactInformationFormObject.currentAddress2"
                                            #currentAddress2="ngModel"
                                            [identify-value-change]="contactInformationFormObject" class="effect"
                                            maxlength="200">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter the address
                                            where you currently reside, where you receive official documents.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && currentAddress2.invalid">
                                        <span *ngIf="currentAddress2.errors?.required">
                                            Address Line 2 is required!
                                        </span>
                                        <!-- <span *ngIf="currentAddress2.errors?.minlength">
                                            Address Line 2 should be of minimum 10 characters!
                                        </span> -->
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">5. Country <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <mat-select name="currentCountry"
                                            [(ngModel)]="contactInformationFormObject.currentCountry"
                                            #currentCountry="ngModel"
                                            (ngModelChange)="setCurrentAddressStateFieldType(true);"
                                            (openedChange)="utils.checkHasMatValueChanged($event, contactInformationFormObject, 'currentCountry');"
                                            class="effect" required="required">
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="countrySelectService.selectFilterControl"
                                                    noEntriesFoundLabel="No data found" placeholderLabel="Search"
                                                    autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option
                                                *ngFor="let country of countrySelectService.filteredOptions | async"
                                                [value]="country.value">{{country.text}}</mat-option>
                                        </mat-select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter the country
                                            where applicant is currently residing.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && currentCountry.invalid">
                                        <span *ngIf="currentCountry.errors?.required">
                                            Country is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">6. State/Region <span class="medatory">*</span></label>
                                    <ng-container *ngIf="currentAddressStateFieldType === constants.TEXT_FIELD">
                                        <div class="input_box">
                                            <input type="text" name="currentState" hint address-directive
                                                [(ngModel)]="contactInformationFormObject.currentState"
                                                #currentState="ngModel"
                                                [identify-value-change]="contactInformationFormObject" class="effect"
                                                required="required" minlength="2" maxlength="100"
                                                [pattern]="pattern.NAME_FIELD_PATTERN">
                                            <span class="tooltip"><span class='tooltipArrow'></span>Please choose the
                                                current state or location for contact purposes.</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger"
                                            *ngIf="contactInformationForm.submitted && currentState.invalid">
                                            <span *ngIf="currentState.errors?.required">
                                                State/Region is required!
                                            </span>
                                            <span *ngIf="currentState.errors?.minlength">
                                                State/Region should be of minimum 2 characters!
                                            </span>
                                            <span
                                                *ngIf="!currentState.errors?.minlength && currentState.errors?.pattern">
                                                Please enter valid State/Region Name!
                                            </span>
                                        </span>
                                    </ng-container>

                                    <ng-container *ngIf="currentAddressStateFieldType === constants.SELECT_FIELD">
                                        <div class="input_box">
                                            <mat-select name="currentState"
                                                [(ngModel)]="contactInformationFormObject.currentState"
                                                #currentState="ngModel"
                                                (openedChange)="utils.checkHasMatValueChanged($event, contactInformationFormObject, 'currentState');"
                                                class="effect" required="required">
                                                <mat-option>
                                                    <ngx-mat-select-search
                                                        [formControl]="currentAddressStateSelectService.selectFilterControl"
                                                        noEntriesFoundLabel="No data found" placeholderLabel="Search"
                                                        autocomplete="off"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option
                                                    *ngFor="let currentStateObject of currentAddressStateSelectService.filteredOptions | async"
                                                    [value]="currentStateObject.value">{{currentStateObject.text}}</mat-option>
                                            </mat-select>
                                            <span class="tooltip"><span class='tooltipArrow'></span>Choose your
                                                state.</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger"
                                            *ngIf="contactInformationForm.submitted && currentState.invalid">
                                            <span *ngIf="currentState.errors?.required">
                                                State/Region is required!
                                            </span>
                                        </span>
                                    </ng-container>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">7. City / Town <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="currentCity" hint
                                            [(ngModel)]="contactInformationFormObject.currentCity"
                                            #currentCity="ngModel"
                                            [identify-value-change]="contactInformationFormObject" class="effect"
                                            required="required" minlength="2" maxlength="60"
                                            [pattern]="pattern.COMMON_FIELD_PATTERN">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter the city of
                                            residence for contact purposes.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && currentCity.invalid">
                                        <span *ngIf="currentCity.errors?.required">
                                            City / Town is required!
                                        </span>
                                        <span *ngIf="currentCity.errors?.minlength">
                                            City / Town should be of minimum 2 characters!
                                        </span>
                                        <span *ngIf="!currentCity.errors?.minlength && currentCity.errors?.pattern">
                                            City / Town should start and end with an alphabet!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">8. Postal Code / Zip Code <span
                                            class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="currentPostalCode" hint
                                            [(ngModel)]="contactInformationFormObject.currentPostalCode"
                                            #currentPostalCode="ngModel"
                                            [identify-value-change]="contactInformationFormObject" class="effect"
                                            required="required" minlength="5" maxlength="7">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter the PIN/ZIP code
                                            of location for contact purposes</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && currentPostalCode.invalid">
                                        <span *ngIf="currentPostalCode.errors?.required">
                                            Postal Code / Zip Code is required!
                                        </span>
                                        <span *ngIf="currentPostalCode.errors?.minlength">
                                            Postal Code should be of minimum 5 digits!
                                        </span>
                                    </span>
                                </div>

                            </div>

                            <h4 class="seprate">Permanent Address in Nigeria</h4>

                            <div class="grid-two">

                                <div class="form_box">
                                    <label class="fieldlabels">9. State of Origin <span
                                            class="medatory">*</span></label>
                                    <div class="input_box">
                                        <mat-select name="permanentOriginState"
                                            [(ngModel)]="contactInformationFormObject.permanentOriginState"
                                            #permanentOriginState="ngModel"
                                            (openedChange)="utils.checkHasMatValueChanged($event, contactInformationFormObject, 'permanentOriginState');"
                                            class="effect" required="required">
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="stateSelectService.selectFilterControl"
                                                    noEntriesFoundLabel="No data found" placeholderLabel="Search"
                                                    autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateSelectService.filteredOptions | async"
                                                [value]="state.value">{{state.text}}</mat-option>
                                        </mat-select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>State of Origin is the
                                            name of the state where you were originally born or considered a permanent
                                            resident.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && permanentOriginState.invalid">
                                        <span *ngIf="permanentOriginState.errors?.required">
                                            State of Origin is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">10. Home Town <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="permanentHomeTown" hint
                                            [(ngModel)]="contactInformationFormObject.permanentHomeTown"
                                            #permanentHomeTown="ngModel"
                                            [identify-value-change]="contactInformationFormObject" class="effect"
                                            required="required" minlength="2" maxlength="60"
                                            [pattern]="pattern.COMMON_FIELD_PATTERN">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter the name of
                                            the town or village where you were born.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && permanentHomeTown.invalid">
                                        <span *ngIf="permanentHomeTown.errors?.required">
                                            Home Town is required!
                                        </span>
                                        <span *ngIf="permanentHomeTown.errors?.minlength">
                                            Home Town should be of minimum 2 characters!
                                        </span>
                                        <span
                                            *ngIf="!permanentHomeTown.errors?.minlength && permanentHomeTown.errors?.pattern">
                                            Home Town should start and end with an alphabet!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">11. Address Line 1<span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="permanentAddress1" hint address-directive
                                            [(ngModel)]="contactInformationFormObject.permanentAddress1"
                                            #permanentAddress1="ngModel"
                                            [identify-value-change]="contactInformationFormObject" class="effect"
                                            required="required" autocomplete="off" minlength="10" maxlength="200">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter the
                                            permanent residence of Nigeria. This should be applicant's primary
                                            residence.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && permanentAddress1.invalid">
                                        <span *ngIf="permanentAddress1.errors?.required">
                                            Address Line 1 is required!
                                        </span>
                                        <span *ngIf="permanentAddress1.errors?.minlength">
                                            Address Line 1 should be of minimum 10 characters!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">12. Address Line 2</label>
                                    <div class="input_box">
                                        <input type="text" name="permanentAddress2" hint address-directive
                                            [(ngModel)]="contactInformationFormObject.permanentAddress2"
                                            #permanentAddress2="ngModel"
                                            [identify-value-change]="contactInformationFormObject" class="effect"
                                            autocomplete="off" maxlength="200">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter the
                                            permanent residence of Nigeria. This should be applicant's primary
                                            residence.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && permanentAddress2.invalid">
                                        <span *ngIf="permanentAddress2.errors?.required">
                                            Address Line 2 is required!
                                        </span>
                                        <!-- <span *ngIf="permanentAddress2.errors?.minlength">
                                            Address Line 2 should be of minimum 10 characters!
                                        </span> -->
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">13. State/Region <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <mat-select name="permanentState"
                                            [(ngModel)]="contactInformationFormObject.permanentState"
                                            #permanentState="ngModel"
                                            (ngModelChange)="fetchStateLGA(contactInformationFormObject, 'permanentState', permanentLGASelectService, 'permanentLga', true);"
                                            (openedChange)="utils.checkHasMatValueChanged($event, contactInformationFormObject, 'permanentState');"
                                            class="effect" required="required">
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="stateSelectService.selectFilterControl"
                                                    noEntriesFoundLabel="No data found" placeholderLabel="Search"
                                                    autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateSelectService.filteredOptions | async"
                                                [value]="state.value">{{state.text}}</mat-option>
                                        </mat-select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please choose the
                                            nigerian state or location for contact purposes.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && permanentState.invalid">
                                        <span *ngIf="permanentState.errors?.required">
                                            State/Region is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">14. LGA <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <mat-select name="permanentLga" class="effect" required="required"
                                            [(ngModel)]="contactInformationFormObject.permanentLga"
                                            #permanentLga="ngModel"
                                            (openedChange)="utils.checkHasMatValueChanged($event, contactInformationFormObject, 'permanentLga');">
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="permanentLGASelectService.selectFilterControl"
                                                    noEntriesFoundLabel="No data found" placeholderLabel="Search"
                                                    autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option
                                                *ngFor="let lga of permanentLGASelectService.filteredOptions | async"
                                                [value]="lga.value">{{lga.text}}</mat-option>
                                        </mat-select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please choose the name
                                            of LGA accurately according to the official documents such as
                                            government-issued ID or utility bills.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && permanentLga.invalid">
                                        <span *ngIf="permanentLga.errors?.required">
                                            LGA is required!
                                        </span>
                                    </span>
                                </div>


                                <div class="form_box">
                                    <label class="fieldlabels">15. City / Town <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="permanentCity" hint
                                            [(ngModel)]="contactInformationFormObject.permanentCity"
                                            #permanentCity="ngModel"
                                            [identify-value-change]="contactInformationFormObject" class="effect"
                                            required="required" minlength="2" maxlength="60"
                                            [pattern]="pattern.COMMON_FIELD_PATTERN">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter the city of
                                            residence for contact purposes.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && permanentCity.invalid">
                                        <span *ngIf="permanentCity.errors?.required">
                                            City / Town is required!
                                        </span>
                                        <span *ngIf="permanentCity.errors?.minlength">
                                            City / Town should be of minimum 2 characters!
                                        </span>
                                        <span *ngIf="!permanentCity.errors?.minlength && permanentCity.errors?.pattern">
                                            City / Town should start and end with an alphabet!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">16. Postal Code </label>
                                    <div class="input_box">
                                        <input type="text" name="permanentPostalCode" hint number-only
                                            [(ngModel)]="contactInformationFormObject.permanentPostalCode"
                                            #permanentPostalCode="ngModel"
                                            [identify-value-change]="contactInformationFormObject" class="effect"
                                            minlength="6" maxlength="6">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter the PIN code of
                                            location for contact purposes</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="contactInformationForm.submitted && permanentPostalCode.invalid">
                                        <span *ngIf="permanentPostalCode.errors.required">
                                            Postal Code is required!
                                        </span>
                                        <span *ngIf="permanentPostalCode.errors?.minlength">
                                            Postal Code should be of minimum 6 digits!
                                        </span>
                                    </span>
                                </div>

                            </div>

                        </div>

                        <div class="grid-onethird-box upload_inst">

                            <div class="element mt20 none_dis">

                                <h4>Instructions</h4>

                                <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                            </div>

                        </div>

                    </div>

                    <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn mt20"
                        value="Previous Page" />

                    <input type="submit" (click)="setShallExitApplication(true)" class="save_btn" value="Save & Exit" />

                    <input type="submit" (click)="setShallExitApplication(false)" class="action-button custom_btn"
                        value="Continue" />

                </form>

            </fieldset>

            <fieldset *ngIf="applicationStage === constants.NEXT_OF_KIN_INFO_STEP">

                <div class="step_info">
                    <span>Step {{constants.NEXT_OF_KIN_INFO_STEP}}:
                        {{applicationStepsList[constants.NEXT_OF_KIN_INFO_STEP - 1].label}}</span>
                </div>

                <form #kinInformationForm="ngForm"
                    (ngSubmit)="kinInformationForm.form.valid && menuClickedStage = this.constants.ANY && openSupportingDocumentsForm($event)"
                    name="kinInformationForm" autocomplete="off" id="msform" error-focus>

                    <input type="hidden" name="hasFieldValueChanged"
                        [(ngModel)]="kinInformationFormObject.hasFieldValueChanged" #hasFieldValueChanged="ngModel">

                    <input type="hidden" name="callableFunctionName"
                        [(ngModel)]="kinInformationFormObject.callableFunctionName" #callableFunctionName="ngModel">

                    <ng-template #instructions>

                        <ul>
                            <li><span>You are required to enter a valid next of kin contact information.</span></li>

                            <li><span>Enter a valid Contact phone number in the format +234-1234567891.</span></li>

                            <li><span>All fields that contain the Asterisk (*) sign are mandatory and must be
                                    completed.</span></li>
                        </ul>

                    </ng-template>

                    <div class="grid-one-third">

                        <div class="grid-onethird-box">

                            <details>

                                <summary>Instructions for Applicant </summary>

                                <div class="content">

                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                                </div>

                            </details>

                            <div class="grid-two">

                                <div class="form_box ">
                                    <label class="fieldlabels">1. Name <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="kinName" hint name-with-number-directive
                                            [(ngModel)]="kinInformationFormObject.kinName" #kinName="ngModel"
                                            [identify-value-change]="kinInformationFormObject" class="effect"
                                            required="required" minlength="2" maxlength="30"
                                            [pattern]="pattern.COMMON_FIELD_PATTERN">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please provide the full
                                            name of a trusted contact who will serve as applicant's witness for the
                                            passport application.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="kinInformationForm.submitted && kinName.invalid">
                                        <span *ngIf="kinName.errors?.required">
                                            Name of Next of Kin is required!
                                        </span>
                                        <span *ngIf="kinName.errors?.minlength">
                                            Name of Next of Kin should be of minimum 2 characters!
                                        </span>
                                        <span *ngIf="!kinName.errors?.minlength && kinName.errors?.pattern">
                                            Name of Next of Kin should start and end with an alphabet!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">2. Relationship <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <select name="relationship" [(ngModel)]="kinInformationFormObject.relationship"
                                            #relationship="ngModel" [identify-value-change]="kinInformationFormObject"
                                            class="effect" required="required">
                                            <option [value]="constants.BLANK">{{constants.DEFAULT_SELECT_OPTION_LABEL}}
                                            </option>
                                            <option [value]="constants.BROTHER">Brother</option>
                                            <option [value]="constants.DAUGHTER">Daughter</option>
                                            <option [value]="constants.FATHER">Father</option>
                                            <option [value]="constants.FRIEND">Friend</option>
                                            <option [value]="constants.MOTHER">Mother</option>
                                            <option [value]="constants.RELATIVE">Relative</option>
                                            <option [value]="constants.SISTER">Sister</option>
                                            <option [value]="constants.SON">Son</option>
                                            <option [value]="constants.SPOUSE">Spouse </option>
                                        </select>
                                        <span class="icon_caret"><i class="fa fa-caret-down"
                                                aria-hidden="true"></i></span>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Select the relationship
                                            that best describes applicant's connection to next of kin.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="kinInformationForm.submitted && relationship.invalid">
                                        <span *ngIf="relationship.errors?.required">
                                            Relationship is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box h_txt height-form">
                                    <label class="fieldlabels">3. Address Line 1<span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="permanentAddress1" hint address-directive
                                            [(ngModel)]="kinInformationFormObject.permanentAddress1"
                                            #permanentAddress1="ngModel"
                                            [identify-value-change]="kinInformationFormObject" class="effect"
                                            required="required" minlength="10" maxlength="200">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please provide the
                                            address of next of kin which includes the house number, street name and
                                            locality.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="kinInformationForm.submitted && permanentAddress1.invalid">
                                        <span *ngIf="permanentAddress1.errors?.required">
                                            Address Line 1 is required!
                                        </span>
                                        <span *ngIf="permanentAddress1.errors?.minlength">
                                            Address Line 1 should minimum 10 characters!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">4. Address Line 2</label>
                                    <div class="input_box">
                                        <input type="text" name="permanentAddress2" hint address-directive
                                            [(ngModel)]="kinInformationFormObject.permanentAddress2"
                                            #permanentAddress2="ngModel"
                                            [identify-value-change]="kinInformationFormObject" class="effect"
                                            maxlength="200">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please write the valid
                                            address.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="kinInformationForm.submitted && permanentAddress2.invalid">
                                        <span *ngIf="permanentAddress2.errors?.required">
                                            Address Line 2 is required!
                                        </span>
                                        <!-- <span *ngIf="permanentAddress2.errors?.minlength">
                                            Address Line 2 should minimum 10 characters!
                                        </span> -->
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">5. Country <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <mat-select name="permanentCountry"
                                            [(ngModel)]="kinInformationFormObject.permanentCountry"
                                            #permanentCountry="ngModel"
                                            (openedChange)="utils.checkHasMatValueChanged($event, kinInformationFormObject, 'permanentCountry');"
                                            (ngModelChange)="setCountryCode(kinInformationFormObject.permanentCountry);"
                                            class="effect" required="required">
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="countrySelectService.selectFilterControl"
                                                    noEntriesFoundLabel="No data found" placeholderLabel="Search"
                                                    autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option
                                                *ngFor="let country of countrySelectService.filteredOptions | async"
                                                [value]="country.value">{{country.text}}</mat-option>
                                        </mat-select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please provide the
                                            current state or location of applicant's next of kin for contact
                                            purposes.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="kinInformationForm.submitted && permanentCountry.invalid">
                                        <span *ngIf="permanentCountry.errors?.required">
                                            Country is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">6. State <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="permanentState" hint
                                            [(ngModel)]="kinInformationFormObject.permanentState"
                                            #permanentState="ngModel" [identify-value-change]="kinInformationFormObject"
                                            class="effect" required="required" minlength="2" maxlength="100"
                                            [pattern]="pattern.NAME_FIELD_PATTERN">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter the name of LGA
                                            accurately according to the official documents such as government-issued ID
                                            or utility bills.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="kinInformationForm.submitted && permanentState.invalid">
                                        <span *ngIf="permanentState.errors?.required">
                                            State is required!
                                        </span>
                                        <span *ngIf="permanentState.errors?.pattern">
                                            Please enter valid State Name!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">7. City / Town <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="permanentCity" hint
                                            [(ngModel)]="kinInformationFormObject.permanentCity"
                                            #permanentCity="ngModel" [identify-value-change]="kinInformationFormObject"
                                            class="effect" required="required" minlength="2" maxlength="60"
                                            [pattern]="pattern.COMMON_FIELD_PATTERN">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter the city of
                                            applicant's next of kin's residence for contact purposes.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="kinInformationForm.submitted && permanentCity.invalid">
                                        <span *ngIf="permanentCity.errors?.required">
                                            City / Town is required!
                                        </span>
                                        <span *ngIf="permanentCity.errors?.minlength">
                                            City / Town should be of minimum 2 characters!
                                        </span>
                                        <span *ngIf="!permanentCity.errors?.minlength && permanentCity.errors?.pattern">
                                            City / Town should start and end with an alphabet!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">8. Postal Code </label>
                                    <div class="input_box">
                                        <input type="text" name="permanentPostalCode" hint number-only
                                            [(ngModel)]="kinInformationFormObject.permanentPostalCode"
                                            #permanentPostalCode="ngModel"
                                            [identify-value-change]="kinInformationFormObject" class="effect"
                                            minlength="6" maxlength="6">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter the PIN/ZIP code
                                            of next of kin's location for contact purposes.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="kinInformationForm.submitted && permanentPostalCode.invalid">
                                        <span *ngIf="permanentPostalCode.errors.required">
                                            Postal Code is required!
                                        </span>
                                        <span *ngIf="permanentPostalCode.errors?.minlength">
                                            Postal Code should be of minimum 6 digits!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">9. Contact Number <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="mobileNumber" hint number-only
                                            [(ngModel)]="kinInformationFormObject.mobileNumber" #mobileNumber="ngModel"
                                            [identify-value-change]="kinInformationFormObject" class="focus_border"
                                            required="required" autocomplete="off" ng2TelInput
                                            [ng2TelInputOptions]="kinMobileNumberOptions"
                                            (countryChange)="utils.setMobileNumberFields($event, kinInformationFormObject, ['mobileNumberCountryISOCode', 'mobileNumberDialCode', 'mobileNumberMinLength', 'mobileNumberMaxLength'])"
                                            (intlTelInputObject)="kinMobileNumberTelObj = $event; utils.setMobileNumberFields($event.s, kinInformationFormObject, ['mobileNumberCountryISOCode', 'mobileNumberDialCode', 'mobileNumberMinLength', 'mobileNumberMaxLength'])"
                                            [minlength]="kinInformationFormObject?.mobileNumberMinLength"
                                            [maxlength]="kinInformationFormObject?.mobileNumberMaxLength">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter a valid
                                            phone number of kin where he/she can be reached easily.</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger"
                                        *ngIf="kinInformationForm.submitted && mobileNumber.invalid">
                                        <span *ngIf="mobileNumber.errors?.required">
                                            Contact Number is required!
                                        </span>
                                        <span *ngIf="mobileNumber.errors?.minlength">
                                            Contact Number should be of minimum
                                            {{kinInformationFormObject?.mobileNumberMinLength}} digits!
                                        </span>
                                    </span>
                                </div>

                            </div>

                        </div>

                        <div class="grid-onethird-box upload_inst">

                            <div class="element mt20 none_dis">

                                <h4>Instructions</h4>

                                <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                            </div>

                        </div>

                    </div>

                    <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn mt20"
                        value="Previous Page" />
                    <input type="submit" (click)="setShallExitApplication(true)" class="save_btn" value="Save & Exit" />
                    <input type="submit" (click)="setShallExitApplication(false)" class="action-button custom_btn"
                        value="Continue" />

                </form>

            </fieldset>

            <fieldset *ngIf="applicationStage === constants.DOCUMENTS_UPLOADED_STAGE" class="support_document">

                <div class="step_info">
                    <span>Step {{constants.DOCUMENTS_UPLOADED_STAGE}}:
                        {{applicationStepsList[constants.DOCUMENTS_UPLOADED_STAGE - 1].label}}</span>
                </div>

                <form #supportingDocumentsForm="ngForm"
                    (ngSubmit)="supportingDocumentsForm.form.valid && menuClickedStage = this.constants.ANY && openPassportDeliveryForm($event)"
                    autocomplete="off" name="supportingDocumentsForm" id="msform" appFocus>

                    <input type="hidden" name="hasFieldValueChanged"
                        [(ngModel)]="supportingDocumentsFormObject.hasFieldValueChanged"
                        #hasFieldValueChanged="ngModel">

                    <input type="hidden" name="callableFunctionName"
                        [(ngModel)]="supportingDocumentsFormObject.callableFunctionName"
                        #callableFunctionName="ngModel">

                    <ng-template #instructions>

                        <ul>

                            <li><span>Please upload all the required documents as displayed here for the processing of
                                    the Passport application</span></li>

                            <li><span>All fields that contain the Asterisk (*) sign are mandatory and must be
                                    completed.</span></li>

                        </ul>

                    </ng-template>

                    <div class="mb10">
                        <span style="color:red; font-weight: 600;">PLEASE ENSURE UPLOADED DOCUMENTS ARE CORRECT &
                            PROPERLY READABLE TO AVOID REJECTION</span>
                    </div>

                    <div class="grid-one-third">

                        <div class="grid-onethird-box">

                            <details>

                                <summary>Instructions for Applicant </summary>

                                <div class="content">

                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                                </div>

                            </details>

                            <ng-container *ngFor="let applicableDocSectionObject of applicableDocsList;">

                                <table class="upload-support-doc-table">

                                    <thead>
                                        <tr>
                                            <th class="personal-head" colspan="2">
                                                {{applicableDocSectionObject.groupTypeLabel}}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            *ngFor="let applicableDocObject of applicableDocSectionObject.groupDocs; let i = index;">
                                            <td class="upload-support-doc">
                                                {{(i+1)}}. {{applicableDocObject.docTypeLabel}}&nbsp;<span
                                                    *ngIf="applicableDocObject.isMandatory === constants.TRUE"
                                                    class="medatory">*</span>

                                                <ng-container
                                                    *ngIf="applicableDocObject.docType === constants.GUARANTOR_FORM">

                                                    <span class="book_center"
                                                        style="float: none; width: auto;  font-size: 14px;"> (<a
                                                            [routerLink]="['/guarantor']" target="_blank"
                                                            rel="noopener noreferrer" style="color: #0087ff;">Click
                                                            here</a> to Download Guarantor's Form)</span>

                                                </ng-container>

                                                <input type="hidden"
                                                    [name]="'base64Data'+applicableDocObject.applicantDocId"
                                                    [required]="applicableDocObject.isMandatory === constants.TRUE"
                                                    [(ngModel)]="applicableDocObject.base64Data" #base64Data="ngModel">

                                                <span class="alert alert-danger" role="alert"
                                                    *ngIf="supportingDocumentsForm.submitted && base64Data.invalid">
                                                    <span *ngIf="base64Data.errors.required">
                                                        {{applicableDocObject.docTypeLabel}} is required!
                                                    </span>
                                                </span>
                                            </td>

                                            <td class="actionable upload-support-doc-btn">
                                                <ng-container *ngIf="applicableDocObject.isDocUploaded === false">

                                                    <span (click)="openFileUploadModal(applicableDocObject);"
                                                        class="upload">Upload</span>

                                                </ng-container>

                                                <ng-container *ngIf="applicableDocObject.isDocUploaded === true">

                                                    <span class="check"><i class="fa fa-check-circle"
                                                            aria-hidden="true"></i></span>

                                                    <span (click)="openFilePreviewModal(applicableDocObject);"
                                                        class="preview">Preview</span>

                                                    <span (click)="deleteUploadedFile(applicableDocObject);"
                                                        class="delete">Delete</span>

                                                </ng-container>

                                            </td>
                                        </tr>
                                    </tbody>

                                </table>

                            </ng-container>

                            <label class="t_and_c  pb10 pointer">
                                <input type="checkbox" name="termCondition"
                                    [(ngModel)]="supportingDocumentsFormObject.isTermConditionSelect"
                                    #termCondition="ngModel" required="required">
                                <span style="padding:0 3px;"> I hereby confirm that all the documents I have provided
                                    are genuine & properly readable.</span>
                                <span class="alert alert-danger" role="alert"
                                    *ngIf="supportingDocumentsForm.submitted && termCondition.invalid">
                                    <span *ngIf="termCondition.errors.required">
                                        Confirmation is required
                                    </span>
                                </span>
                            </label>

                        </div>

                        <div class="grid-onethird-box upload_inst">

                            <div class="element mt20 none_dis">

                                <h4>Instructions</h4>

                                <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                            </div>

                        </div>

                    </div>

                    <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn"
                        value="Previous Page" />

                    <input type="submit" (click)="setShallExitApplication(true)" class="save_btn" value="Save & Exit" />

                    <input type="submit" (click)="setShallExitApplication(false)" class="action-button custom_btn"
                        value="Continue" />

                </form>

            </fieldset>

            <fieldset
                *ngIf="applicationStage === constants.PASSPORT_DELIVERY_STAGE && enablePassportDeliveryStage === true"
                class="passport_delivery">

                <div class="step_info">
                    <span>Step {{constants.PASSPORT_DELIVERY_STAGE}}:
                        {{applicationStepsList[constants.PASSPORT_DELIVERY_STAGE - 1].label}}</span>
                </div>

                <form #passportDeliveryForm="ngForm"
                    (ngSubmit)="passportDeliveryForm.form.valid && menuClickedStage = this.constants.ANY && openApplicationPreview($event)"
                    autocomplete="off" name="passportDeliveryForm" id="msform" appFocus>

                    <input type="hidden" name="hasFieldValueChanged"
                        [(ngModel)]="passportDeliveryFormObject.hasFieldValueChanged" #hasFieldValueChanged="ngModel">

                    <input type="hidden" name="callableFunctionName"
                        [(ngModel)]="passportDeliveryFormObject.callableFunctionName" #callableFunctionName="ngModel">

                    <!-- <div *ngIf="isDeliveryAvailable === constants.FALSE">
                        <h3 style="color: red;">NOTE: Passport delivery is not available for the selected processing country.</h3>
                    </div> -->

                    <div>
                        <h4>How would you like to receive your passport?</h4>
                        <div class="radio-grid-wrap">
                            <div class="radio-grid">
                                <label class="flex_row_passport_delivery radio-span pointer">
                                    <input type="radio" name="deliveryOpted"
                                        [identify-value-change]="passportDeliveryFormObject" #deliveryOpted="ngModel"
                                        [(ngModel)]="passportDeliveryFormObject.deliveryOpted" [value]="constants.FALSE"
                                        class="image_upload_radio" required="required">
                                    <span>Pick up your passport at the Immigration Processing Centre.</span>
                                </label>
                            </div>

                            <div *ngIf="isDeliveryAvailable === constants.TRUE" class="radio-grid">
                                <label class="flex_row_passport_delivery radio-span pointer">
                                    <input type="radio" name="deliveryOpted"
                                        [identify-value-change]="passportDeliveryFormObject" #deliveryOpted="ngModel"
                                        [(ngModel)]="passportDeliveryFormObject.deliveryOpted" [value]="constants.TRUE"
                                        (ngModelChange)="setDeliveryPhoneInitialCountry(); fetchPassportFeeDetails()"
                                        class="image_upload_radio" required="required">
                                    <span>Courier Service (Additional Fee will be charged).</span>
                                </label>
                            </div>

                            <span class="alert alert-danger" role="alert"
                                *ngIf="passportDeliveryForm.submitted && deliveryOpted.invalid">
                                <span *ngIf="deliveryOpted.errors.required">
                                    Please select any option for Passport Delivery!
                                </span>
                            </span>
                        </div>
                    </div>

                    <ng-container
                        *ngIf="isDeliveryAvailable === constants.TRUE && passportDeliveryFormObject.deliveryOpted === constants.TRUE">

                        <ng-template #instructions>

                            <ul>

                                <li><span>You are required to enter a valid receiver's information related to your
                                        passport delivery.</span></li>

                                <li>The delivery fee is calculated based on the <b>Country</b> chosen here.</li>

                                <li><span>Enter a valid contact number in the format +234-1234567891.</span></li>

                                <li>All fields that contain the Asterisk (*) sign are mandatory and must be completed.
                                </li>

                            </ul>

                        </ng-template>

                        <div class="grid-one-third">

                            <div class="grid-onethird-box">

                                <details>

                                    <summary>Instructions for Applicant </summary>

                                    <div class="content">

                                        <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                                    </div>

                                </details>

                                <div class="grid-two">

                                    <div class="form_box">
                                        <label class="fieldlabels">1. Name of Receiver<span
                                                class="medatory">*</span></label>
                                        <div class="input_box">
                                            <input type="text" name="receiverName" hint
                                                [identify-value-change]="passportDeliveryFormObject"
                                                name-with-number-directive class="effect" required="required"
                                                autocomplete="off" [(ngModel)]="passportDeliveryFormObject.receiverName"
                                                #receiverName="ngModel" minlength="2" maxlength="30"
                                                pattern="^[A-Za-z].*[A-Za-z0-9]$">
                                            <span class="tooltip"><span class='tooltipArrow'></span>Please enter the
                                                name of the individual authorized to receive your passport.</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportDeliveryForm.submitted && receiverName.invalid">
                                            <span *ngIf="receiverName.errors.required">
                                                Name of Receiver is required!
                                            </span>
                                            <span *ngIf="receiverName.errors?.minlength">
                                                Name of Receiver should be of minimum 2 characters!
                                            </span>
                                            <span
                                                *ngIf="!receiverName.errors?.minlength && receiverName.errors.pattern">
                                                Name of Receiver should start and end with alphabet!
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box">
                                        <label class="fieldlabels">2. Receiver's E-mail Address <span
                                                class="medatory">*</span></label>
                                        <div class="input_box">
                                            <input type="email" name="emailId" hint
                                                [identify-value-change]="passportDeliveryFormObject"
                                                [(ngModel)]="passportDeliveryFormObject.emailId" #emailId="ngModel"
                                                class="effect" maxlength="100" required="required"
                                                [pattern]="pattern.EMAIL_FIELD_PATTERN">
                                            <span class="tooltip"><span class='tooltipArrow'></span>Please provide a
                                                valid email address of receiver in the format yourname@example.com. This
                                                email will serve as your primary point of contact regarding your
                                                passport delivery.</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportDeliveryForm.submitted && emailId.invalid">
                                            <span *ngIf="emailId.errors.required">
                                                Receiver's E-mail Address is required!
                                            </span>
                                            <span *ngIf="emailId.errors.pattern">
                                                Receiver's E-mail Address should be valid!
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box">
                                        <label class="fieldlabels">3. Receiver's Contact Number <span
                                                class="medatory">*</span></label>
                                        <div class="input_box">
                                            <input type="text" name="mobileNumber" number-only hint
                                                [identify-value-change]="passportDeliveryFormObject" blockCopyPaste
                                                numberOnly [validate-phone]="['mobileNumber', 'Contact Number']"
                                                [(ngModel)]="passportDeliveryFormObject.mobileNumber"
                                                #mobileNumber="ngModel" class="focus_border" required="required"
                                                autocomplete="off" minlength="8" maxlength="11" ng2TelInput
                                                [ng2TelInputOptions]="passportDeliveryMobileNumberOptions"
                                                (intlTelInputObject)="utils.setMobileNumberFields($event.s, passportDeliveryFormObject, ['mobileNumberCountryISOCode', 'mobileNumberDialCode'])">
                                            <span class="tooltip"><span class='tooltipArrow'></span>Please enter a valid
                                                contact number of receiver where he/she can be reached easily. </span>
                                            <span class="focus-border"><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportDeliveryForm.submitted && mobileNumber.invalid">
                                            <span *ngIf="mobileNumber.errors.required">
                                                Receiver's Contact Number is required!
                                            </span>
                                            <span *ngIf="mobileNumber.errors?.minlength">
                                                Receiver's Contact Number should be of minimum 8 digits!
                                            </span>
                                            <span
                                                *ngIf="!mobileNumber.errors?.minlength && mobileNumber.errors?.hasError">
                                                {{mobileNumber.errors.errorMessage}}
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box">
                                        <label class="fieldlabels">4. Alternate Phone Number</label>
                                        <div class="input_box">
                                            <input type="text" name="phoneNumber" number-only hint
                                                [identify-value-change]="passportDeliveryFormObject" blockCopyPaste
                                                numberOnly [validate-phone]="['phoneNumber', 'Additional Number']"
                                                [(ngModel)]="passportDeliveryFormObject.phoneNumber"
                                                #phoneNumber="ngModel" class="focus_border" autocomplete="off"
                                                minlength="8" maxlength="11" ng2TelInput
                                                [ng2TelInputOptions]="passportDeliveryPhoneNumberOptions"
                                                (countryChange)="utils.setMobileNumberFields($event, passportDeliveryFormObject, ['phoneNumberCountryISOCode', 'phoneNumberDialCode'])"
                                                (intlTelInputObject)="utils.setMobileNumberFields($event.s, passportDeliveryFormObject, ['phoneNumberCountryISOCode', 'phoneNumberDialCode'])">
                                            <span class="tooltip"><span class='tooltipArrow'></span>If applicable, enter
                                                any secondary contact number where receiver can be contacted. If you
                                                don't have an additional number, leave this field blank.</span>
                                            <span class="focus-border"><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportDeliveryForm.submitted && phoneNumber.invalid">
                                            <span *ngIf="phoneNumber.errors.required">
                                                Alternate Phone Number is required!
                                            </span>
                                            <span *ngIf="phoneNumber.errors?.minlength">
                                                Alternate Phone Number should be of minimum 8 digits!
                                            </span>
                                            <span
                                                *ngIf="!phoneNumber.errors?.minlength && phoneNumber.errors?.hasError">
                                                {{phoneNumber.errors.errorMessage}}
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box">
                                        <label class="fieldlabels">5. Receiver's Address Line 1<span
                                                class="medatory">*</span></label>
                                        <div class="input_box">
                                            <input type="text" name="receiverAddressLine1" hint
                                                [identify-value-change]="passportDeliveryFormObject" address-directive
                                                class="effect" required="required" autocomplete="off"
                                                [(ngModel)]="passportDeliveryFormObject.receiverAddressLine1"
                                                #receiverAddressLine1="ngModel" minlength="10" maxlength="150">
                                            <span class="tooltip"><span class='tooltipArrow'></span>Please enter the
                                                address where receiver permanently reside.</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportDeliveryForm.submitted && receiverAddressLine1.invalid">
                                            <span *ngIf="receiverAddressLine1.errors.required">
                                                Receiver's Address Line 1 is required!
                                            </span>
                                            <span *ngIf="receiverAddressLine1.errors?.minlength">
                                                Receiver's Address Line 1 should be of minimum 10 characters!
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box">
                                        <label class="fieldlabels">6. Receiver's Address Line 2</label>
                                        <div class="input_box">
                                            <input type="text" name="receiverAddressLine2" hint
                                                [identify-value-change]="passportDeliveryFormObject" address-directive
                                                class="effect" autocomplete="off"
                                                [(ngModel)]="passportDeliveryFormObject.receiverAddressLine2"
                                                #receiverAddressLine2="ngModel" minlength="10" maxlength="150">
                                            <span class="tooltip"><span class='tooltipArrow'></span>Please enter the
                                                address where receiver permanently reside.</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportDeliveryForm.submitted && receiverAddressLine2.invalid">
                                            <span *ngIf="receiverAddressLine2.errors.required">
                                                Receiver's Address Line 2 is required!
                                            </span>
                                            <span *ngIf="receiverAddressLine2.errors?.minlength">
                                                Receiver's Address Line 2 should be of minimum 10 characters!!
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box">
                                        <label class="fieldlabels">7. Country<span class="medatory">*</span></label>
                                        <div class="input_box">
                                            <input type="text" name="receiverAddressCountry" hint
                                                [identify-value-change]="passportDeliveryFormObject"
                                                [(ngModel)]="passportDeliveryFormObject.receiverAddressCountry"
                                                #receiverAddressCountry="ngModel" class="effect" required="required"
                                                disabled="disabled">
                                            <span class="tooltip"><span class='tooltipArrow'></span>Please provide the
                                                Country for passport delivery.</span>
                                            <span class="focus-border"><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportDeliveryForm.submitted && receiverAddressCountry.invalid">
                                            <span *ngIf="receiverAddressCountry.errors.required">
                                                Country is required!
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box">
                                        <label class="fieldlabels">8. State <span class="medatory">*</span></label>
                                        <div class="input_box">
                                            <input type="text" name="receiverAddressState" hint
                                                [(ngModel)]="passportDeliveryFormObject.receiverAddressState"
                                                #receiverAddressState="ngModel"
                                                [identify-value-change]="passportDeliveryFormObject" class="effect"
                                                required="required" minlength="2" maxlength="100"
                                                [pattern]="pattern.NAME_FIELD_PATTERN">
                                            <span class="tooltip"><span class='tooltipArrow'></span>Please provide the
                                                current state or location for passport delivery. </span>
                                            <span class="focus-border"><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportDeliveryForm.submitted && receiverAddressState.invalid">
                                            <span *ngIf="receiverAddressState.errors.required">
                                                State is required!
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box">
                                        <label class="fieldlabels">9. Town<span class="medatory">*</span></label>
                                        <div class="input_box">
                                            <input type="text" name="receiverAddressLga" hint
                                                [identify-value-change]="passportDeliveryFormObject" address-directive
                                                class="effect" required="required" autocomplete="off"
                                                [(ngModel)]="passportDeliveryFormObject.receiverAddressLga"
                                                #receiverAddressLga="ngModel" minlength="2" maxlength="150">
                                            <span class="tooltip"><span class='tooltipArrow'></span>A town is a locality
                                                within the subdivision where you reside.</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportDeliveryForm.submitted && receiverAddressLga.invalid">
                                            <span *ngIf="receiverAddressLga.errors.required">
                                                Town is required!
                                            </span>
                                            <span *ngIf="receiverAddressLga.errors?.minlength">
                                                Town should be of minimum 2 characters!
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box">
                                        <label class="fieldlabels">10. Landmark<span class="medatory">*</span></label>
                                        <div class="input_box">
                                            <input type="text" name="receiverAddressLandmark" hint
                                                [identify-value-change]="passportDeliveryFormObject" address-directive
                                                class="effect" required="required" autocomplete="off"
                                                [(ngModel)]="passportDeliveryFormObject.receiverAddressLandmark"
                                                #receiverAddressLandmark="ngModel" minlength="10" maxlength="150">
                                            <span class="tooltip"><span class='tooltipArrow'></span>Mention a
                                                significant nearby landmark to assist in locating the
                                                destination.</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportDeliveryForm.submitted && receiverAddressLandmark.invalid">
                                            <span *ngIf="receiverAddressLandmark.errors.required">
                                                Landmark is required!
                                            </span>
                                            <span *ngIf="receiverAddressLandmark.errors?.minlength">
                                                Landmark should be of minimum 10 characters!
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box">
                                        <label class="fieldlabels">11. Postal Code</label>
                                        <div class="input_box">
                                            <input type="text" name="receiverAddressPostalCode" hint
                                                [identify-value-change]="passportDeliveryFormObject" numberOnly
                                                class="effect" autocomplete="off"
                                                [(ngModel)]="passportDeliveryFormObject.receiverAddressPostalCode"
                                                #receiverAddressPostalCode="ngModel" minlength="6" maxlength="6">
                                            <span class="tooltip"><span class='tooltipArrow'></span>Enter the PIN code
                                                of the location for passport delivery</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert"
                                            *ngIf="passportDeliveryForm.submitted && receiverAddressPostalCode.invalid">
                                            <span *ngIf="receiverAddressPostalCode.errors.required">
                                                Postal Code is required!
                                            </span>
                                            <span *ngIf="receiverAddressPostalCode.errors?.minlength">
                                                Postal Code should be of minimum 6 digits!
                                            </span>
                                        </span>
                                    </div>

                                </div>

                                <label class="t_and_c pointer">
                                    <input type="checkbox" name="termCondition"
                                        [(ngModel)]="passportDeliveryFormObject.isTermConditionSelect"
                                        #termCondition="ngModel" required="required">
                                    <span style="padding:0 3px;"> I have read and agreed to the <a
                                            [href]='passportDeliveryTermsLink' target="_blank"
                                            style="color: #0087ff;text-decoration: underline;">Terms and
                                            Conditions</a>.</span>
                                    <span class="alert alert-danger" role="alert"
                                        *ngIf="passportDeliveryForm.submitted && termCondition.invalid">
                                        <span *ngIf="termCondition.errors.required">
                                            Confirmation is required
                                        </span>
                                    </span>
                                </label>

                            </div>

                            <div class="grid-onethird-box upload_inst">

                                <div *ngIf="isValidDetails(passportDeliveryFormObject, ['receiverName', 'emailId', 'mobileNumber', 'receiverAddressLine1', 'receiverAddressState', 'receiverAddressLga', 'receiverAddressLandmark']) === false"
                                    class="element mt20 none_dis">
                                    <h4>Instructions</h4>
                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                                </div>

                                <div *ngIf="isValidDetails(passportDeliveryFormObject, ['receiverName', 'emailId', 'mobileNumber', 'receiverAddressLine1', 'receiverAddressState', 'receiverAddressLga', 'receiverAddressLandmark']) === true"
                                    class="grid-box">
                                    <div class="fee">
                                        <div *ngIf="feeDetailsObject.codFee > 0">COD Fee:
                                            <span>{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.codFee | number
                                                : '1.2' : 'en-US'}}</span>
                                        </div>

                                        <div>Passport Booklet Fee: <span>{{feeDetailsObject.currencySymbol}}
                                                {{feeDetailsObject.bookletFee | number : '1.2' : 'en-US'}}</span></div>

                                        <div>Passport Delivery Fee: <span>{{feeDetailsObject.currencySymbol}}
                                                {{feeDetailsObject.deliveryFee | number : '1.2' : 'en-US'}}</span></div>

                                        <div><b>Total Fee:</b> <span><b>{{feeDetailsObject.currencySymbol}}
                                                    {{feeDetailsObject.totalFee | number : '1.2' : 'en-US'}}</b></span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </ng-container>

                    <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn"
                        value="Previous Page" />

                    <input *ngIf="isDeliveryAvailable === constants.TRUE" type="submit"
                        (click)="setShallExitApplication(true)" class="save_btn" value="Save & Exit" />

                    <input type="submit" (click)="setShallExitApplication(false)" class="action-button custom_btn"
                        value="Continue" />

                </form>

            </fieldset>

        </div>

    </section>

    <div>

        <app-footer></app-footer>