import { Injectable } from '@angular/core';
import { Constants } from './constants';
import { Tags } from './tags';
import { Utils } from './utils';

@Injectable({ 
    providedIn: 'root'
})
export class LocalStorage {
    private storage: Storage;

    constructor(private constants: Constants, private utils: Utils, private tags: Tags){
        this.storage = window.localStorage;
    }

    setStorageKey(keyName: string, keyValue: string){
        this.storage.setItem(keyName, keyValue);
    }

    getStorageKey(keyName: string){
        return this.utils.isEmpty(this.storage.getItem(keyName)) === false ? this.storage.getItem(keyName) : this.constants.BLANK;
    }

    removeStorageKey(keyName: string){
        if(this.utils.isEmpty(this.storage.getItem(keyName)) === false){
            this.storage.removeItem(keyName);
        }
    }

    removeAllStorageData(){
        this.storage.clear();
    }

    getApplicationToken(): string {
        return this.getStorageKey(this.tags.APPLICATION_TOKEN);
    }

    getLoggedUserId() {
        const loggedUserId = this.getStorageKey(this.tags.LOGGED_USER_ID);
        return this.utils.decryptString(loggedUserId);
    }

    getLoggedUserFullName() {
        const loggedUserFullName = this.getStorageKey(this.tags.LOGGED_USER_NAME);
        return this.utils.decryptString(loggedUserFullName);
    }

    getLoggedUserType() {
        const encryptedUserTypeJSONObject = this.getStorageKey(this.tags.LOGGED_USER_TYPE);
        return this.utils.decryptString(encryptedUserTypeJSONObject);
    }
    
    getLoggedUserCurrentRoute() {
        const loggedUserCurrentRoute = this.getStorageKey(this.tags.LOGGED_USER_CURRENT_ROUTE);
        return this.utils.decryptString(loggedUserCurrentRoute);
    }

}
