import { Directive, HostListener, ElementRef } from '@angular/core';
import { Constants } from '../common/constants';

@Directive({
  selector: '[error-focus]'
})
export class ErrorFocusDirective {

    constructor(private elementRef: ElementRef, private constants: Constants) { }

    @HostListener('submit', ['$event'])
    public onSubmit(event: any): void {
        const invalidElements = this.elementRef.nativeElement.querySelectorAll('.ng-invalid');
        if (invalidElements.length > 0) {
            const altAttribute = document.createAttribute("alt");
            altAttribute.value = this.constants.TRUE.toString();
            invalidElements[0].setAttributeNode(altAttribute);
            invalidElements[0].focus();
        }
    }

}