import { Injectable } from '@angular/core';

@Injectable({ 
    providedIn: 'root'
})
export class Pattern {

    PASSPORT_NUMBER_PATTERN = '^[A-Za-z].*$';
    
    COMMON_FIELD_PATTERN = '^[A-Za-z].*[A-Za-z]$';
    
    NAME_FIELD_PATTERN = '^[A-Za-z0-9].*[A-Za-z0-9]$';
    
    EMAIL_FIELD_PATTERN = '^[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.\\-]{2,}[.]{1}[a-zA-Z0-9]{2,}$';

    FIRST_NAME_FIELD_PATTERN = '^[A-Za-z].*[A-Za-z0-9]$';

}
