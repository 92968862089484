import { Injectable } from '@angular/core';

@Injectable({ 
    providedIn: 'root'
})
export class Route {

    HOME_NEW = '';

    APPLICATION_HELP = 'help';

    PAYMENT_HELP = 'faq';

    HOME = 'home-old-not-used';

    DASHBOARD = 'dashboard';

    VALIDATE_EMAIL = 'validate';

    APPLICATION_ADD_EDIT = 'detail';

    RESUME_APPLICATION = 'resume';

    APPLICATION_VIEW = 'view';

    APPLICATION_VIEW_V1 = 'preview';

    APPLICATION_SEARCH = 'search';

    PAYMENT_SUCCESS = 'success';

    PAYMENT_SUCCESS_V1 = 'confirmation'

    PAYMENT_FAILURE = 'failure';

    PAYMENT_FAILURE_V1 = 'failure-v1';

    PAYMENT_RECEIPT = 'receipt';

    GUARANTOR_FORM = 'guarantor';

    LOGOUT = 'logout';

    PAGE_NOT_FOUND = '**';

    FORBIDDEN = 'forbidden';

    NIN_VERIFICATION = 'nin';

    COD_REQUEST = 'passport';

    COD_DATA_COMPARISON = 'comparison';

    APPLICATION_REUPLOAD = 'reupload';

    IMAGE_TEST = 'image-compliance';
    
    LOGIN = 'login';

    CONTACTLESS = 'contactless-response';

    CONTACTLESS_PAYMENT = 'contactless-payment';
    
}
