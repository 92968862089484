<div class="notice_modal">

    <div class="modal-header">
        <h4>IMPORTANT NOTICE</h4>
    </div>

    <div class="modal-body">
      <p [innerHTML]="message || 'Are you sure?'"></p>
    </div>

    <div class="modal-footer" style="position:relative;">
        <button type="button" class="btn" (click)="confirm()">Continue</button>
        <button type="button" class="save_btn" (click)="cancel()">Apply from within Nigeria</button>
    </div>

</div>
