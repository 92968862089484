<div class="image_upload_container">

    <div class="inner_image_upload_wapper">

        <div class="modal-header">
            <h4>Upload Photo</h4>
        </div>

        <form #imageUploadForm="ngForm" (ngSubmit)="imageUploadForm.form.valid && validatePhoto()" name="imageUploadForm" id="msform" class="ms_form_img" autocomplete="off" style="padding: 0;">

            <ng-template #instructions>

                <ul class="details_photo">

                    <li>Image size should be less than {{constants.USER_IMAGE_REQUIRED_SIZE_IN_MB}}MB.</li>

                    <li>Image resolution should be 600x800 px.</li>

                    <li>Must be white background.</li>

                    <li>Hair should not cover the face or ears. </li>

                    <li>Don't use any apparel-headphone, mask etc.</li>

                    <li>Should hove proper lighting-Neither too dark, nor too bright.</li>

                </ul>

            </ng-template>
            
            <div class="modal-body">

                <div class="centered">
                
                    <details>
                                
                        <summary class="align-left">Photo Instuctions </summary>
                    
                        <div class="content">

                            <img src="assets/images/dummy.png" alt="dummy">

                            <ng-template [ngTemplateOutlet]="instructions"></ng-template> 

                        </div>

                    </details>

                    <div class="grid-photo-two">

                        <div class="grid-boxs">

                            <div *ngIf="showDummyImage === true" class="photo_box icon_dummy photo-set">

                                <i class="fa fa-file-image-o" aria-hidden="true"></i>
                                
                            </div>

                            <ngx-super-croppie
                                *ngIf="enableImageCropper"
                                #ngxSuperCroppie
                                [croppieOptions]="croppieOptions"
                                [url]="uploadedFileBase64Data"
                                [orientation]="orientation"
                                [zoom]="zoom"
                                [resultOptions]="resultOptions"
                                (result)="updateCroppedImage($event)"
                            ></ngx-super-croppie>
                            <span class="alert alert-danger absolute" role="alert" *ngIf="imageUploadForm.submitted && base64Data.invalid">
                                <span *ngIf="base64Data.errors.required">
                                    Photo is required!
                                </span>
                            </span>

                            <div class="file_upload1 w100 mt10 mb10">
                                <input type="hidden" name="base64Data" [(ngModel)]="uploadedFileBase64Data" #base64Data="ngModel" required="required">
                                
                                <button type="button" class="filt--upload">
                                    <input type="file" [accept]="requestObject.allowFileTypeList" (change)="uploadFile($event)" id="myFile" class="upload up">
                                    <span id="upload" class="upl">Browse</span>
                                </button>
                            </div>

                            <div class="flex_row mt10 center-content"></div>

                        </div>

                        <div class="grid-boxs">
                            
                            <div class="instructionss">

                                <div class="element none_dis">
                                    <div class="grid-two">
                                        <div class="grid-boxs">
                                            <h4>Passport Photo Dimensions</h4>
                                            <img src="assets/images/dummy.png" alt="dummy">
                                        </div>
                                        <div class="grid-boxs">
                                            <h4 >Note:</h4>
                                            <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="compliants.length > constants.FALSE" class="issue_box">
                                
                                <h4 style="color:red;"> ICAO Compliance Issue(s) </h4>
                                
                                <ul class="issues">
                                
                                    <li *ngFor="let compliant of compliants" >{{compliant}}</li>
                                
                                </ul>
                                
                            </div>

                        </div>

                    </div>

                </div>
            
            </div>

            <div class="modal-footer">
                <button type="submit" class="btn">Submit</button>
                
                <button type="button" class="back_btn" (click)="cancel()">Cancel</button>
            </div>

        </form>

    </div>
    
</div>
