<app-header></app-header>

<div class="wrapper">

    <div class="main">
        <h3 style="padding-bottom:0;">{{pageTitle}}</h3>
    </div>

    <section class="main">

        <p *ngIf="applyingFor === constants.REISSUE">Please check <span (click)="openCODDocumentsModal()" style="font-weight: bold; color: #0d7836; cursor: pointer;">List of Supporting Documents</span> for Correction of Data.</p>
        
        <fieldset class="mb30">
            
            <div class="step_info">
                <span>Current Passport Details</span>
            </div>  

            <div class="form-card">

                <form #passportDetailsForm="ngForm" (ngSubmit)="passportDetailsForm.form.valid && fetchExistingPassportDetails()" [validateDateOfBirth]="['dateOfBirthDay', 'dateOfBirthMonth', 'dateOfBirthYear', constants.FALSE]" autocomplete="off" appFocus>
    
                    <details>
                        <summary>Important Notice</summary>
                        <div class="content">
                            <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                        </div>
                    </details>

                    <div class="nin_verify_grid">
    
                       <div class="grid-boxs">
    
                            <div class="form_box" >
                                <label class="fieldlabels">Current Passport Number <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="currentPassportNumber" hint alphaNumericOnly [(ngModel)]="passportDetailsFormObject.currentPassportNumber" #currentPassportNumber="ngModel" (input)="passportDetailsFormObject.currentPassportNumber = passportDetailsFormObject.currentPassportNumber.toUpperCase()" class="effect" required="required" pattern="^[A-Za-z].*$" minlength="7" maxlength="9" firstFocus>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your passport number as it appears on your passport. The passport number may contain numbers and/or characters. Please closely distinguish between the number zero and the letter O. The passport number is required to complete the application or to check the status of your application.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="passportDetailsForm.submitted && currentPassportNumber.invalid">
                                    <span *ngIf="currentPassportNumber.errors?.required">
                                        Passport Number is required!
                                    </span>
                                    <span *ngIf="currentPassportNumber.errors?.minlength">
                                        Passport Number should be 7 digits!
                                    </span>
                                    <span *ngIf="!currentPassportNumber.errors?.minlength && currentPassportNumber.errors?.pattern">
                                        Passport Number should start with alphabet!
                                    </span>
    
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Date of Birth on Current Passport (dd/mm/yyyy) <span class="medatory">*</span></label>
                                <div class="grid-date">
                                    <div class="input_box">
                                        <input type="text" name="dateOfBirthDay" hint numberOnly [(ngModel)]="passportDetailsFormObject.dateOfBirthDay" #dateOfBirthDay="ngModel" class="effect" required="required" maxlength="2" placeholder="DD">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter the date on which you were born. This should be matched with your NIN document.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>

                                    <div class="input_box">
                                        <select name="dateOfBirthMonth" hint [(ngModel)]="passportDetailsFormObject.dateOfBirthMonth" #dateOfBirthMonth="ngModel" class="effect" required="required">
                                            <option *ngFor="let object of monthsList;" [value]="object.value">{{object.text}}</option>
                                        </select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Choose the month in which you were born. This should be matched with your NIN document.</span>
                                        <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                        <span class="focus-border "><i></i></span>
                                    </div>

                                    <div class="input_box">
                                        <input type="text" name="dateOfBirthYear" hint numberOnly [(ngModel)]="passportDetailsFormObject.dateOfBirthYear" #dateOfBirthYear="ngModel" class="effect" required="required" minlength="4" maxlength="4" placeholder="YYYY">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter the year in which you were born. This should be matched with your NIN document.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="passportDetailsForm.submitted && (dateOfBirthDay.invalid || dateOfBirthMonth.invalid || dateOfBirthYear.invalid)">
                                    <span *ngIf="(dateOfBirthDay.errors?.required || dateOfBirthMonth.errors?.required || dateOfBirthYear.errors?.required)">
                                        Date of Birth is required!
                                    </span>
                                    <span *ngIf="dateOfBirthYear.errors?.minlength">
                                        Date of Birth's year should be 4 digits!
                                    </span>
                                    <span *ngIf="!dateOfBirthYear.errors?.minlength && dateOfBirthDay.errors?.hasError">
                                        {{dateOfBirthDay.errors.errorMessage}}
                                    </span>
                                </span>
                            </div>

                            <div style="float: left;">
                                <input type="submit" class="custom_btn mleft0 mright5" value="Verify" [disabled]="showPassportDetailsSection === true "/>
                                <input *ngIf="showPassportDetailsSection === true" type="button" value="Reset" (click)="reset()" class="save_btn">
                            </div>

                        </div> 
                        
                        <div class="grid-boxs upload_inst">
                            <div  class="imp_info">
                                <div  class="element none_dis">
                                    <h4>Instructions</h4>
                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                                </div>
                            </div>
                        </div> 
                    
                    </div>
    
                    <ng-container *ngIf="showPassportDetailsSection === true">

                        <div class="mt15">

                            <div class="w100 personal_details_section mt10">
                                
                                <h3>Your Personal Details</h3>
                                
                                <p class="pb10">This information has been retrieved from your passport record and cannot be edited. If all the information is correct, then click on the <b>Confirm & Continue</b> button</p>
                                
                                <table *ngIf="applyingFor === constants.REISSUE" class="table-code-req">
                                    <!-- <tr>
                                        <td><b>Title</b></td>
                                        <td><span>{{passportDetailsObject.currentTitle}}</span></td>
                                    </tr>  -->

                                    <tr>
                                        <td><b>First Name</b></td>
                                        <td><span>{{passportDetailsObject.currentFirstName}}</span></td>
                                    </tr> 

                                    <tr>
                                        <td><b>Middle Name</b></td>
                                        <td><span>{{passportDetailsObject.currentMiddleName}}</span></td>
                                    </tr> 

                                    <tr>
                                        <td><b>Last Name</b></td>
                                        <td><span>{{passportDetailsObject.currentLastName}}</span></td>
                                    </tr> 

                                    <tr>
                                        <td><b>Gender</b></td>
                                        <td><span>{{passportDetailsObject.currentGender}}</span></td>
                                    </tr>
                                    
                                    <tr>
                                        <td><b>Date of Birth</b></td>
                                        <td><span>{{passportDetailsObject.currentDateOfBirthLabel}}</span></td>
                                    </tr>
                                    
                                    <tr>
                                        <td><b>Place of Birth</b></td>
                                        <td><span>{{passportDetailsObject.currentPlaceOfBirth}}</span></td>
                                    </tr>

                                </table>
                                
                                <table *ngIf="applyingFor === constants.RENEW" class="request_table">
                                    <tr>
                                        <td><b>Name of Fields</b></td>
                                        <td><b>Data from Current Passport</b></td>
                                        <td><b>Data from NIN Records</b></td>
                                        <td><b>Matched Data</b></td>
                                    </tr>
                                    
                                    <tr *ngFor="let comparisonItems of codComparisonItemsList">
                                        <td>{{comparisonItems.fieldLabel}}</td>
                                        <td>{{comparisonItems.dataOnPassport}}</td>
                                        <td><b>{{comparisonItems.dataOnNIN}}</b></td>
                                        <td *ngIf="comparisonItems.comparisonResult === true">
                                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                                        </td>
                                        <td *ngIf="comparisonItems.comparisonResult === false">
                                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                                        </td>
                                    </tr>
                                </table>

                            </div>

                            <div class="w100 mb15">
                                <input type="button" class="custom_btn mleft0" value="Confirm & Continue" (click)="saveExistingPassportDetails()" />
                            </div>

                        </div>
                    
                    </ng-container>
    
                </form>

            </div>

        </fieldset>

    </section>

</div>

<app-footer></app-footer>

<ng-template #instructions>

    <ul>
        <li>All fields that contain the Asterisk (*) sign are mandatory and must be completed.</li>
    </ul>

</ng-template>