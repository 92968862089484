<div class="confirmbox-container">

    <div class="modal-header">
        <h4>{{title}}</h4>
    </div>
    
    <div class="modal-body">
      <p [innerHTML]="message"></p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn submit-cancel" (click)="confirm()">{{confirmButtonLabel}}</button>
        <button *ngIf="isShowCancelButton === true" type="button" class="back_btn submit-cancel" (click)="cancel()">{{cancelButtonLabel}}</button>
    </div>

</div>