<div class="captcha-box">

    <div class="captcha-container {{config.cssClass}}">
  
      <div>
        <canvas id="captcahCanvas" width="220" height="80"></canvas>
      </div>
    
      <div class="captcha-actions">
  
        <div class="captcha-inputs relative captch-db-btn">
          <div class="captcha-input-container">
            <div class="input_box">
              <input type="text" name="inputText" numberOnly [(ngModel)]="enteredCaptchaText" #inputText="ngModel" required="required" class="effect check-input" (keyup.enter)="callOnEnter()" [disabled]="isVerified === true" [minlength]="config.length" [maxlength]="config.length" />
              <span class="tooltip"><span class='tooltipArrow'></span>Please enter the 4-digit code shown above. This helps us ensure that you're a human and not a bot.</span>
              <span class="focus-border "><i></i></span>
            </div>
            
            <span *ngIf="currentForm.submitted && inputText.invalid">
              <span *ngIf="inputText.errors.required" class="alert captcha-alert">
                Captcha is required!
              </span>  
              <span *ngIf="inputText.errors.minlength" class="alert captcha-alert">
                Captcha should be 4 digits!
              </span>
              <span *ngIf="inputText.errors.hasError === true" class="alert captcha-alert">
                Please correctly enter the characters displayed in the picture!
              </span>
            </span>
          </div>
  
          <div class="captcha-btn-input">
            <a href="javascript:void(0)" class="cpt-btn" (click)="isVerified === false ? playCaptcha() : null" ><i class="fa fa-volume-down" aria-hidden="true"></i></a>
            <a href="javascript:void(0)" class="cpt-btn reload" (click)="isVerified === false ? createCaptcha() : null" >&#x21bb;</a>
          </div>
        </div>
  
      </div>
    
    </div>
  
  </div>
  