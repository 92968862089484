<footer class="footer">
    <div class="footer-wrapper main">
        <div class="footer-inner">
            <div class="footer-items">
                <h2>NIS Headquaters</h2>
                <p class="footer_about">Umar Musa Yar Adu'a Express Way, Airport Road,
                    Sauka, Abuja, FCT Nigeria</p>
                <ul class="info_links_footer">
                    <li>
                        <span class="icon-footer">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                        </span>
                        <p>+234-912-1556-359<br>
                        07:00 - 20:00hours (GMT+1)</p>
                    </li>
                    <li>
                        <span class="icon-footer">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                        <a href="mailto:info@immigration.gov.ng" target="_blank" rel="noopener noreferrer"><p>info@immigration.gov.ng</p></a>
                    </li>
                </ul>
            </div>
            <div class="footer-items">
                <h2>Complaints and Enquiries</h2>
                <ul class="info_links_footer">
                    <li>
                        <span class="icon-footer">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                        </span>
                        <p>+234-912-1477-092</p>
                    </li>
                    <!-- <li>
                        <span class="icon-footer">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                        <p>nis.servicom@nigeriaimmigration.gov.ng</p>
                    </li> -->
                    <li>
                        <span class="icon-footer">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                        <p>support@newworkssolution.com</p>
                    </li>
                </ul>
            </div>
            <div class="footer-items">
                <h2>Connect with us</h2>
                <ul class="social_links_footer">
                    <li>
                        <span><i class="fa fa-facebook-official" aria-hidden="true"></i></span>
                    </li>
                    <li>
                        <span>
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                        </span>
                    </li>
                    <li>
                        <span>
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>

