import { Component, OnInit, ViewChild } from '@angular/core';
import { CroppieOptions, ResultOptions } from 'croppie';
import { NgxSuperCroppieComponent } from 'ngx-super-croppie';
import { Constants } from '../common/constants';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { LoaderService } from '../common/loader-service';
import { HttpUtils } from '../common/http-utils';
import { GUI } from '../common/gui';
import { CaptchaComponent } from '../captcha/captcha.component';

@Component({
  selector: 'app-image-test',
  templateUrl: './image-test.component.html',
  styleUrls: ['./image-test.component.css']
})
export class ImageTestComponent {

  @ViewChild('ngxSuperCroppie') ngxSuperCroppie: NgxSuperCroppieComponent;

  @ViewChild(CaptchaComponent) captchaComponent : CaptchaComponent;

  requestObject: any = {};

  responseObject: any = {};

  showDummyImage: boolean = true;

  enableImageCropper: boolean = false;

  uploadedFileBase64Data: string = this.constants.BLANK;

  croppedImageBase64Data : any = this.constants.BLANK;

  isValidImage = false;
  
  croppieOptions: CroppieOptions = this.utils.croppieOptions;

  orientation = this.utils.orientation;

  zoom = this.utils.zoom;

  croppedImage: string | HTMLElement | Blob | HTMLCanvasElement;

  resultOptions: ResultOptions = this.utils.resultOptions;

  compliants: any[] = [];

  constructor(public constants: Constants, private toast: Toast, private utils: Utils, private loaderService: LoaderService, private httpUtils: HttpUtils, public gui: GUI) { 
    this.setDefaultValues();
    this.croppieOptions.enableResize = this.requestObject.isFixedCropper === this.constants.FALSE;
    this.enableImageCropper = this.requestObject.fileType !== this.constants.FILE_TYPE_PDF && this.utils.isEmpty(this.requestObject.fileBase64Data) === false;
    this.showDummyImage = this.enableImageCropper === false;
  }

  private setDefaultValues(): void {
    this.requestObject['isFixedCropper'] = this.constants.TRUE;
    this.requestObject['allowFileTypeList'] = this.constants.ALLOW_IMAGE_LIST;;
  }

  uploadFile(event: any){
    const file: File = event.target.files[0];
    const fileType = file.type;
    this.responseObject.fileType = fileType;
    if(this.requestObject.allowFileTypeList.includes(fileType) === false){
      event.target.value = this.constants.BLANK;
      this.toast.showErrorToast("File type "+ fileType + " is not allowed");
      return;
    }
    const reader = new FileReader();
    if(fileType === this.constants.FILE_TYPE_PDF){
      this.enableImageCropper = false;
      this.showDummyImage = false;
      reader.onloadend = this.handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
    } else {
      const imageFileSizeInBytes = file.size;
      const imageFileSizeInMB = imageFileSizeInBytes/1024/1024;
      if(imageFileSizeInMB > this.constants.USER_IMAGE_REQUIRED_SIZE_IN_MB){
        event.target.value = this.constants.BLANK;
        this.toast.showErrorToast("Image size should not be bigger than "+ this.constants.USER_IMAGE_REQUIRED_SIZE_IN_MB + " MB");
        return;
      }
      reader.onloadend = this.handleImageDimensionsReader.bind(this);
      reader.readAsDataURL(file);
    }
  }

  private handleReaderLoaded(event: any) {
    const reader = event.target;
    this.uploadedFileBase64Data = reader.result;
    this.compliants = [];
  }

  private handleImageDimensionsReader(object: any) {

    this.fetchImageDimensions(object).then((response) => { 
      const responseJSON = JSON.parse(JSON.stringify(response));
      const width = responseJSON.width;
      const height = responseJSON.height;

      if(this.requestObject.isFixedCropper === this.constants.TRUE && (width < this.constants.USER_IMAGE_REQUIRED_WIDTH || height < this.constants.USER_IMAGE_REQUIRED_HEIGHT)){
        this.toast.showErrorToast("Image dimensions should be more than ("+this.constants.USER_IMAGE_REQUIRED_WIDTH+"x"+this.constants.USER_IMAGE_REQUIRED_HEIGHT+") px size");
        this.showDummyImage = true;
        this.enableImageCropper = false;
      } else {
        const reader = object.target;
        this.uploadedFileBase64Data = reader.result;
        this.enableImageCropper = true;
        this.showDummyImage = false;
      }
    });
  }

  private fetchImageDimensions(object: any): Promise<{}>{
    return new Promise((resolve, reject) => {
      const imageDimensionsObject = {};
      const image = new Image();  
      image.src = object.target.result;
      image.onload = function(){
        imageDimensionsObject['width'] = image.width;
        imageDimensionsObject['height'] = image.height;
        resolve(imageDimensionsObject); 
      }
    });
  }
  
  updateCroppedImage(crop: string | HTMLElement | Blob | HTMLCanvasElement): void {
    this.croppedImage = crop;
    this.croppedImageBase64Data = this.croppedImage;
  }

  confirm(): void {
    this.validatePhoto();
  }

  openDashboard(): void {
    this.gui.openNewHomePage();
  }

  validatePhoto(): void {
    this.compliants = [];
    this.isValidImage = false;
    
    if(this.utils.isEmpty(this.uploadedFileBase64Data) === true){
      this.toast.showErrorToast("Please upload photo then click on Submit");
      this.loaderService.stopLoader();
      return;
    }

    let finalBase64Data = this.uploadedFileBase64Data;
    finalBase64Data = this.utils.isEmpty(this.croppedImageBase64Data) === false ? this.croppedImageBase64Data : finalBase64Data;
    this.httpUtils.validateImageBeforeUpload(finalBase64Data).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const validatedBase64Data = serverResponse.base64Data;
        this.responseObject.fileType = this.responseObject.fileType === undefined || this.responseObject.fileType === this.constants.BLANK ? this.requestObject.fileType : this.responseObject.fileType;
        this.responseObject.fileBase64Data = validatedBase64Data;
        this.isValidImage = serverResponse.validImage;
      } else {  
        this.compliants = serverResponse.compliants;
      }
    });
  }

}
