import { Component, OnInit } from '@angular/core';
import { Utils } from '../common/utils';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { GUI } from '../common/gui';
import { LocalStorage } from '../common/local-storage';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-nin-verification',
  templateUrl: './nin-verification.component.html',
  styles: [
  ]
})
export class NinVerificationComponent implements OnInit {

  encryptedApplyingFor: string = this.constants.BLANK;

  applyingFor: string = this.constants.BLANK;

  pageTitle: string = this.constants.BLANK

  ninVerificationFormObject: any = {};
  
  ninDetailsObject : any = {};
  
  monthsList: any = [];
  
  showNINDetailsSection: boolean = false;

  loggedUserId: string = this.constants.BLANK;

  constructor(private utils: Utils, public constants: Constants, private httpUtils: HttpUtils, private loaderService: LoaderService, private toast: Toast, 
              private gui: GUI, private localStorage: LocalStorage, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplyingFor = params['applyingFor'];
    });
    this.applyingFor = this.utils.decryptString(this.encryptedApplyingFor);
    this.setPageTitle()
    this.loggedUserId = this.localStorage.getLoggedUserId();  
    this.monthsList = this.utils.getMonthsArray();
    this.setFormDefaultValues();
  }
  
  ngOnInit(): void {
  }

  private setPageTitle(): void {
    if(this.applyingFor === this.constants.RENEW) {
      this.pageTitle = 'NIN Verification for Renewal/Re-issue Passport Application';
    }else if(this.applyingFor === this.constants.REISSUE){
      this.pageTitle = 'NIN Verification for Correction of Data Passport Application';
    }else {
      this.pageTitle = 'NIN Verification for Fresh Passport Application';
    }
  }

  private setFormDefaultValues(): void {
    this.ninVerificationFormObject.applyingFor = this.applyingFor;
    this.ninVerificationFormObject.dateOfBirthMonth = this.constants.BLANK;
    this.showNINDetailsSection = false;
  }

  validateNIN(): void {
    this.loaderService.startLoader();
    this.ninVerificationFormObject.dateOfBirth = this.ninVerificationFormObject.dateOfBirthDay + '/' + this.ninVerificationFormObject.dateOfBirthMonth + '/' + this.ninVerificationFormObject.dateOfBirthYear;
    this.ninVerificationFormObject.loggedUserId = this.loggedUserId;
    this.httpUtils.validateNIN(this.ninVerificationFormObject).then(serverResponse =>{
      this.loaderService.stopLoader();
      if(serverResponse.responseCode == this.constants.STATUS_SUCCESS){
        this.ninDetailsObject = serverResponse.details;
        this.showNINDetailsSection = true;
      } else{
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  saveNINDetails(): void {
    this.loaderService.startLoader();
    this.httpUtils.saveNIN(this.ninVerificationFormObject).then(serverResponse =>{
      this.loaderService.stopLoader();
      if(serverResponse.responseCode == this.constants.STATUS_SUCCESS){
        const masterId = serverResponse.masterId;
        if(this.applyingFor === this.constants.FRESH) {
          this.gui.openApplicationAddPage(masterId);
        } else {
          this.gui.openPassportVerificationPage(masterId, this.applyingFor);
        }
      } else{
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  reset(): void {
    this.ninVerificationFormObject = {};
    this.setFormDefaultValues();
  }
  
}
