<app-header-no-session></app-header-no-session>

<div class="banner_wrapper">
    <div class="home_banner_img">
        <div class="banner_content">
            <h1>Form Help</h1>
        </div>
    </div>
</div>

<main class="help_page page">
    <div class="main">
        <section>
            <h1 class="heading_main text_center green-text">How to fill your Application form</h1>
            <div class="help_wrapper">
                
                <div class="help_items">
                    <div class="help_item">
                        
                        <table>

                            <tr>
                                <td>
                                    <span class="help_item_list"  >1. Reason for Renewal:</span>
                                </td>
                                <td>
                                    <p>This field contains 4 reasons for renewing the passport.</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span class="help_item_list" >2. Passport Number:</span>
                                </td>
                                <td>
                                    <p>This field requires your current passport number.</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span class="help_item_list" >3. Processing Country:</span>
                                </td>
                                <td>
                                    <p>This field refers to the country from which the user wants to apply for their passport to be processed.</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span class="help_item_list">4. Processing Embassy:</span>
                                </td>
                                <td>
                                    <p>This field requires the embassy from where the passport will be processed.</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span class="help_item_list" >5. Booklet Type:</span>
                                </td>
                                <td>
                                    <p>It contains 2 options: 32 and 64 ,the number of pages required in the passport booklet.</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span class="help_item_list" >6. Validity:</span>
                                </td>
                                <td>
                                    <p>It can be 5 years or 10 years based on the processing center.</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="help_item img right">
                        <img src="assets/images/Stage1_Passport_Type_Details.png" alt="Login">
                    </div>
                </div>

                <div class="help_items">
                    <div class="help_item img left">
                        <img src="assets/images/Stage3_Personal_Details.png" alt="Login">
                    </div>
                    <div class="help_item">
                        <table>
                            <tr>
                                <td width="200px">
                                    <span class="help_item_list">1. Title:</span>
                                </td>
                                <td>
                                    <p>This field is editable for Female applicants only (Miss or Mrs.) In the case of Male, this field is prefilled (Mr.).</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">2. Place of Birth:</span>
                                </td>
                                <td>
                                    <p>The applicant will enter his/her birth place.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">3. Country of Birth:</span>
                                </td>
                                <td>
                                    <p>The applicant will enter his/her birth country.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">4. Marital Status:</span>
                                </td>
                                <td>
                                    <p>This field contains 4 options from which the applicant can select his/her status. The applicant is required to upload documents accordingly.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">5. Occupation:</span>
                                </td>
                                <td>
                                    <p>The applicant is required to enter his/her job/profession. In the case of Minor, this field is not required.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">6. Mother’s Maiden Name:</span>
                                </td>
                                <td>
                                    <p>A married woman's maiden name is her parents' surname, which she used before she got married and started using her husband's surname.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">6. Communication E-mail:</span>
                                </td>
                                <td>
                                    <p>The email provided will be the primary point of contact with the applicant regarding application and payment.</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="help_items">
                    <div class="help_item">
                        <table>
                            <tr>
                                <td width="200px">
                                    <span class="help_item_list" >1. Contact Number:</span>
                                </td>
                                <td>
                                    <p>This field requires the phone number that you (or the person who is being asked for a contact number) can be reached at.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list" >2. Additional Number:</span>
                                </td>
                                <td>
                                    <p>Alternate phone number are additional numbers that can be used alongside your regular number which can be used if the primary number is not reachable.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list" >3. Address:</span>
                                </td>
                                <td>
                                    <p>The 'Address Line 1' and 'Address Line 2' field requires the number of the building and the name of the street and place where somebody lives or works.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list" >4. Country:</span>
                                </td>
                                <td>
                                    <p>The name of the country where the applicant is residing.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list" >5. State/Region:</span>
                                </td>
                                <td>
                                    <p>The applicant is required to enter the state in which he/she is currently residing.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list" >6. City / Town:</span>
                                </td>
                                <td>
                                    <p>The applicant will enter the province/town to which he/she belongs.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list" >7. Postal Code / Zip Code:</span>
                                </td>
                                <td>
                                    <p>This field determines the exact location of the applicant.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">8. State of Origin:</span>
                                </td>
                                <td>
                                    <p>This field determines the area from which the applicant belongs to.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list" >9. Hometown:</span>
                                </td>
                                <td>
                                    <p>The applicant will enter the name of the hometown where he/she was born.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list" >10. State/Region:</span>
                                </td>
                                <td>
                                    <p>The applicant is required to enter the state in which he/she permanently resides in Nigeria.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list" >11. LGA:</span>
                                </td>
                                <td>
                                    <p>LGA refers to the local government area. The applicant can fill the LGA of his residence.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list" >12. City:</span>
                                </td>
                                <td>
                                    <p>The name of the city of residence for contact purposes.</p>
                                </td>
                            </tr>
                        </table> 
                    </div>
                    <div class="help_item img right">
                        <img src="assets/images/Stage4_Contact_Details.png" alt="Login">
                    </div>
                </div>

                <div class="help_items">
                    <div class="help_item img left">
                        <img src="assets/images/Stage5_Kin_Details.png" alt="Login">
                    </div>
                    <div class="help_item">
                        <table>
                            <tr>
                                <td width="180px">
                                    <span class="help_item_list">1. Name of Next of Kin:</span>
                                </td>
                                <td>
                                    <p>A person's next of kin is their living blood relative, including spouses and adopted family members or friends.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">2. Relationship:</span>
                                </td>
                                <td>
                                    <p>The user can select the social relationship with the kin.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">3. Address:</span>
                                </td>
                                <td>
                                    <p>The 'Address Line 1' and 'Address Line 2' field will contain the address of the next of kin mentioned in the previous field.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">4. State:</span>
                                </td>
                                <td>
                                    <p>The applicant is required to enter the state in which the Next of kin is residing.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">5. LGA:</span>
                                </td>
                                <td>
                                    <p>This field requires the local government area. The applicant can fill the LGA of his Next of kin's residence.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">6. City / Town:</span>
                                </td>
                                <td>
                                    <p>This field requires the province/town of the Next of kin’s area of residence.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">7. Contact Number:</span>
                                </td>
                                <td>
                                    <p>The applicant can mention the phone number on which Next of kin can be reached.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="help_item_list">8. Postal Code:</span>
                                </td>
                                <td>
                                    <p>This field is used to determine the exact location of Next of kin.</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="help_items">
                    <div class="help_item">
                        <table>
                            <tr>
                                <td width="100px">
                                    <span class="help_item_list">Upload:</span>
                                </td>
                                <td>
                                    <p>Applicant have to upload all the required documents in clear and readable form.</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="help_item img right">
                        <img src="assets/images/Stage6_Document_Upload.png" alt="Login">
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>

<app-footer-no-session></app-footer-no-session>