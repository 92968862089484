import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ApplicationAddEditComponent } from './application-add-edit/application-add-edit.component';
import { ApplicationViewComponent } from './application-view/application-view.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';
import { Route } from './common/route';
import { AuthGuard } from './common/auth-guard';
import { ResumeApplicationComponent } from './resume-application/resume-application.component';
import { ApplicationViewV1Component } from './application-view-v1/application-view-v1.component';
import { LogoutComponent } from './logout/logout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PaymentSuccessV1Component } from './payment-success-v1/payment-success-v1.component';
import { PaymentFailureV1Component } from './payment-failure-v1/payment-failure-v1.component';
import { InvalidAccessComponent } from './invalid-access/invalid-access.component';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';
import { GuarantorFormComponent } from './guarantor-form/guarantor-form.component';
import { NinVerificationComponent } from './nin-verification/nin-verification.component';
import { HomeNewComponent } from './home-new/home-new.component';
import { ApplicationHelpComponent } from './application-help/application-help.component';
import { PaymentHelpComponent } from './payment-help/payment-help.component';
import { CODRequestComponent } from './codrequest/codrequest.component';
import { CODDataComparisonComponent } from './coddata-comparison/coddata-comparison.component';
import { DocumentReuploadComponent } from './document-reupload/document-reupload.component';
import { HomeNewV2Component } from './home-new-v2/home-new-v2.component';
import { ImageTestComponent } from './image-test/image-test.component';
import { LoginComponent } from './login/login.component';
import { ContactlessResponseComponent } from './contactless-reponse/contactless-response.component';
import { ContactlessPaymentComponent } from './contactless-payment/contactless-payment.component';

const route = new Route();

const routes: Routes = [

  {path:route.HOME_NEW, component: HomeNewV2Component, pathMatch: 'full'},
  
  // {path:route.HOME_NEW, component: HomeNewComponent, pathMatch: 'full'},
  
  {path:route.IMAGE_TEST, component: ImageTestComponent, pathMatch: 'full'},
  
  {path:route.APPLICATION_HELP, component: ApplicationHelpComponent, pathMatch: 'full'},

  {path: route.LOGIN, component: LoginComponent, pathMatch: 'full'},
  
  {path: route.APPLICATION_HELP, component: ApplicationHelpComponent, pathMatch: 'full'},

  {path: route.PAYMENT_HELP, component: PaymentHelpComponent, pathMatch: 'full'},

  // {path: route.HOME, component: HomeComponent, pathMatch: 'full'},
  
  {path: route.DASHBOARD, component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard]},

  // {path: route.APPLICATION_ADD_EDIT, component: ApplicationAddEditComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  
  {path: route.APPLICATION_ADD_EDIT + "/:applicantId", component: ApplicationAddEditComponent, pathMatch: 'full', canActivate: [AuthGuard]},

  // {path: route.RESUME_APPLICATION + "/:applicationId/:applicantEmail", component: ResumeApplicationComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  
  {path: route.APPLICATION_VIEW + "/:applicantId", component: ApplicationViewComponent, pathMatch: 'full', canActivate: [AuthGuard]},

  // {path: route.APPLICATION_VIEW + "/:applicantId/:requestResource", component: ApplicationViewComponent, pathMatch: 'full', canActivate: [AuthGuard]},

  {path: route.APPLICATION_VIEW_V1 + "/:applicationId/:referenceId", component: ApplicationViewV1Component, pathMatch: 'full', canActivate: [AuthGuard]},
  
  // {path: route.APPLICATION_SEARCH, component: ApplicationSearchComponent, pathMatch: 'full'},

  // {path: route.APPLICATION_VIEW_V1 + '/:applicationId/:referenceId/:isSearchFromHome', component: ApplicationViewV1Component, pathMatch: 'full'},
  
  // {path: route.PAYMENT_SUCCESS + "/:applicantId/:txnRefNumber", component: PaymentSuccessComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  
  // {path: route.PAYMENT_FAILURE + "/:applicantId/:txnRefNumber", component: PaymentFailureComponent, pathMatch: 'full', canActivate: [AuthGuard]},

  {path: route.PAYMENT_SUCCESS_V1  + '/:paymentData', component: PaymentSuccessV1Component, pathMatch: 'full', canActivate: [AuthGuard]},

  // {path: route.PAYMENT_FAILURE_V1, component: PaymentFailureV1Component, pathMatch: 'full', canActivate: [AuthGuard]},

  // {path: route.PAYMENT_RECEIPT + "/:applicantId/:isV1Application", component: PaymentReceiptComponent, pathMatch: 'full'},

  {path: route.PAYMENT_RECEIPT + "/:applicantId", component: PaymentReceiptComponent, pathMatch: 'full', canActivate: [AuthGuard]},

  // {path: route.GUARANTOR_FORM + "/:applicantId", component: GuarantorFormComponent, pathMatch: 'full', canActivate: [AuthGuard]},

  {path: route.FORBIDDEN, component: InvalidAccessComponent, pathMatch: 'full'},

  {path: route.LOGOUT, component: LogoutComponent, pathMatch: 'full'},

  {path: route.NIN_VERIFICATION + "/:applyingFor", component: NinVerificationComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  
  {path: route.COD_REQUEST + '/:masterId/:applyingFor', component: CODRequestComponent, pathMatch: 'full', canActivate: [AuthGuard]},

  {path: route.COD_DATA_COMPARISON + '/:dataCorrectionId' , component: CODDataComparisonComponent, pathMatch: 'full', canActivate: [AuthGuard]},

  {path: route.APPLICATION_REUPLOAD + '/:token', component: DocumentReuploadComponent, pathMatch: 'full', canActivate: [AuthGuard]},


  { path: route.CONTACTLESS, component: ContactlessResponseComponent, pathMatch: 'full'  },

  { path: route.CONTACTLESS_PAYMENT + '/:applicationId/:referenceNumber/:status', component: ContactlessPaymentComponent, pathMatch: 'full' },


  {path: route.PAGE_NOT_FOUND, component: PageNotFoundComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }