import { Component } from '@angular/core';
import { LoaderService } from '../common/loader-service';
import { HttpUtils } from '../common/http-utils';
import { LocalStorage } from '../common/local-storage';
import { Toast } from '../common/toast';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';

@Component({
  selector: 'app-application-search',
  templateUrl: './application-search.component.html',
  styleUrls: ['./application-search.component.css']
})
export class ApplicationSearchComponent {

  searchApplicationFormObject: any = {};

  constructor(private loaderService: LoaderService, private httpUtils: HttpUtils, private localStorage: LocalStorage, private toast: Toast, private constants: Constants, public gui: GUI) { }

  searchApplication(): void {
    this.loaderService.startLoader();
    this.searchApplicationFormObject.isUnpaidSearch = this.constants.FALSE;
    this.searchApplicationFormObject.isSearchFromHome = this.constants.TRUE;
    this.searchApplicationFormObject.loggedUserId = this.localStorage.getLoggedUserId();
    this.httpUtils.searchApplication(this.searchApplicationFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
          this.gui.openApplicationPreviewPageV1FromHome(serverResponse.applicationIdV1, serverResponse.referenceIdV1);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

}
