import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-photo-guidelines-modal',
  templateUrl: './photo-guidelines-modal.component.html',
  styleUrls: ['./photo-guidelines-modal.component.css']
})
export class PhotoGuidelinesModalComponent extends SimpleModalComponent<null, boolean> {

  constructor() {
    super();
  }

  cancel(): void {
    this.result = true;
    this.close();
  }

}
