import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from '../common/constants';
import { Utils } from '../common/utils';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { GUI } from '../common/gui';

@Component({
  selector: 'app-guarantor-form',
  templateUrl: './guarantor-form.component.html',
  styleUrls: ['./guarantor-form.component.css']
})
export class GuarantorFormComponent {

  private encryptedApplicantId : string = this.constants.BLANK;

  guarantorFormDetailsObject  : any = {};

  scale : number = 0.8;
  
  margin : string = "1cm";

  constructor(private activatedRoute: ActivatedRoute, private constants: Constants, private utils: Utils, private httpUtils: HttpUtils, private loaderService: LoaderService, private toast: Toast, private gui: GUI) { 
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplicantId = params['applicantId'];
    });
    
    this.storeApplicationGuarantorFormData();
  }

  private storeApplicationGuarantorFormData(): void {
    const applicantId = this.utils.decryptString(this.encryptedApplicantId);
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationGuarantorFormData(applicantId).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.guarantorFormDetailsObject = serverResponse.guarantorFormDetails;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  backToDashboard(): void {
    this.gui.openDashboardPage();
  }

  printGurantorForm(): void {
    window.print();
  }

}
