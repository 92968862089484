import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { LocationStrategy } from '@angular/common';
import { GUI } from '../common/gui';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-no-session',
  templateUrl: './header-no-session.component.html',
  styleUrls: ['./header-no-session.component.css']
})
export class HeaderNoSessionComponent {

  trackApplicationURL: string = environment.trackApplicationUrl;
  appointmentApplicationUrl: string = environment.appointmentApplicationUrl;

  constructor(private location: LocationStrategy, private keycloakService: KeycloakService, private gui: GUI) { 
    history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });  
  }

  doLogin(){
    this.keycloakService.login();
  }

  openApplicationSearchPage(){
    this.gui.openApplicationSearchPage();
  }

  openHelpPage(){
    this.gui.openApplicationHelpPage();
  }

  openHomePage(){
    this.gui.openNewHomePage();
  }

}
