import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Constants } from './constants';

@Injectable({ 
    providedIn: 'root'
})
export class LoaderService {

    constructor(private ngxUILoaderService: NgxUiLoaderService, private constants: Constants){
    }

    startLoader(): void {
        this.ngxUILoaderService.startLoader(this.constants.DEFAULT_LOADER_ID);
    }

    stopLoader(): void {
        this.ngxUILoaderService.stopLoader(this.constants.DEFAULT_LOADER_ID);
    }

    startLoaderV2(taskId: string){
        this.ngxUILoaderService.start(taskId);
    }

    stopLoaderV2(taskId: string){
        this.ngxUILoaderService.stop(taskId);
    }

}
