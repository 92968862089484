import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface ConfirmModel {
  title: string;
  message: string;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  isShowCancelButton: boolean;
}
@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {
  title: string;

  message: string;

  confirmButtonLabel: string;
  
  cancelButtonLabel: string;

  isShowCancelButton: boolean;
  
  constructor() { 
    super();
  }

  confirm() {
    this.result = true;
    this.close();
  }

  cancel() {
    this.result = false;
    this.close();
  }

}