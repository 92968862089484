<app-header-no-session></app-header-no-session>

<div class="wrapper">

    <div class="main" >

        <h2 style="padding-bottom:0;">Payment Status</h2>

    </div>

    <section class="main">
        
        <div class="form_main">

            <fieldset style="margin: 0px;">

                <div class="step_info"><span>Application Search</span></div>

                <form #searchApplicationForm="ngForm" (ngSubmit)="searchApplicationForm.form.valid && searchApplication()" autocomplete="off">

                    <ng-template #instructions>
                        
                        <li>You can search application by entering application number and reference number.</li>
                    
                        <li>Application number & reference number is provided post application form is filled.</li>

                    </ng-template>

                    <div class="form-card" style="padding: 8px 15px;">
                        
                        <details style="float:none;">

                            <summary>Instructions for Applicant </summary>

                            <div class="content">
                                
                                <ul>
                                    
                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                                </ul>

                            </div>

                        </details>

                        <div class="search_grid">

                            <div class="grid-box">

                                <div class="form_box ">
                                    <label class="fieldlabels">Application Number <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="applicationId" hint [(ngModel)]="searchApplicationFormObject.applicationId" #applicationId="ngModel" class="effect" required="required" maxlength="10">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter your application number.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && applicationId.invalid">
                                        <span *ngIf="applicationId.errors.required">
                                            Application Number is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box mt10">
                                    <label class="fieldlabels">Reference Number <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="referenceId" hint [(ngModel)]="searchApplicationFormObject.referenceId" #referenceId="ngModel" class="effect" required="required" maxlength="16">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter your reference number.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && referenceId.invalid">
                                        <span *ngIf="referenceId.errors.required">
                                            Reference Number is required!
                                        </span>
                                    </span>
                                </div>
                            
                                <input type="button" (click)="gui.openHomePage()" class="back_btn" value="Back" />

                                <input type="submit" value="Search" class="save_btn">

                            </div>

                            <div class="grid-box clearfix"></div>

                            <div class="grid-box">

                                <div  class="imp_info mt20">

                                    <div  class="element none_dis">

                                        <h4>Important Instructions</h4>

                                        <ol style="padding-left: 15px;">

                                            <ng-template [ngTemplateOutlet]="instructions"></ng-template>

                                        </ol>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </form>

            </fieldset>

        </div>

    </section>

</div>

<app-footer></app-footer>
