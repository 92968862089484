<app-header></app-header>

<ng-container *ngIf="isServerResponseCome === true">

    <div class="wrapper">

        <div class="center_body">

            <div class="card">

                <ng-container *ngIf="paymentMessage.value == constants.STATUS_SUCCESS">
                    <h1>{{paymentMessage.text}}</h1>
                </ng-container>

                <ng-container *ngIf="paymentMessage.value == constants.STATUS_REJECTED">
                    <p>{{paymentMessage.text}}</p>
                </ng-container>

                <div class="flex_row mt10" style="align-items: center;">
                    <h6>List Of Application(s)</h6>
                </div>

                <table style="width: 100%;">
                    <thead>
                        <tr>
                            <th width="15%" data-label="applicationNo" nowrap scope ="row">Application no.</th>
                            <th width="15%" data-label="referenceNo" nowrap scope ="row">Reference no.</th>
                            <th width="40%" data-label="fullName" nowrap scope ="row">Full Name</th>
                            <th width="20%" data-label="paymentStatus" nowrap scope ="row">Payment Status</th>
                            <th width="10%" data-label="action" nowrap scope ="row">Action</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        <tr *ngIf="paidApplicationsList?.length === 0">
                            <td colspan="5" style="text-align:left;">No record found</td>
                        </tr>

                        <tr *ngFor="let paidApplication of paidApplicationsList;">
                            <td  data-label="application No." nowrap scope ="row"><span>{{paidApplication.applicationIdV1}}</span></td>
                            <td  data-label="reference No." nowrap scope ="row"><span>{{paidApplication.referenceIdV1}}</span></td>
                            <td  data-label="full Name" nowrap scope ="row"><span>{{paidApplication.fullName}}</span></td>
                            <td data-label="payment Status" nowrap scope ="row"><span [ngClass]="paidApplication.paymentStatus === constants.STATUS_SUCCESS ? 'success' : 'danger'">{{paidApplication.paymentStatusLabel}}</span></td>
                            <td  data-label="action" nowrap scope ="row">
                                <span (click)="viewApplication(paidApplication.id)"><a href="javascript:void(0);" class="dashboard_links">View/Print</a></span>
                                <span *ngIf="paidApplication.paymentStatus === constants.STATUS_INITIATE" (click)="checkPaymentStatus(paidApplication.id)"><a href="javascript:void(0);" class="dashboard_links">Verify Payment</a></span>
                                <!-- <span *ngIf="paidApplication.paymentStatus === constants.STATUS_SUCCESS" (click)="checkPaymentRefundStatus(paidApplication.id, paidApplication.applicationIdV1, paidApplication.referenceIdV1)"><a href="javascript:void(0);" class="dashboard_links">Book Appointment</a></span> -->
                            </td>
                        </tr>
                    </tbody>
                </table>

                <input type="button" (click)="openDashboard()" value="Go to Dashboard" class="custom_btn">

                <!-- <ng-container *ngIf="paymentMessage.successCount > constants.FALSE">
                    <input type="button" (click)="openBookAppointmentURL(constants.BLANK, constants.BLANK)" value="Book Appointment" class="save_btn" >
                </ng-container> -->

                <div class="book-appointment-note">
                    <p><b>Note:</b>Your application is currently awaiting approval. After approval, you will receive an email notification, and you can then proceed to schedule your appointment.</p>
                </div>

            </div>

        </div>

    </div>

</ng-container>

<app-footer></app-footer>
