import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { Route } from '../common/route';
import { Tags } from '../common/tags';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-resume-application',
  templateUrl: './resume-application.component.html',
  styleUrls: ['./resume-application.component.css']
})
export class ResumeApplicationComponent {

  private encryptedApplicationId : string = this.constants.BLANK;

  private encryptedApplicantEmail : string = this.constants.BLANK;

  private applicationSearchFormObject : any = {};

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private ngZone: NgZone, private route: Route, private constants: Constants, private utils: Utils,
              private loaderService: LoaderService, private httpUtils: HttpUtils, private toast: Toast, private localStorage: LocalStorage, private tags: Tags) { 
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplicationId = params["applicationId"];
      this.encryptedApplicantEmail = params["applicantEmail"];
    });
    this.fetchNewServerToken();
  }

  private fetchNewServerToken(){
    this.loaderService.startLoader();
    this.httpUtils.fetchServerToken().then(serverResponse => {
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.loaderService.stopLoader();
        this.localStorage.setStorageKey(this.tags.APPLICATION_TOKEN, serverResponse.bearerToken);
        this.searchApplication();
      }
    });
  }

  private searchApplication(){ 
    this.loaderService.startLoader();
    this.applicationSearchFormObject.applicationId = this.utils.decryptServerEncryptedString(this.encryptedApplicationId);
    this.applicationSearchFormObject.searchType = this.constants.RESUME_APPLICATION;
    this.applicationSearchFormObject.searchBy = this.constants.EMAIL_ID;
    this.applicationSearchFormObject.searchValue = this.utils.decryptServerEncryptedString(this.encryptedApplicantEmail);
    this.httpUtils.searchApplication(this.applicationSearchFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.redirectControl(serverResponse);
      } else if(serverResponseCode === this.constants.PAYMENT_ALREADY_COMPLETE || serverResponseCode === this.constants.PAYMENT_ALREADY_INITIATED){
        this.toast.showErrorToast(serverResponse.responseMsg);
        this.openApplicationViewPage(serverResponse.applicantId);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private redirectControl(serverResponse: any){
    const decryptedMasterSearchType = this.constants.RESUME_APPLICATION;
    const applicationStage = serverResponse.applicationStage;

    if((decryptedMasterSearchType === this.constants.RESUME_APPLICATION) || (decryptedMasterSearchType === this.constants.PAYMENT_STATUS && applicationStage < this.constants.NEXT_OF_KIN_INFO_STEP)){
      this.openApplicationAddEditPage(serverResponse.applicantId, applicationStage);
    } else if(decryptedMasterSearchType === this.constants.PAYMENT_STATUS){
      this.openApplicationViewPage(serverResponse.applicantId);
    }
  }

  openHomePage(){
    this.ngZone.run(()=>this.router.navigate([this.route.HOME]));
  }

  private openApplicationAddEditPage(applicantId: string, applicationStage: number){
    this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_ADD_EDIT, this.utils.encryptString(applicantId), this.utils.encryptString(applicationStage.toString())]));
  }

  private openApplicationViewPage(applicantId: string){
    this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_VIEW, this.utils.encryptString(applicantId)]));
  }

}