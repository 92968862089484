<footer>

    <div class="main-footer">

        <div class="footer_flex">
            <p>Copyright © {{latestYear}} | All rights reserved.</p>
        </div>

   </div>

</footer>