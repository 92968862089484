<div id="invalid">

    <div class="invalid">

        <div>

            <div class="invalid_access">
                <h1>X</h1>
            </div>

            <h2>Invalid Access</h2>

        </div>

        <p>Sorry, you are not allowed to access this page.</p>

    </div>
    
</div>