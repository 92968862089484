import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.css']
})
export class PaymentReceiptComponent {

  private encrypedApplicantId : string = this.constants.BLANK;

  private applicantId : string = this.constants.BLANK;

  private encrypedIsV1Application : string = this.constants.BLANK;

  isV1Application : number = this.constants.FALSE;

  paymentReceiptDetails : any = {};

  private isMobileDeviceUsed : boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, public utils: Utils, public constants: Constants) { 
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encrypedApplicantId = params['applicantId'];
      this.encrypedIsV1Application = params['isV1Application'];
    });
    this.applicantId = this.utils.decryptString(this.encrypedApplicantId);
    this.isV1Application = Number(this.utils.decryptString(this.encrypedIsV1Application));
    this.isMobileDeviceUsed = this.utils.checkIsMobileDeviceUsed();
    this.storePaymentReceiptDetails();
  }

  private storePaymentReceiptDetails(): void {
    this.httpUtils.fetchPaymentReceiptData(this.applicantId, this.isV1Application).then(serverResponse => {
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.paymentReceiptDetails = serverResponse.paymentReceiptDetails;
        setTimeout(()=>{window.print();}, 1000);
        if(this.isMobileDeviceUsed === false){
          setTimeout(()=>{window.close();}, 2000);
        }
      } else {
        window.close();
      }
    });
  }

  @HostListener('window:afterprint')
  onAfterPrint() {
    if(this.isMobileDeviceUsed === false){
      window.close();
    }
  }

}
