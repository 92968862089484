
<nav class="header-login-session">

    <div class="header_row">

        <div class="header_wrapper">

            <div class="left_sides right_top">
                <a [routerLink]="'/' + route.HOME_NEW">
                    <img src="assets/images/logo-nis.png">
                </a>
                <hr class="header_line">
            </div>

            <div class="right_top type-line">
                <span>Passport</span>
            </div>

            <div class="right_sides right_top_1">

                <div class="for_menu">

                    <input id="menu-toggle" type="checkbox" />
                    
                    <label *ngIf="isUserLoggedIn === true" class='menu-button-container' for="menu-toggle">
                        <div class='menu-button'></div>
                    </label>
                    
                    <ul class="menu">
                        <li *ngIf="isUserLoggedIn === true" class="small-screen-username">
                            <div class="user-account-container">
                                <i class="fa fa-user-circle-o " aria-hidden="true"></i> <h4>{{loggedUserFullName}}</h4>
                            </div>
                        </li>

                        <li *ngIf="isShowHomeLink === true"><a [routerLink]="'/' + route.HOME_NEW">Home</a></li>

                        <!-- <li><a [routerLink]="'/' + route.DASHBOARD">My Applications</a></li>

                        <li><a href="javascript:void(0);" (click)="openSearchModel(constants.FALSE)">Find Application</a></li>

                        <li><a [href]="trackApplicationURL" target="_blank">Track Application</a></li>
                        
                        <li><a [href]="appointmentApplicationUrl" target="_blank">Book Appointment</a></li> -->

                        <li *ngIf="isUserLoggedIn === true" class="small-screen-username"><a href="javascript:void(0);" (click)="doLogout();">Logout</a></li>
                    </ul> 

                </div> 

                <div class="user_log">
                    <ul>
                        <li *ngIf="isUserLoggedIn === true" class="dropdown">
                            <a (click)="utils.setDropdownWidth($event.target);" href="javascript:void(0);" mat-button [matMenuTriggerFor]="headerMenu" #headerMatMenuTrigger="matMenuTrigger" class="custom_btn">Hi, {{loggedUserFullName}} <i  aria-hidden="true" [class]="headerMatMenuTrigger.menuOpen === true ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></a>
          
                            <mat-menu #headerMenu="matMenu">
                                <button type="button" class="mat-logout" (click)="doLogout();" mat-menu-item style="height: auto !important;">Logout</button>
                            </mat-menu>
                        </li>
                    </ul>
                </div>
                
            </div>

        </div>

    </div>
    
    <div class="banner-img-name view-top"></div>
</nav> 
