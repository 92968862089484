<app-header></app-header>

<div class="center_body">  
    <div class="card">
         <div style="border-radius:200px; height:100px; width:100px; background: #F8FAF5; margin:0 auto; border:1px solid #f0f0f0">
             <i class="fa fa-times" style="color: #dd1d1d; margin-left: 0;"></i>
         </div>
         
         <h1 style="color: #dd1d1d">Payment Failed !</h1> 
 
         <p>Your payment has been failed, Please try again later.</p>
 
         <input type="button" (click)="openDashboard()" value="Go to Dashboard" class="save_btn">
     </div>
 </div> 

<app-footer></app-footer>
